import "./App.css";

import { useEffect } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { FluentProvider, teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme } from "@fluentui/react-components";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./internal/context";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import { AuthProvider } from "./contexts/AuthContext";
import LoginCallback from "./authentication/LoginCallback";
import SilentCallback from "./authentication/SilentCallback";
import PopupLoginCallback from "./authentication/PopupLoginCallback";
import ConsentPopup from "./authentication/ConsentPopup";
import ClosePopup from "./authentication/ClosePopup";
import { app } from "@microsoft/teams-js";
import Login from "./authentication/Login";
import LoginRedirect from "./authentication/LoginRedirect";
import ExternalLoginIdp from "./authentication/ExternalLoginIdp";
import ApplicationSettingService from "./services/applicationSettingService";
import { CONSTANTS } from "./constants/constants";

const appClientId = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_id);
const loginPageUrl = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_url);

export default function App() {
  const { loading, themeString } = useTeamsUserCredential({
    initiateLoginEndpoint: loginPageUrl,
    clientId: appClientId,
  });
  
  useEffect(() => {   
    loading &&
    app.initialize().then(() => {
      app.notifyAppLoaded();
      app.notifySuccess();
    });
  
  }, [loading]);

  return (
    <TeamsFxContext.Provider value={{ themeString }}>
      <FluentProvider
        id="fluent-provider"
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
            ? teamsHighContrastTheme
            : teamsLightTheme
        }
      >
         <AuthProvider>
            <Router>
              {!loading && (
                    <Routes>
                      <Route path="/tab" element={<Login  />} />
                      <Route path="/auth-start" element={<ConsentPopup/>} />
                      <Route path="/auth-end" element={<ClosePopup/>} />
                      <Route path="/login-callback" element={<LoginCallback />} />
                      <Route path="/popup-callback" element={<PopupLoginCallback />} />
                      <Route path="/silent-callback" element={<SilentCallback />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/termsofuse" element={<TermsOfUse />} />
                      <Route path="/loginRedirect" element={<LoginRedirect />} />
                      <Route path="/externalLoginIdp" element={<ExternalLoginIdp />} />
                      <Route path="*" element={<Navigate to={"/tab"} />} />
                    </Routes>
              )}
            </Router>
          </AuthProvider>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
