import { ICardEntry } from "../models/ICardEntry";
import CardWidgetBase from "./CardWidgetBase";
import { SpinnerProps} from "@fluentui/react-components";
 
 interface CardWidgetProps extends Partial<SpinnerProps> {
  cardEntry : ICardEntry
}

export default function CardWidget(props: CardWidgetProps) {
 
  return (
    <div >
        <CardWidgetBase cardEntry={props.cardEntry} ></CardWidgetBase>
    </div>
  )
  
}
