import { Input, Label } from "@fluentui/react-components";
import SoftTextService from "../services/softTextService";
import { CONSTANTS } from "../constants/constants";
import { useState } from "react";

interface ITimekeeperProps {
    timekeeperId: string;
    timekeeperName: string;
}

export default function TimekeeperField(props: ITimekeeperProps) {

    const [timekeeperSoftText] = useState(SoftTextService.getSoftTextValue(CONSTANTS.timekeeper));

    return (
        <div>
            <Label className="title" required={true}>{timekeeperSoftText}</Label>
            <Input disabled value= {`${props.timekeeperId}:${props.timekeeperName}`}/>
        </div>
    );
}
