import { ApiUrlConstant } from "../constants/api-url-constants";
import { MessageType } from "../enums/messageType";
import { ITimeFinderActivityGroup } from "../models/ITimeFinderActivityGroup";
import { ITimeFinderActivity } from "../models/ITimefinderActivity";
import DataService from "./dataService";
import LoggingService from "./loggingService";

const TimeFinderService = {
    // Get time finder activities
    async getTimekeeperActivitiesAll(timekeeperId: string, startTime: string, endTime: string, offset: number): Promise<ITimeFinderActivity[]> {
        let activities: ITimeFinderActivity[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeFinderService - getTimekeeperActivitiesAll(${JSON.stringify({timekeeperId, startTime, endTime, offset})}) started` : '', MessageType.Info);
            var dataToPost = {
                timekeeperId: timekeeperId,
                startTime: startTime,
                endTime: endTime,
                offset: offset,
                bestMatch: true,
                overlapping: true,
                noMatch: true,
                exclusions: true,
                showDropdown: "Teams all",
                timeGaps: [],
                isAllEvents: true
              };

              activities = await DataService.postData<ITimeFinderActivity[]>(ApiUrlConstant.timeFinder.getTimekeeperActivitiesAll, dataToPost);
              return activities;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeFinderService - getTimekeeperActivitiesAll(${JSON.stringify({timekeeperId, startTime, endTime, offset})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeFinderService - getTimekeeperActivitiesAll(${JSON.stringify({timekeeperId, startTime, endTime, offset})}) - output(${JSON.stringify({activities})}) ended` : '', MessageType.Info);
        }
    },

    // Exclude time finder activity
    async excludeTimeFinderActivity(timeKeeperId: string, item: ITimeFinderActivity) {
        let response: string = '';
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeFinderService - excludeTimeFinderActivity(${JSON.stringify({timeKeeperId, item})}) started` : '', MessageType.Info);
            var dataToPost: { timekeeperId: string, activityId: string, excludedFlag: boolean }[] = [];
            let data: { timekeeperId: string, activityId: string, excludedFlag: boolean } = {
                timekeeperId: timeKeeperId,
                activityId: item.id,
                excludedFlag: true
            };
            
            dataToPost.push(data);

            response = await DataService.postData<string>(ApiUrlConstant.timeFinder.excludeTimeFinderActivity, dataToPost);
            return response;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeFinderService - excludeTimeFinderActivity(${JSON.stringify({timeKeeperId, item})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeFinderService - excludeTimeFinderActivity(${JSON.stringify({timeKeeperId, item})}) - output(${JSON.stringify({response})}) ended` : '', MessageType.Info);
        }
    }

}

export default TimeFinderService;