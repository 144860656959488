import { useEffect, useState } from "react";
import { ICardEntry } from "../models/ICardEntry";
import CardWidget from "../widgets/CardWidget";
import TimekeeperService from "../services/timekeeperService";
import { Spinner, makeStyles, shorthands } from "@fluentui/react-components";
import moment from "moment";
import ConfigService from "../services/configService";
import { ITimeRestrictionsConfig } from "../models/ITimeRestrictionsConfig";
import { CONSTANTS } from "../constants/constants";
import SecurityService from "../services/securityService";
import { TimeOperationsPermissions } from "../enums/timeOperationsPermissions";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";

const useStyles = makeStyles({
    container: {
      "> div": { ...shorthands.padding("200px") },
    },
    spinner: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    noTimeCreatePermission: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%'
    }
});
  
export default function CreateTime() {

    const styles = useStyles();

    // Permission to create a time entry
    const [createTimePermissions] = useState<boolean>(SecurityService.hasTimeOperationPermissions(TimeOperationsPermissions.Time_CreateTime));

    // Initialize cardEntry state
    const [cardEntry, setCardEntry] = useState<ICardEntry>();

    // This will be called only once when the component is mounted
    useEffect(() => {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `CreateTime - useEffect() started` : '', MessageType.Info);
        initializeCardEntry();
        LoggingService.log(LoggingService.isLoggingEnabled() ? `CreateTime - useEffect() ended` : '', MessageType.Info);
    }, []);

    // Initialize cardEntry with default values
    async function initializeCardEntry() {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CreateTime - initializeCardEntry() started` : '', MessageType.Info);
            let newCardEntry: ICardEntry = {};

            // We will set only the default timekeeper for this phase of development
            let timeRestrictionsConfiguration = ConfigService.getConfigurationData<ITimeRestrictionsConfig>(CONSTANTS.timeRestrictions);
            let fetchTimekeeperGroupRoundingValue = timeRestrictionsConfiguration?.TimekeeperGroupRoundingEnabled.toLowerCase() == "true" ? true : false;
            let allowAllTimekeeper = false;
            
            let defaultTimekeeper = await TimekeeperService.getDefaultTimekeeper();
            newCardEntry.timekeeperId = defaultTimekeeper.timekeeperId;
            newCardEntry.timekeeperName = defaultTimekeeper.timekeeperName;
            newCardEntry.timekeeperObj = await TimekeeperService.getTimekeeperData(defaultTimekeeper.timekeeperId, allowAllTimekeeper, fetchTimekeeperGroupRoundingValue);
        
            newCardEntry.dateWorked = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
            newCardEntry.status = "0";
            newCardEntry.roundTimeDisplayValue = "";

            newCardEntry.isAddEntry = true;
            
            setCardEntry(newCardEntry);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CreateTime - initializeCardEntry() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CreateTime - initializeCardEntry() ended` : '', MessageType.Info);
        }
    }
    
    return createTimePermissions ? (
        cardEntry ? 
          <CardWidget cardEntry={cardEntry} /> :
          <div className={styles.spinner}> 
              <Spinner size="large" labelPosition="below" label="Loading..." /> 
          </div>
      ) : (
        <div className={styles.noTimeCreatePermission}>Create time entry permission is required to use this tab. Please connect with your admin.</div>
    );
}