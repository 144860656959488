import { ComboboxProps, Input, InputProps, Label } from "@fluentui/react-components";
import { ICardEntry } from "../models/ICardEntry";
import { useEffect, useState } from "react";
import { IUserCode } from "../models/IUserCode";
import { IUdcFieldModel } from "../models/IUdcFieldModel";
import { DataType } from "../enums/dataType";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";

interface UdcDropdownFieldProps extends Partial<ComboboxProps>{
    cardEntry: ICardEntry;
    field: IUdcFieldModel;
    onUdcChange: (codeX: string, udcObj: IUserCode, isFromDropdown: boolean) => void;
}

export default function UdcTextField(props: UdcDropdownFieldProps) {
    // Check if the field is view only
    const [viewOnly] = useState(props.cardEntry.timeId ? true : false)

    // State to store the value of the input field
    const [value, setValue] = useState<string | undefined>("");

    // This useEffect is called when the component is mounted
    useEffect(() => {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcTextField - useEffect() started.` : '', MessageType.Info);
        let currValue = props.cardEntry.userCodes?.[props.field.Name] ?? "";
        setValue(currValue);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcTextField - useEffect() ended.` : '', MessageType.Info);
    }, []);

    // This useEffect is called when the userCodes changes
    useEffect(() => { 
        let currValue = props.cardEntry.userCodes?.[props.field.Name] ?? "";
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcTextField - useEffect() for userCode started. Dependency array - ${JSON.stringify({currValue})}` : '', MessageType.Info);
        setValue(currValue);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcTextField - useEffect() for userCode ended. Dependency array - ${JSON.stringify({currValue})}` : '', MessageType.Info);
    }, [props.cardEntry.userCodes?.[props.field.Name]]);

    // This function is called when the user types in the input field
    const onChange: InputProps["onChange"] = (ev, data) => {
        let val = data.value ?? "";

        if (val.length > +props.field.Size) {
            val = val.slice(0, +props.field.Size);
        }
        
        setValue(val);
    }


    // This function is called when the user leaves the input field
    const onBlur = (): void => {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcTextField - onBlur() started` : '', MessageType.Info);
        let val = value ?? "";

        if (props.field.ControlDataType === DataType.NumberType) {
            if (+props.field.DecimalPlaces > 0) {
                let numValue = +val;
                val = numValue.toFixed(+props.field.DecimalPlaces);
            }

            if (val.length > +props.field.Size) {
                val = ""
            }

            setValue(val);
        } else {
            setValue(val);
        }

        // Call onUdcChange with the appropriate arguments
        let data : IUserCode = {
            codeID: val
        };

        props.onUdcChange(props.field.Name, data, false);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcTextField - onBlur() ended` : '', MessageType.Info);
    };
    

    return (
        <div>
            <Label className="title" required={props.field.Required == "true" ? true : false}>{props.field.Label}</Label>
            {
                props.field.ControlDataType === DataType.NumberType ?
                <Input disabled={viewOnly} id={props.field.Name} type="number" autoComplete="off" maxLength={+props.field.Size} placeholder={"Enter " + props.field.Label} value={value} onChange={onChange} onBlur={onBlur} /> :
                <Input disabled={viewOnly} id={props.field.Name} type="text" autoComplete="off" maxLength={+props.field.Size} placeholder={"Enter " + props.field.Label} value={value} onChange={onChange} onBlur={onBlur}/>
            }
        </div>
    )
}