import { MessageType } from "../enums/messageType";
import { IJwtToken } from "../models/IJwtToken";
import LoggingService from "./loggingService";

const JwtHelperService = { 

    // parses the token
    parseJwt(token: string){
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
          return null;
        }
    },

    // Decode the token
    decodeToken(token: string) {
        let decodedToken: IJwtToken = {
            unique_name: "",
            TMSPermissions: "",
            expenseDisbursementPermissions: "",
            timePermissions: "",
            matterViewPermissions: "",
            licences: "",
            menuVisibilityPermissions: "",
            nbf: 0,
            exp: 0,
            iat: 0,
            iss: "",
            overrideValidationPermissions: "",
            userGuid: "",
            workGroupPermissions: ""
        };
        
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `JwtHelperService - decodeToken() started` : '', MessageType.Info);
            let parts = token.split(".");

            if (parts.length !== 3) {
                throw new Error("JWT must have 3 parts");
            }

            let decoded = this.urlBase64Decode(parts[1]);
            if (!decoded) {
                throw new Error("Cannot decode the token");
            }

            return decodedToken = JSON.parse(decoded) as IJwtToken;
        } catch (err) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `JwtHelperService - decodeToken() - ${err}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `JwtHelperService - decodeToken() - output(${JSON.stringify({decodedToken})}) ended` : '', MessageType.Info);
        }
    },

    // Decode the base64url
    urlBase64Decode(str: string) {
        let result = '';
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `JwtHelperService - urlBase64Decode() started` : '', MessageType.Info);
            let output = str.replace("-", "+").replace("_", "/");
            switch (output.length % 4) {
                case 0: { break; }
                case 2: { output += "=="; break; }
                case 3: { output += "="; break; }
                default: {
                    throw new Error("Illegal base64url string!");
                }
            }

            return result = atob(output);
        } catch (err) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `JwtHelperService - urlBase64Decode() - ${err}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `JwtHelperService - urlBase64Decode() ended` : '', MessageType.Info);
        }
    }
}

export default JwtHelperService;