import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";

export default function SilentCallback() {
  const auth = useAuth();

  useEffect(() => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `SilentCallback - Silent sign-in event triggered.` : '', MessageType.Info);
    auth.silentCallback().then(() => {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `SilentCallback - Silent sign-in successful.` : '', MessageType.Info);
    }).catch(function(err) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `SilentCallback - Silent sign-in failed - ${err}` : '', MessageType.Error);
    });
  }, []);

  return <div>Processing silent sign-in...</div>;
}
