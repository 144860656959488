import { ApiUrlConstant } from "../constants/api-url-constants";
import { CONSTANTS } from "../constants/constants";
import { dataTypeValue } from "../enums/dataTypeValue";
import { IIniConfig } from "../models/IIniConfig";
import { ICloseValidationConfig } from "../models/ICloseValidationConfig";
import { IDateValidationConfig } from "../models/IDateValidationConfig";
import { IMiscellaneousValidationConfig } from "../models/IMiscellaneousValidationConfig";
import { INarrativeValidationConfig } from "../models/INarrativeValidationConfig";
import { ITimeRestrictionsConfig } from "../models/ITimeRestrictionsConfig";
import ConfigService from "./configService";
import DataService from "./dataService";
import { ILookUpData } from "../models/ILookUpData";
import { IDictionary } from "../models/IDictionary";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";

const ValidationDataService = { 
    
    // Checks if the datatype is numeric
    isNumericDatatype(datatypenum: string): boolean {
        let isNumeric = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - isNumericDatatype(${JSON.stringify({datatypenum})}) started` : '', MessageType.Info);
            var datatype: string;

            datatype = this.convertToDatatype(datatypenum);

            isNumeric = (datatype === "BIGINT" || datatype === "SMALLINT" || datatype === "TINYINT" ||
                datatype === "DECIMAL" || datatype === "INT" || datatype === "NUMERIC" || datatype === "FLOAT" ||
                datatype === "BIT" || datatype === "SMALLBIT" || datatype === "MONEY" || datatype === "SMALLMONEY"
                || datatype === "WORD");

            return isNumeric;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - isNumericDatatype(${JSON.stringify({datatypenum})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - isNumericDatatype(${JSON.stringify({datatypenum})}) - output(${JSON.stringify({isNumeric})}) ended` : '', MessageType.Info);
        }
    },

    // Converts the datatype
    convertToDatatype(datatypenum: string): string {
        let dataType = '';
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - convertToDatatype(${JSON.stringify({datatypenum})}) started` : '', MessageType.Info);
            
            if (dataTypeValue.VARCHAR === datatypenum) {
                dataType = "VARCHAR";
            } else if (dataTypeValue.SMALLINT === datatypenum) {
                dataType = "SMALLINT";
            } else if (dataTypeValue.INT === datatypenum) {
                dataType = "INT";
            } else if (dataTypeValue.WORD === datatypenum) {
                dataType = "WORD";
            } else if (dataTypeValue.FLOAT === datatypenum) {
                dataType = "FLOAT";
            } else if (dataTypeValue.DATE === datatypenum) {
                dataType = "DATE";
            } else if (dataTypeValue.DATETIME === datatypenum) {
                dataType = "DATETIME";
            } else {
                dataType = "VARCHAR";
            }
    
            return dataType;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - convertToDatatype(${JSON.stringify({datatypenum})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - convertToDatatype(${JSON.stringify({datatypenum})}) - output(${JSON.stringify({dataType})}) ended` : '', MessageType.Info);
        }
    },

    // Gets the local validation rules for the client, matter and timekeeper
    async getLocalValidationRules(clientId: string, matterId: string, timekeeperId: string) {
        let dictionary: IDictionary<string> = {};
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - getLocalValidationRules(${JSON.stringify({clientId, matterId, timekeeperId})}) started` : '', MessageType.Info);
            let validationRules: ILookUpData[] = [];
            let configData = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config);
            if (configData && configData.IsLocalRuleDefined === "1") {
                validationRules = await DataService.fetchData<ILookUpData[]>(
                    (ApiUrlConstant.validationData.getLocalValidationRules).replace("{0}", clientId).replace("{1}", matterId).replace("{2}", timekeeperId));

                for (var i = 0; i < validationRules.length; i++) {
                    dictionary[validationRules[i].key] = validationRules[i].value;
                }
            } else {
                dictionary = await this.getGlobalValidationRules();
            }

            return dictionary;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - getLocalValidationRules(${JSON.stringify({clientId, matterId, timekeeperId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - getLocalValidationRules(${JSON.stringify({clientId, matterId, timekeeperId})}) - output(${JSON.stringify({dictionary})}) ended` : '', MessageType.Info);
        }
    },

    // Gets the global validation rules
    async getGlobalValidationRules() { 
        let dictionary: IDictionary<string> = {};
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - getGlobalValidationRules() started` : '', MessageType.Info);
            let timeRestrictions = ConfigService.getConfigurationData<ITimeRestrictionsConfig>(CONSTANTS.timeRestrictions);
            let dateValidation = ConfigService.getConfigurationData<IDateValidationConfig>(CONSTANTS.dateValidation);
            let closeValidation = ConfigService.getConfigurationData<ICloseValidationConfig>(CONSTANTS.closeValdiation);
            let narrativeValidation = ConfigService.getConfigurationData<INarrativeValidationConfig>(CONSTANTS.narrativeValidation);
            let miscellaneousValidation = ConfigService.getConfigurationData<IMiscellaneousValidationConfig>(CONSTANTS.miscellaneous);
            let validationRules = [
                timeRestrictions, dateValidation, closeValidation,
                narrativeValidation, miscellaneousValidation];
            
            validationRules.forEach((item: any) => {
                if (item) {
                    Object.keys(item).forEach(key => {
                        dictionary[key] = item[key];
                    });
                }
            });    

            return dictionary;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - getGlobalValidationRules() - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ValidationDataService - getGlobalValidationRules() - output(${JSON.stringify({dictionary})}) ended` : '', MessageType.Info);
        }
    }
}

export default ValidationDataService;