import React, { forwardRef, useImperativeHandle } from "react";
import { Toast,  ToastTitle, Toaster, useId, useToastController } from "@fluentui/react-components";


export interface NotificationMessageHandler {
    showNotification: (message: string, isError?: boolean, timeout?: number) => void;
}

export const NotificationMessage = forwardRef<NotificationMessageHandler>((props, ref) => {

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    // This method is used to display the message
    const showNotification = (message: string, isErorr: boolean = false, timeout: number = 3000) => {
        dispatchToast(
            <Toast>
                <ToastTitle>{message}</ToastTitle>
            </Toast>,
            { timeout, intent: isErorr ? "error" : "success"}
        );
    };

    // Expose the showNotification function to parent component
    useImperativeHandle(ref, () => ({
        showNotification
    }));

    return (
        <div className="notification">
            <Toaster toasterId={toasterId} />
        </div>
    );
});