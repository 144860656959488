import { useAuth } from "../contexts/AuthContext";
import Dashboard from "../dashboards/Dashboard";
import { MessageType } from "../enums/messageType";
import JwtHelperService from "../services/jwtHelperService";
import LoggingService from "../services/loggingService";
import ExternalLoginIdp from "./ExternalLoginIdp";
import LoginRedirect from "./LoginRedirect";

function Login() {

  const auth = useAuth();
  
  if (!auth.userToken) {  
    LoggingService.log(LoggingService.isLoggingEnabled() ? `Login - Redirecting user to login screen as token does not exist` : '', MessageType.Info);
    return <LoginRedirect isTokenExpired ="false"/>
  }
  else
  {
    const decodedJwt = JwtHelperService.parseJwt(auth.userToken);
  
    if (decodedJwt != null && decodedJwt.exp * 1000 < Date.now()) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `Login - Redirecting user to login screen as token has expired` : '', MessageType.Info);
      return <ExternalLoginIdp />
    }

    LoggingService.log(LoggingService.isLoggingEnabled() ? `Login - Redirecting user to dashboard` : '', MessageType.Info);
    return <Dashboard />
  }
}

export default Login;