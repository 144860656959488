import moment from "moment";
import { ITimeFinderActivity } from "../models/ITimefinderActivity";
import TimeEntryService from "./timeEntryService";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";

const TIME_CONSTANST = {
    MAX_SECOND_IN_DAY: 86400,
    MAX_MINUTE_IN_DAY: 1440,
    SECOND_IN_HOURS: 3600,
    MAX_HOUR_LIMIT: 500,
    MAX_MILLISECOND: 1000,
    SECOND_IN_MINUTE: 60,
    ONE_DIGIT_LIMIT: 10,
    HOURS_DAYS: 24,
    HOURS_TWELVE: 12,
    LENTH_ONE: 1,
    LENTH_TWO: 2,
    LENTH_THREE: 3,
    LENTH_FOUR: 4,
    LENTH_FIVE: 5,
    DAYS_IN_WEEK: 7,
    WORKING_DAYS_IN_WEEK: 5,
    NUMBER_NINE: 9,
    NUMBER_SIX: 6,
    NUMBER_ELEVEN: 11,
    UNITS_FORMAT_ZERO_VALUE: "0",
};

const TimerService = {

    // This function is used to get the time in hh:mm AM/PM format
    getTimeDisplayValue(time : string) {
        let displayValue : string = "";
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getTimeDisplayValue(${JSON.stringify({time})}) started` : '', MessageType.Info);
            return displayValue = moment(time).format("hh:mm") + this.getAmPmForSequences(time);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getTimeDisplayValue(${JSON.stringify({time})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getTimeDisplayValue(${JSON.stringify({time})}) - output(${JSON.stringify({displayValue})}) ended` : '', MessageType.Info);
        }
    },

    // This function is used to get the AM/PM value for the time
    getAmPmForSequences(dateTime: string): string {
        let amPmValue: string = "";
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getAmPmForSequences(${JSON.stringify({dateTime})}) started` : '', MessageType.Info);
            return amPmValue = new Date(dateTime).getHours() < 12 ? " AM" : " PM";
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getAmPmForSequences(${JSON.stringify({dateTime})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getAmPmForSequences(${JSON.stringify({dateTime})}) - output(${JSON.stringify({amPmValue})}) ended` : '', MessageType.Info);
        }
    },

    // This function is used to convert seconds to MM:SS format
    secondsToMMSS(seconds: number) {
        let displayValue: string = "";
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - secondsToMMSS(${JSON.stringify({seconds})}) started` : '', MessageType.Info);
            return displayValue = new Date(seconds * 1000).toISOString().substr(14, 5);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - secondsToMMSS(${JSON.stringify({seconds})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - secondsToMMSS(${JSON.stringify({seconds})}) - output(${JSON.stringify({displayValue})}) ended` : '', MessageType.Info);
        }
    },

    // This method will round the time to the nearest increment
    roundSeconds(time: number, roundInc: number, roundingType: number): number {
       let roundedTime: number = 0;
       try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - roundSeconds(${JSON.stringify({time, roundInc, roundingType})}) started` : '', MessageType.Info);
            const MINUTE_HOURS = 60;
            const ROUNDING_TYPE = 2;
            roundedTime = +(time);
            let convertSecond = +(roundInc * MINUTE_HOURS),
                remainSecond = 0;
            if (convertSecond > 0) {
                remainSecond = +(time % convertSecond);
                if (remainSecond !== 0) {
                    if (roundingType === 1) {
                        roundedTime = +(time + (convertSecond - remainSecond));
                    } else if (roundingType === ROUNDING_TYPE) {
                        roundedTime = +(time - remainSecond);
                    }
                }
            }

            return roundedTime;
       } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - roundSeconds(${JSON.stringify({time, roundInc, roundingType})}) - ${error}` : '', MessageType.Error);
            throw error;
       } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - roundSeconds(${JSON.stringify({time, roundInc, roundingType})}) - output(${JSON.stringify({roundedTime})}) ended` : '', MessageType.Info);
       }
    },

    // This method will convert the value to string
    convertToString(value?: number | string | Date): string {
        return moment(value).format("YYYY-MM-DDTHH:mm:ss.SSS");
    },

    // This method will convert the value to hh:mm format
    convertSecondsToHoursAndMinutes(timeData: number): string {
       let displayValue: string = "";
       try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - convertSecondsToHoursAndMinutes(${JSON.stringify({timeData})}) started` : '', MessageType.Info);
            var timeInHours: number, restTimeInSeconds: number, timeInMinutes: number, strtimeInMinutes: string,
            sign: string = timeData < 0 ? "-" : "";
            let time = timeData < 0 ? Math.abs(timeData) : timeData;
            timeInHours = parseInt((time / TIME_CONSTANST.SECOND_IN_HOURS).toString(), 10);
            restTimeInSeconds = parseInt((time % TIME_CONSTANST.SECOND_IN_HOURS).toString(), 10);
            timeInMinutes = parseInt((restTimeInSeconds / TIME_CONSTANST.SECOND_IN_MINUTE).toString(), 10);

            if (timeInMinutes >= TIME_CONSTANST.SECOND_IN_MINUTE) {
                timeInMinutes = timeInMinutes / TIME_CONSTANST.SECOND_IN_MINUTE;
            }

            strtimeInMinutes = timeInMinutes.toString();

            if ((strtimeInMinutes.length === 1) && (timeInMinutes < TIME_CONSTANST.ONE_DIGIT_LIMIT)) {
                strtimeInMinutes = TIME_CONSTANST.UNITS_FORMAT_ZERO_VALUE + strtimeInMinutes;
            } else if ((strtimeInMinutes.length === 1) && (timeInMinutes >= TIME_CONSTANST.ONE_DIGIT_LIMIT)) {
                strtimeInMinutes = strtimeInMinutes + TIME_CONSTANST.UNITS_FORMAT_ZERO_VALUE;
            }
            if (timeInHours < TIME_CONSTANST.ONE_DIGIT_LIMIT) {
                let format = TIME_CONSTANST.UNITS_FORMAT_ZERO_VALUE;

                return displayValue = (sign + format + timeInHours + ":" + strtimeInMinutes);

            } else {
                return displayValue = (sign + timeInHours + ":" + strtimeInMinutes);
            }
       } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - convertSecondsToHoursAndMinutes(${JSON.stringify({timeData})}) - ${error}` : '', MessageType.Error);
            throw error;
       } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - convertSecondsToHoursAndMinutes(${JSON.stringify({timeData})}) - output(${JSON.stringify({displayValue})}) ended` : '', MessageType.Info);
       }
    },

    // This method will get the date object
    getDateTime(dateObj?: moment.Moment | string | Date): Date {
        let d: Date = new Date();
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getDateTime(${JSON.stringify({dateObj})}) started` : '', MessageType.Info);
            let arr: string[] = [], datePart: string[] = [], timePart: string[] = [],
            milli: string[] = [], year: number,
            month: number, day: number, hours: number, minutes: number, seconds: number, milliSeconds: number;
            let userTimeZoneOffset = TimeEntryService.getTimeZoneOffset();
            let tempDateString = (dateObj) ? moment(dateObj).format("YYYY-MM-DDTHH:mm:ss.SSS")
                : moment(new Date()).utc().add("hours", userTimeZoneOffset).format("YYYY-MM-DDTHH:mm:ss.SSS");

            arr = tempDateString.split("T");
            if (arr.length === TIME_CONSTANST.LENTH_TWO) {
                datePart = arr[0].split("-");
                timePart = arr[1].split(":");
                milli = timePart[TIME_CONSTANST.LENTH_TWO].split(".");
                year = Number(datePart[0]);
                month = Number(datePart[1]) - 1;
                day = Number(datePart[TIME_CONSTANST.LENTH_TWO]);
                hours = Number(timePart[0]);
                minutes = Number(timePart[1]);
                seconds = Number(milli[0]);
                milliSeconds = Number(milli[1]);
                d = new Date(year, month, day, hours, minutes, seconds, milliSeconds);

            }

            return d;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getDateTime(${JSON.stringify({dateObj})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getDateTime(${JSON.stringify({dateObj})}) - output(${JSON.stringify({d})}) ended` : '', MessageType.Info);
        }
    },

    // This method will get the date object for time finder
    getDateTimeForTimeFinder (dateObj?: string | Date): Date {
        let d: Date = new Date()
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getDateTimeForTimeFinder(${JSON.stringify({dateObj})}) started` : '', MessageType.Info);
            var arr = [], tempDateString = "", datePart = [], timePart = [], milli = [], year, month, day, hours,
            minutes, seconds, milliSeconds, sYear, sMonth, sDay, sHours, sMinutes, sSeconds, sMilliSeconds;

            tempDateString = (dateObj) ?
                moment(dateObj).utc().add("hours", TimeEntryService.getTimeZoneOffset()).format("YYYY-MM-DDTHH:mm:ss.SSS")
                : moment(new Date()).utc().add("hours", TimeEntryService.getTimeZoneOffset()).format("YYYY-MM-DDTHH:mm:ss.SSS");

            arr = tempDateString.split("T");
            if (arr.length === TIME_CONSTANST.LENTH_TWO) {
                datePart = arr[0].split("-");
                timePart = arr[1].split(":");
                milli = timePart[TIME_CONSTANST.LENTH_TWO].split(".");
                year = Number(datePart[0]);
                month = Number(datePart[1]) - 1;
                day = Number(datePart[TIME_CONSTANST.LENTH_TWO]);
                hours = Number(timePart[0]);
                minutes = Number(timePart[1]);
                seconds = Number(milli[0]);
                milliSeconds = Number(milli[1]);

                d = new Date(year, month, day, hours, minutes, seconds, milliSeconds);

            }

            return d;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getDateTimeForTimeFinder(${JSON.stringify({dateObj})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - getDateTimeForTimeFinder(${JSON.stringify({dateObj})}) - output(${JSON.stringify({d})}) ended` : '', MessageType.Info);
        }
    },

    // This method will convert the date to local time
    convertActivitiesTimeToLocalTime(activities: ITimeFinderActivity[]): ITimeFinderActivity[] {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - convertActivitiesTimeToLocalTime(${JSON.stringify({activities})}) started` : '', MessageType.Info);
            if (activities.length > 0) {
                for (var i = 0; i < activities.length; i++) {
                  var localOffSetStrtData = activities[i].activityStartTime + "Z";
                  var localOffSetEndData = activities[i].activityEndTime + "Z";
                  activities[i].activityStartTime = this.getDateTimeForTimeFinder(
                    localOffSetStrtData).toString();
                  activities[i].activityEndTime = this.getDateTimeForTimeFinder(
                    localOffSetEndData).toString();
                }
              }
          
              return activities;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - convertActivitiesTimeToLocalTime(${JSON.stringify({activities})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimerService - convertActivitiesTimeToLocalTime(${JSON.stringify({activities})}) - output(${JSON.stringify({activities})}) ended` : '', MessageType.Info);
        }
    }
}

export default TimerService;