import { useRef, useState } from "react";
import {
  Button,
  makeStyles,
  shorthands,
  Spinner,
} from "@fluentui/react-components";
import type {
  ComboboxProps,
} from "@fluentui/react-components";
import moment from "moment";
import "../styles/CardWidget.css";
import ConfigService from "../services/configService";
import TimekeeperField from "../components/TimekeeperField";
import ClientField from "../components/ClientField";
import { ICardEntry } from "../models/ICardEntry";
import MatterField from "../components/MatterField";
import { IClient } from "../models/IClient";
import { IMatter } from "../models/IMatter";
import Narrative from "../components/Narrative";
import TimeField from "../components/TimeField";
import { IRoundingBillableRequest } from "../models/IRoundingBillableRequest";
import TimerService from "../services/timerService";
import { ITimeRestrictionsConfig } from "../models/ITimeRestrictionsConfig";
import TimeEntryService from "../services/timeEntryService";
import { IUserDictionary } from "../models/IUserDictionary";
import { CONSTANTS } from "../constants/constants";
import { TimeEntry } from "../models/TimeEntry";
import { SaveEntrySteps } from "../enums/saveEntrySteps";
import { IDictionary } from "../models/IDictionary";
import { IUserCode } from "../models/IUserCode";
import UdcFields from "../components/UdcFields";
import { NotificationMessage, NotificationMessageHandler } from "../components/NotificationMessage";
import SecurityService from "../services/securityService";
import { TimeOperationsPermissions } from "../enums/timeOperationsPermissions";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { IRoundingBillableResponse } from "../models/IRoundingBillableResponse";


const useStyles = makeStyles({
  messageBarGroup: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: "column",
    marginTop: "-10px",
    height: "50px",
    ...shorthands.overflow("auto"),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "end",
    ...shorthands.gap("5px"),
  },
  spinner: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
});

interface CardWidgetControlProps extends Partial<ComboboxProps> {
  cardEntry: ICardEntry;
}


export default function CardWidgetControl(props: CardWidgetControlProps) {

  const styles = useStyles();
  const notificationRef = useRef<NotificationMessageHandler>(null);

  // This object will hold the card entry details
  const [cardEntry, setCardEntry] = useState(props.cardEntry);

  // This variable is used to display the spinner when fetching data
  const [isFetchingData, setIsFetchingData] = useState(false);

  // This variable is used to show text in the narrative field after saving current entry
  const [trigger, setTrigger] = useState(false);

  // This variable is used to show the view only mode (from day view)
  const [viewOnly] = useState(props.cardEntry.timeId ? true : false)

  // This variable is used to check if the user has permissions to submit the time entry
  const [canSubmit] = useState(SecurityService.hasTimeOperationPermissions(TimeOperationsPermissions.Time_CloseTime));
  
  // This will change the narrative in the card entry
  function onNarrativeChange(value: string, spellChecked: number) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onNarrativeChange(${JSON.stringify({value, spellChecked})}) started` : '', MessageType.Info);
    setCardEntry({
      ...cardEntry,
      narrative: value,
      spellChecked: spellChecked,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onNarrativeChange(${JSON.stringify({value, spellChecked})}) ended` : '', MessageType.Info);
  }

  // This will change the time in the card entry
  function onTimeChange(value: number) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onTimeChange(${JSON.stringify({value})}) started` : '', MessageType.Info);
    setCardEntry({ ...cardEntry, totalSeconds: value, roundTimeDisplayValue: TimerService.convertSecondsToHoursAndMinutes(value)});
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onTimeChange(${JSON.stringify({value})}) ended` : '', MessageType.Info);
  }

  // This method is used to get the spell check dictionary based on the matter language or user selected language
  function getSpellCheckDictionary() {
    let dictionary = "";
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - getSpellCheckDictionary() started` : '', MessageType.Info);
      let matterDetails = props.cardEntry.matterObj;
      let matterLanguage =
        matterDetails &&
        matterDetails.userAddedFields &&
        matterDetails.userAddedFields.Language
          ? matterDetails.userAddedFields.Language
          : "";
  
      let defaultLanguage =
        ConfigService.getUserConfigurationData<IUserDictionary>(
          CONSTANTS.userDictionary
        )?.SelectedLanguage.Value ?? "en-GB";
  
      return dictionary = matterLanguage || defaultLanguage;
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - getSpellCheckDictionary() - ${error}` : '', MessageType.Error);
      return dictionary = "";
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - getSpellCheckDictionary() - output(${JSON.stringify({dictionary})}) ended` : '', MessageType.Info);
    }
  }

  // This method is used to prepare the time entry object for saving
  async function prepareTimeEntryForAdd() {
    let timeEntry = new TimeEntry();
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - prepareTimeEntryForAdd() started` : '', MessageType.Info);
      let roundingAndBillable = await getRoundingAndBillableValue();
      let roundingType =
        ConfigService.getConfigurationData<ITimeRestrictionsConfig>(
          CONSTANTS.timeRestrictions
        )?.AutoRound ?? "0";
            
      
      timeEntry.isAddEntry = true;
      timeEntry.clientId = cardEntry.clientId ?? "";
      timeEntry.clientName = cardEntry.clientName;
      timeEntry.projectId = cardEntry.matterId ?? "";
      timeEntry.projectName = cardEntry.matterName;
      timeEntry.timekeeperId = cardEntry.timekeeperId ?? "";
      timeEntry.timekeeperName = cardEntry.timekeeperName;
      timeEntry.dateWorked = cardEntry.dateWorked;
      timeEntry.narrativeForSheetview = cardEntry.narrative;

      timeEntry.billable = roundingAndBillable.billable;
      let billableOverriden = await TimeEntryService.getAllowBillableOverrideValue(timeEntry.clientId, timeEntry.projectId, timeEntry.timekeeperId);
      timeEntry.billableOverriden = billableOverriden ? "1" : "0";
      if (cardEntry.totalSeconds && cardEntry.totalSeconds < 0) {
        timeEntry.otherTime = cardEntry.totalSeconds;
        timeEntry.startStopSequences = [];
        timeEntry.rawTime = timeEntry.otherTime;
      } else {
        timeEntry.otherTime = 0;
        timeEntry.startStopSequences = [];

        if (cardEntry.totalSeconds && cardEntry.totalSeconds > 0) {
          let sequenceStartDateTime = moment();
          let sequenceStopDateTime = moment(sequenceStartDateTime).add(
            cardEntry.totalSeconds,
            "seconds"
          );
    
          timeEntry.startStopSequences.push({
            entryID: "1",
            startTime: sequenceStartDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS"),
            stopTime: sequenceStopDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS"),
          });
        }

        timeEntry.rawTime = cardEntry.totalSeconds;
      }

      timeEntry.remainingRawTime = 0;
      timeEntry.remainingRoundTime = 0;
      timeEntry.roundingValue = parseInt(
        roundingAndBillable.roundingValue ?? "0"
      );
      timeEntry.roundTime = TimerService.roundSeconds(
        timeEntry.rawTime ?? 0,
        timeEntry.roundingValue,
        +roundingType
      );
      timeEntry.roundTimeDisplayValue =
        TimerService.convertSecondsToHoursAndMinutes(timeEntry.roundTime);

      timeEntry.spellChecked = cardEntry.narrative ? cardEntry.spellChecked : 1;

      timeEntry.status = cardEntry.status;
      timeEntry.source = "MT";
      timeEntry.originalSource = "MT";
      timeEntry.comments = "";
      timeEntry.spellcheckDictionary = getSpellCheckDictionary();
      timeEntry.spellcheckOverride = 0;

      timeEntry.offset = TimeEntryService.getTimeZoneOffset();

      timeEntry.userCreated = sessionStorage.getItem(CONSTANTS.userId) ?? "";
      timeEntry.userEdited = timeEntry.userCreated;

      timeEntry.dateCreated = timeEntry.dateWorked
      timeEntry.dateEdited = timeEntry.dateWorked
      timeEntry.dateLastModified = timeEntry.dateWorked

      timeEntry.isTimerRunning = false;
      timeEntry.userAddedFields = {};

      timeEntry.userCodes = cardEntry.userCodes;
      timeEntry.userCodeIdDesc = cardEntry.userCodeIdDesc;

      if (cardEntry.matterObj && cardEntry.matterObj.userAddedFields) {
        for (let key in cardEntry.matterObj.userAddedFields) {
            if (key.toUpperCase() == "LANGUAGE") {
                timeEntry.matterLanguage = cardEntry.matterObj.userAddedFields[key];
                break;
            }
        }

    } else {
        timeEntry.matterLanguage = "";
    }
      
      return timeEntry;
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - prepareTimeEntryForAdd() - ${error}` : '', MessageType.Error);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - prepareTimeEntryForAdd() - output(${JSON.stringify({timeEntry})}) ended` : '', MessageType.Info);
    }
  }

  // This method is used to save the time entry
  async function saveEntry(isSubmit: boolean = false) {
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - saveEntry(${JSON.stringify({isSubmit})}) started` : '', MessageType.Info);
      setIsFetchingData(true);
      let timeEntry = await prepareTimeEntryForAdd() || new TimeEntry();
      let saveEntrySteps = TimeEntryService.getSaveEntryStepsForAdd();
      if (isSubmit) {
        saveEntrySteps.push(SaveEntrySteps.ValidateCloseRestrictions);
      }

      let timeEntryObj = TimeEntryService.prepairSaveRequest(
        timeEntry,
        saveEntrySteps
      );
      
      let response = await TimeEntryService.AddTimeEntry(timeEntryObj);
      if (response.timeID?.length > 0) {
        showSuccessMessage("Time entry saved successfully");
        clearCardEntry();
        setTrigger(!trigger);
      }
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - saveEntry(${JSON.stringify({isSubmit})}) - ${error}` : '', MessageType.Error);
      showErrorMessage(CONSTANTS.errorMessage);
    } finally {
      setIsFetchingData(false);
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - saveEntry(${JSON.stringify({isSubmit})}) ended` : '', MessageType.Info);
    }
  }

  // This method is used to get the rounding and billable value for the time entry
  async function getRoundingAndBillableValue() {
    let response : IRoundingBillableResponse = {};
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - getRoundingAndBillableValue() started` : '', MessageType.Info);
      let request: IRoundingBillableRequest = {
        clientId: cardEntry.clientId,
        matterId: cardEntry.matterId,
        costCode: "",
        tkprId: cardEntry.timekeeperId,
        userCodes: cardEntry.userCodes ?? {} as IDictionary<string>,
      };
  
      response = await TimeEntryService.getRoundingAndBillableValue(request);
      return response;
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - getRoundingAndBillableValue() - ${error}` : '', MessageType.Error);
      throw error;
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - getRoundingAndBillableValue() - output(${JSON.stringify({response})}) ended` : '', MessageType.Info);
    }
  }

  // This method is used to change the client details in the card entry. It will be called from the ClientField component when we change the client
  function onClientChange(newClientObj: IClient) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onClientChange(${JSON.stringify({newClientObj})}) started` : '', MessageType.Info);
    setCardEntry({
      ...cardEntry,
      clientId: newClientObj.clientId,
      clientName: newClientObj.clientName,
      clientObj: newClientObj,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onClientChange(${JSON.stringify({newClientObj})}) ended` : '', MessageType.Info);
  }

  // This method is used to clear the client details in the card entry. It will be called when we have to clear the client details
  function clearClientDetails() {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearClientDetails() started` : '', MessageType.Info);
    setCardEntry({
      ...cardEntry,
      clientId: "",
      clientName: "",
      clientObj: undefined,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearClientDetails() ended` : '', MessageType.Info);
  }

  // This method is used to change the matter details in the card entry. It will be called from the MatterField component when we change the matter
  function onMatterChange(newMatterObj: IMatter) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onMatterChange(${JSON.stringify({newMatterObj})}) started` : '', MessageType.Info);
    setCardEntry({
      ...cardEntry,
      matterId: newMatterObj.matterId,
      matterName: newMatterObj.matterName,
      matterObj: newMatterObj,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onMatterChange(${JSON.stringify({newMatterObj})}) ended` : '', MessageType.Info);
  }

  // This method is used to clear the matter details in the card entry. It will be called when we have to clear the matter details (when we change the client for example)
  function clearMatterDetails() {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearMatterDetails() started` : '', MessageType.Info);
    setCardEntry({
      ...cardEntry,
      matterId: "",
      matterName: "",
      matterObj: undefined,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearMatterDetails() ended` : '', MessageType.Info);
  }

  // This method is used to change the client and matter details in the card entry. It will be called when we have UNIQUE matter style and on selecting a matter first, we have to select the associated client as well
  function onClientMatterChange(newClientObj: IClient, newMatterObj: IMatter) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onClientMatterChange(${JSON.stringify({newClientObj, newMatterObj})}) started` : '', MessageType.Info);
    setCardEntry({
      ...cardEntry,
      clientId: newClientObj.clientId,
      clientName: newClientObj.clientName,
      clientObj: newClientObj,
      matterId: newMatterObj.matterId,
      matterName: newMatterObj.matterName,
      matterObj: newMatterObj,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onClientMatterChange(${JSON.stringify({newClientObj, newMatterObj})}) ended` : '', MessageType.Info);
  }

  // This method is used to change the UDC details in the card entry. It will be called from the UDCFields component when the dependant object of the UDC has changed
  // CodeX will be 'Code1', 'Code2', etc
  function clearUdcDetails(codeX: string) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearUdcDetails(${JSON.stringify({codeX})}) started` : '', MessageType.Info);
    setCardEntry((prevState) => {
      let newUserCodes = prevState.userCodes ? { ...prevState.userCodes } : {};
      let newUserCodeIdDesc = prevState.userCodeIdDesc
        ? { ...prevState.userCodeIdDesc }
        : {};
      let newUserCodesObj = prevState.userCodesObj
        ? { ...prevState.userCodesObj }
        : {};

      delete newUserCodes[codeX];
      delete newUserCodeIdDesc[codeX];
      delete newUserCodesObj[codeX];

      return {
        ...prevState,
        userCodes: newUserCodes,
        userCodeIdDesc: newUserCodeIdDesc,
        userCodesObj: newUserCodesObj,
      };
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearUdcDetails(${JSON.stringify({codeX})}) ended` : '', MessageType.Info);
  }

  // This method is used to change the UDC details in the card entry. It will be called from the UDCFields component when we change the UDC
  // CodeX will be 'Code1', 'Code2', etc
  // isFromDropdown will be true if the UDC is a dropdown field, false if it is a text field
  function onUdcChange(
    codeX: string,
    udcObj: IUserCode,
    isFromDropdown: boolean
  ) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onUdcChange(${JSON.stringify({codeX, udcObj, isFromDropdown})}) started` : '', MessageType.Info);
    let newUserCodes = { ...cardEntry.userCodes } || {};
    let newUserCodeIdDesc = { ...cardEntry.userCodeIdDesc } || {};
    let newUserCodesObj = { ...cardEntry.userCodesObj } || {};

    // Update the properties based on codeX
    newUserCodes[codeX] = udcObj.codeID ?? "";
    newUserCodeIdDesc[codeX] =
      udcObj.codeID + (isFromDropdown ? ":" + udcObj.codesetDescription : "");

    // UserCodeObject will not be available for text fields
    if (isFromDropdown && udcObj) {
      newUserCodesObj[codeX] = udcObj;
    }

    setCardEntry({
      ...cardEntry,
      userCodes: newUserCodes,
      userCodeIdDesc: newUserCodeIdDesc,
      userCodesObj: newUserCodesObj,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - onUdcChange(${JSON.stringify({codeX, udcObj, isFromDropdown})}) ended` : '', MessageType.Info);
  }

  // This method is used to display the error message
  const showErrorMessage = (errorMessage: string) => {
    notificationRef.current?.showNotification(errorMessage, true);
  };

  // This method is used to display the success message
  const showSuccessMessage = (successMessage: string) => {
    notificationRef.current?.showNotification(successMessage);
  };

  // This method is used to clear the card entry
  function clearCardEntry() { 
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearCardEntry() started` : '', MessageType.Info);
    setCardEntry({
      timekeeperId: cardEntry.timekeeperId ?? "",
      timekeeperName: cardEntry.timekeeperName ?? "",
      timekeeperObj: cardEntry.timekeeperObj ?? undefined,
      dateWorked: cardEntry.dateWorked,
      status: cardEntry.status,
    });
    LoggingService.log(LoggingService.isLoggingEnabled() ? `CardWidgetControl - clearCardEntry() ended` : '', MessageType.Info);
  }

  return (
    <form>
      <div className="list-body ">
        {
          <div style={{marginTop: '10px'}}>
             <NotificationMessage ref={notificationRef}/>
          </div>
        }

        <TimekeeperField
          timekeeperId={cardEntry.timekeeperId ?? ""}
          timekeeperName={cardEntry.timekeeperName ?? ""}
        ></TimekeeperField>

        <div>
          <TimeField
            cardEntry={cardEntry}
            onTimeChange={onTimeChange}
          ></TimeField>
        </div>

        <ClientField
          cardEntry={cardEntry}
          onClientChange={onClientChange}
          setIsFetchingData={setIsFetchingData}
          showErrorMessage={showErrorMessage}
          clearClientDetails={clearClientDetails}
        ></ClientField>

        <MatterField
          cardEntry={cardEntry}
          onMatterChange={onMatterChange}
          onClientMatterChange={onClientMatterChange}
          clearMatterDetails={clearMatterDetails}
          setIsFetchingData={setIsFetchingData}
          showErrorMessage={showErrorMessage}
        ></MatterField>

        <UdcFields
          cardEntry={cardEntry}
          onUdcChange={onUdcChange}
          clearUdcDetails={clearUdcDetails}
          setIsFetchingData={setIsFetchingData}
          showErrorMessage={showErrorMessage}
        ></UdcFields>

        <Narrative
          cardEntry={cardEntry}
          trigger={trigger}
          onNarrativeChange={onNarrativeChange}
          showErrorMessage={showErrorMessage}
          showSuccessMessage={showSuccessMessage}
        ></Narrative>

        <Button appearance="primary" onClick={() => saveEntry()} disabled={viewOnly}>
          Save
        </Button>

        <Button appearance="primary" onClick={() => saveEntry(true)} disabled={viewOnly || !canSubmit}>
          Submit
        </Button>
      </div>
      {isFetchingData && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </form>
  );
}
