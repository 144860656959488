import { ApiUrlConstant } from "../constants/api-url-constants";
import { IUserData } from "../models/IUserData";
import DataService from "./dataService";
import { ITimeKeeper } from "../models/ITimeKeeper";
import { ITimekeeperModel } from "../models/ITimekeeperModel";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";

// This will hold the default timekeeper model
let timekeeperModel: ITimekeeperModel;

// This will hold the timekeeper data
let timekeeperData: ITimeKeeper;

const TimekeeperService = { 
  // This method will be used to get the default timekeeper
  async getDefaultTimekeeper(): Promise<ITimekeeperModel> {
    let timekeeper : ITimekeeperModel = {
      timekeeperId: "",
      timekeeperName: ""
    };
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimekeeperService - getDefaultTimekeeper() started` : '', MessageType.Info);
      if (timekeeperModel) {
        return timekeeper = timekeeperModel;
      }

      let userData = await DataService.fetchData<IUserData>(ApiUrlConstant.userData.getUserData);
      let defaultTimekeeper = {
        timekeeperId: userData.defaultTimekeeperData.timekeeperId,
        timekeeperName: userData.defaultTimekeeperData.timekeeperName
      };
      
      timekeeperModel = defaultTimekeeper;
      return timekeeper = defaultTimekeeper;
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimekeeperService - getDefaultTimekeeper() - ${error}` : '', MessageType.Error);
      throw error;
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimekeeperService - getDefaultTimekeeper() - output(${JSON.stringify({timekeeper})}) ended` : '', MessageType.Info);
    }
  },

  // This method will be used to get the timekeeper data
  async getTimekeeperData(timekeeperId: string, allowAllTimekeeper: boolean, fetchTimekeeperGroupRoundingValue: boolean): Promise<ITimeKeeper> { 
    let timekeeper : ITimeKeeper = {
      timekeeperId: "",
      timekeeperName: ""
    }
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimekeeperService - getTimekeeperData(${JSON.stringify({timekeeperId, allowAllTimekeeper, fetchTimekeeperGroupRoundingValue})}) started` : '', MessageType.Info);
      if (timekeeperData) {
        return timekeeper = timekeeperData;
      }

      let timekeeperObj = await DataService.fetchData<ITimeKeeper>(ApiUrlConstant.timekeeper.getTimeKeepersDetailbyTimekeeperId.replace("{0}", timekeeperId).replace("{1}", allowAllTimekeeper.toString()).replace("{2}", fetchTimekeeperGroupRoundingValue.toString()));
      timekeeperData = timekeeperObj;
      return timekeeper = timekeeperObj;
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimekeeperService - getTimekeeperData(${JSON.stringify({timekeeperId, allowAllTimekeeper, fetchTimekeeperGroupRoundingValue})}) - ${error}` : '', MessageType.Error);
      throw error;
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimekeeperService - getTimekeeperData(${JSON.stringify({timekeeperId, allowAllTimekeeper, fetchTimekeeperGroupRoundingValue})}) - output(${JSON.stringify({timekeeper})}) ended` : '', MessageType.Info);
    }
  }
}

export default TimekeeperService;