import { useEffect, useState } from "react";
import {
  TabList,
  Tab,
  SelectTabEvent,
  SelectTabData,
  TabValue,
  Spinner,
  makeStyles,
  Button
} from "@fluentui/react-components";
import "./dashboard.css";
import { FoundView as FoundWidget } from "../widgets/FoundWidget";
import CreateTime from "../components/CreateTime";
import ConfigService from "../services/configService";
import SoftTextService from "../services/softTextService";
import DayViewWidget from "../widgets/DayViewWidget";
import { CONSTANTS } from "../constants/constants";
import SecurityService from "../services/securityService";
import { UserLicenses } from "../enums/userLicences";
import SettingsWidget from "../widgets/SettingsWidget";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { useAuth } from "../contexts/AuthContext";
import JwtHelperService from "../services/jwtHelperService";
import { useNavigate } from "react-router-dom";
import { NotificationMessage, NotificationMessageHandler } from "../components/NotificationMessage";
import React from "react";
import { HostClientType } from "@microsoft/teams-js";

const useStyles = makeStyles({
  spinner: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  noLicense: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%'
  }
});

export default function Dashboard() {

  const styles = useStyles();

  // Used to access the token
  const auth = useAuth();

  // Used to call navigate method
  const navigate = useNavigate();

  // This is used to get the notification ref
  const notificationRef = React.useRef<NotificationMessageHandler>(null);

  // It will hold current tab selected
  const [selectedValue, setSelectedValue] = useState<TabValue>(CONSTANTS.create);

  // Is code inside useEffect completed?
  const [isLoaded, setIsLoaded] = useState(false);

  // Does the user have access to use the application
  const [hasAccess, setHasAccess] = useState<boolean>();

  // This function will be called when a tab is selected
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `Dashboard - onTabSelect(${JSON.stringify(data)}) started` : '', MessageType.Info);
    setSelectedValue(data.value);
    LoggingService.log(LoggingService.isLoggingEnabled() ? `Dashboard - onTabSelect(${JSON.stringify(data)}) - ended` : '', MessageType.Info);
  };

  useEffect(() => {
    try {      
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'Dashboard - useEffect() started' : '', MessageType.Info);

      // Keep validating the token after every 1 minute
      const interval = setInterval(() => {
        validateToken();
      }, 60000);

      SecurityService.loadPermissionsAndLicenses();
      let hasCDTimeLicense = SecurityService.hasLicense(UserLicenses.CDTIME);
      let isSuperAdmin = SecurityService.isSuperAdmin();
      let canUseApplication = hasCDTimeLicense && !isSuperAdmin;
      setHasAccess(canUseApplication);
      if (canUseApplication) {
        loadConfigurationData();
      }
      
      return () => {
        clearInterval(interval);
      };
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `Dashboard - useEffect() - ${error}` : '', MessageType.Error);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'Dashboard - useEffect() ended' : '', MessageType.Info);
    }
  }, []);

  // Load configuration data, user configuration data and soft text details
  const loadConfigurationData = async () => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'Dashboard - loadConfigurationData() started' : '', MessageType.Info);
    await Promise.all([
      ConfigService.loadConfigurationData(),
      ConfigService.loadUserConfigurationData()
    ]);

    SoftTextService.loadAllSoftTextDetails();
    setIsLoaded(true);
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'Dashboard - loadConfigurationData() ended' : '', MessageType.Info);
  };

  // Checks if the token has expired
  function validateToken() {
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'Dashboard - validateToken() started' : '', MessageType.Info);
    if (auth.userToken) {
      const decodedJwt = JwtHelperService.parseJwt(auth.userToken);
      if (decodedJwt != null && decodedJwt.exp * 1000 < Date.now()) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Dashboard - token has expired` : '', MessageType.Info);
        let hostClientType = sessionStorage.getItem('hostClientType');
        if (hostClientType !== HostClientType.web) {
          let refreshToken = sessionStorage.getItem("refreshToken");
          if (refreshToken) {
            auth.getAccessTokenFromRefreshToken(refreshToken);
          }
        } else {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `Dashboard - Redirecting user to login redirect screen as token has expired` : '', MessageType.Info);
          notificationRef.current?.showNotification(CONSTANTS.tokenExpiredMessage, true, 5000);
          setTimeout(() => {
            // navigate after 5 seconds
            navigate("/loginRedirect");
          }, 5000)
        }
      }
    }
  }

  const handleLogout = () => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `Dashboard - Logout event clicked` : '', MessageType.Info);
    auth.logout();
    LoggingService.log(LoggingService.isLoggingEnabled() ? `Dashboard - Navigating to login after logout click` : '', MessageType.Info);
    navigate("/tab");
  };

  return (
    hasAccess ? (
      isLoaded ? (
        <div className="welcome page">
    <NotificationMessage ref={notificationRef}/>
    <div className="narrow">
        <div className="tabList">
            <div className="display-flex">
                <div className="w-90-pr">
                    <TabList
                        selectedValue={selectedValue}
                        onTabSelect={onTabSelect}
                        size="large"
                        appearance="subtle"
                    >
                        <Tab id="Create" value={CONSTANTS.create}>
                            Create
                        </Tab>
                        <Tab id="Found" value={CONSTANTS.found}>
                            Found
                        </Tab>
                        <Tab id="Today" value={CONSTANTS.today}>
                            Today 
                        </Tab>
                        <Tab id="Settings" value={CONSTANTS.settings}>
                            Settings
                        </Tab>
                    </TabList>
                </div>
                <div className="w-10-pr">
                    <Button type="button" appearance="primary" className="center-button" onClick={handleLogout}>Logout</Button>
                </div>
            </div>
            <div>
                {selectedValue === CONSTANTS.create && (
                    <div className="cardWidget">
                        <CreateTime />
                    </div>
                )}
                {selectedValue === CONSTANTS.found && (
                    <div>
                        <FoundWidget />
                    </div>
                )}
                {selectedValue === CONSTANTS.today && (
                    <div>
                        <DayViewWidget />
                    </div>
                )}
                {selectedValue === CONSTANTS.settings && (
                    <div>
                        <SettingsWidget />
                    </div>
                )}
            </div>
        </div>
    </div>
</div>

      ) : (
        <div className={styles.spinner}> 
          <Spinner size="large" labelPosition="below" label="Loading..." /> 
        </div>
      )
    ) : (
      <div className={styles.noLicense}>You do not have the license to use this application.</div>
    )
  );
}
