import { Button, Switch } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import LoggingService from "../services/loggingService";
import { useNavigate } from "react-router-dom";
import { NotificationMessage, NotificationMessageHandler } from "../components/NotificationMessage";
import React from "react";
import { useAuth } from "../contexts/AuthContext"
import { MessageType } from "../enums/messageType";
import { HostClientType } from "@microsoft/teams-js";
import { CONSTANTS } from "../constants/constants";

export default function SettingsWidget() {

    // Used to access the token
    const auth = useAuth();
  
    // State to store the value of slider for logging
    const [isLoggingEnabled, setIsLoggingEnabled] = useState<boolean>(false);

    // Used to call navigate method
    const navigate = useNavigate();

    // This is used to get the notification ref
    const notificationRef = React.useRef<NotificationMessageHandler>(null);

    // This method will be called when the component is mounted
    useEffect(() => {
        let loggingEnabled = sessionStorage.getItem("isLoggingEnabled") === "true";
        setIsLoggingEnabled(loggingEnabled);
        LoggingService.setIsLoggingEnabled(loggingEnabled);
    }, []);
    
    // Function to handle the change in logging slider
    const handleLoggingChange = () => {
        sessionStorage.setItem("isLoggingEnabled", String(!isLoggingEnabled));
        LoggingService.setIsLoggingEnabled(!isLoggingEnabled);
        setIsLoggingEnabled(!isLoggingEnabled);   
    };

    // Function to download logs
    function downloadLogs() {
        LoggingService.downloadLogs();
    }

    // Function to clear logs
    function clearLogs() {
        LoggingService.clearLogs();
    }

    // Function to clear sessionStorage
    async function clearCache() {
        var hostClientType = sessionStorage.getItem('hostClientType');
        let refreshToken = sessionStorage.getItem('refreshToken') ?? "";
        
        sessionStorage.clear();
        sessionStorage.setItem("isLoggingEnabled", String(isLoggingEnabled));
        sessionStorage.setItem('hostClientType', hostClientType ?? "");
        if (hostClientType === HostClientType.web) {
            localStorage.removeItem(CONSTANTS.user_region);
            notificationRef.current?.showNotification("Cache cleared successfully. Redirecting to login page...", false, 5000);
            setTimeout(() => {
                auth.clearToken();
                navigate("/loginRedirect");
            }, 5000)

        } else {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `SettingsWidget - Current token ${auth.userToken}` : '', MessageType.Info);
            await auth.getAccessTokenFromRefreshToken(refreshToken);
            notificationRef.current?.showNotification("Cache cleared successfully.", false, 5000);
        }
    }
    
    return (
        <div>
            <NotificationMessage ref={notificationRef}/>
            <div>
                <p style={{ margin: '0.6%', fontSize: 'x-large' }}>Logs</p>
                <p style={{ margin: '0.6%' }}>Please activate this feature only during troubleshooting. To avoid negatively impacting the application, ensure it is turned off when not in use.</p>
                <p style={{ margin: '0.6%' }}>Note that logs will be <strong>recycled every 5 minutes</strong> while this feature is enabled.</p>
                <Switch label="Enable logging" checked={isLoggingEnabled} onChange={handleLoggingChange} />
                <div>
                    {isLoggingEnabled && (
                        <div style={{ margin: '0.7%' }}>
                            <Button style={{ marginRight: '10px' }} onClick={downloadLogs}>
                                Download Logs
                            </Button>

                            <Button onClick={clearLogs}>
                                Clear Logs
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div style={{paddingTop: '3rem'}}>
                <p style={{ margin: '0.6%', fontSize: 'x-large' }}>Clear cache</p>
                <Button style={{ margin: '0.7%' }} onClick={clearCache}>Clear Cache</Button>
            </div>
        </div>
    );
}