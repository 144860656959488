import { ComboboxProps, Field, makeStyles } from "@fluentui/react-components";
import ClientService from "../services/clientService";
import { useState } from "react";
import { ICardEntry } from "../models/ICardEntry";
import { IClient } from "../models/IClient";
import moment from "moment";
import { CONSTANTS } from "../constants/constants";
import SoftTextService from "../services/softTextService";
import CustomCombobox from "./CustomCombobox";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { ILookUpData } from "../models/ILookUpData";

interface ClientFieldProps extends Partial<ComboboxProps> {
  cardEntry : ICardEntry;
  onClientChange: (clientObj: IClient) => void;
  setIsFetchingData: (isFetchingData: boolean) => void;
  showErrorMessage: (exception: string) => void;
  clearClientDetails: () => void;
}

const useStyles = makeStyles({
  container: {
    "> div": { display: "initial" },
  },
});

export default function ClientField(props: ClientFieldProps) {

  const styles = useStyles();
  
  // This state will be used to store the validation message
  const [validationMessage, setValidationMessage] = useState("");

  // This state will be used to store the soft text value for the client
  const [clientSoftText] = useState(SoftTextService.getSoftTextValue(CONSTANTS.client))
  

  // This method will be called when we have to get the clients for the dropdown
  async function getClients(startIndex: number, searchString: string) {
    let clients: ILookUpData[] = []
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientField - getClients(${JSON.stringify({startIndex, searchString})}) started` : '', MessageType.Info);
      // Clear the validation message
      setValidationMessage("");
      clients = await ClientService.getClients(startIndex, CONSTANTS.dropdownLength, searchString);
      return clients;
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientField - getClients(${JSON.stringify({startIndex, searchString})}) - ${error}` : '', MessageType.Error);
      props.showErrorMessage(CONSTANTS.errorMessage);
      throw error;
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientField - getClients(${JSON.stringify({startIndex, searchString})}) - output(${JSON.stringify(clients)}) ended` : '', MessageType.Info);
    }
  }
  

  // This method will be called when the user selects a client
  async function handleClientChange (newClientId: string) {
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientField - handleClientChange(${JSON.stringify({newClientId})}) started` : '', MessageType.Info);
      if (!newClientId) {
        if (props.cardEntry.clientId) { 
          // If the client is not selected, then we have to clear the client object from the card entry
          props.onClientChange({});
        }
  
        return;
      }
  
      // Start fetching data
      props.setIsFetchingData(true); // To block the UI while fetching data
  
      let timekeeperId = props.cardEntry?.timekeeperId ?? "";
      let dateWorked = moment(props.cardEntry.dateWorked).format("YYYY-MM-DDTHH:mm:ss.SSS");
  
      let data = await ClientService.validateClient(newClientId, timekeeperId, dateWorked, props.cardEntry);
  
      if (data.status && data.entity) {
        // If we have recieved the client object, then we will call the onClientChange method
        props.onClientChange(data.entity); 
      } else {
        // We have to delete the client from the card entry
        props.clearClientDetails();
        setValidationMessage(data.notificationMessage ?? "");
      }
  
      // Finish fetching data
      props.setIsFetchingData(false); // To unblock the UI after fetching data
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientField - handleClientChange(${JSON.stringify({newClientId})}) - ${error}` : '', MessageType.Error);
      props.setIsFetchingData(false);
      props.showErrorMessage(CONSTANTS.errorMessage);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientField - handleClientChange(${JSON.stringify({newClientId})}) ended` : '', MessageType.Info);
    }
  };


  // This method will be called in the dropdown to check if the dependant item is selected or not before calling the fetchData() method
  function isDependantSelected() {
    // Since client does not have any parent, we have hardcoded the value to true
    return true;
  }


  return (
    <div>
      <Field validationMessage={validationMessage} className={styles.container} required={true}>
        <CustomCombobox 
          entity={CONSTANTS.client} title={clientSoftText} selectedId={props.cardEntry.clientId ?? ""} selectedName={props.cardEntry.clientName ?? ""} validationMessage={validationMessage} cardEntry={props.cardEntry}
          isDependantSelected={isDependantSelected} getDropdownItems={getClients} onSelectionChange={handleClientChange} isRequired={true}>
        </CustomCombobox>
      </Field>   
    </div>
  )
}