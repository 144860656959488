import { ComboboxProps, Field, makeStyles } from "@fluentui/react-components";
import { ICardEntry } from "../models/ICardEntry";
import { useEffect, useRef, useState } from "react";
import CustomCombobox from "./CustomCombobox";
import { IUserCodeResponse } from "../models/IUserCodeResponse";
import UserCodeService from "../services/userCodeService";
import { DependsOnTables } from "../enums/dependsOnTables";
import ConfigService from "../services/configService";
import { CONSTANTS } from "../constants/constants";
import SoftTextService from "../services/softTextService";
import { ILookUpData } from "../models/ILookUpData";
import { IUserCode } from "../models/IUserCode";
import { IUdcFieldModel } from "../models/IUdcFieldModel";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";

interface UdcDropdownFieldProps extends Partial<ComboboxProps>{
    cardEntry: ICardEntry;
    field: IUdcFieldModel;
    onUdcChange: (codeX: string, udcObj: IUserCode, isFromDropdown: boolean) => void;
    clearUdcDetails: (codeX: string) => void;
    setIsFetchingData: (isFetchingData: boolean) => void;
    showErrorMessage: (exception: string) => void;
}

const useStyles = makeStyles({
    container: {
      "> div": { display: "initial" },
    },
});


export default function UdcDropdownField(props: UdcDropdownFieldProps) {

    const styles = useStyles();
    
    // State to store the validation message
    const [validationMessage, setValidationMessage] = useState("");

    // Get the parent UDC
    const parent = +props.field.DependsOn >= 5 ? props.cardEntry.userCodes?.["Code" + (+props.field.DependsOn - 4)] : null;

    // Whenever the parent UDC has changed, we might have to clear the UDC details
    const firstRender1 = useRef(true);
    useEffect(() => {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - useEffect() for parent started. Dependency array - ${JSON.stringify({parent})}` : '', MessageType.Info);
        if (firstRender1.current) {
            firstRender1.current = false;
          return;
        }

        if (parent) {
            setValidationMessage("");
        }

        props.clearUdcDetails(props.field.Name);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - useEffect() for parent ended. Dependency array - ${JSON.stringify({parent})}` : '', MessageType.Info);
      }, [parent]);


    // Whenever a client has changed, we might have to clear the UDC details  
    const firstRender2 = useRef(true);
    useEffect(() => { 
        let clientId = props.cardEntry.clientId ?? "";
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - useEffect() for client started. Dependency array - ${JSON.stringify({clientId})}` : '', MessageType.Info);
        if (firstRender2.current) {
            firstRender2.current = false;
          return;
        }

        if (+props.field.DependsOn == DependsOnTables.Client) {
            if (props.cardEntry.clientId) {
                setValidationMessage("");
            }

            props.clearUdcDetails(props.field.Name);
        }  else if (+props.field.DependsOn == DependsOnTables.ClientMatter) {

            if (props.cardEntry.clientId || props.cardEntry.matterId) {
                setValidationMessage("");
            }
           // Previously, we used the codeset (which we get from the dependant entity) to display the values in the dropdown and selected one among them. Now since we have changed the client, the codeset might have changed
           // we need to check if the codeset has changed. If it has changed, then we need to clear the UDC details
           let codeSet = findCodeSet();
           if (codeSet != props.cardEntry.userCodesObj?.[props.field.Name]?.codeSet) {
               props.clearUdcDetails(props.field.Name);
           }
        }

        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - useEffect() for client ended. Dependency array - ${JSON.stringify({clientId})}` : '', MessageType.Info);
    }, [props.cardEntry.clientId]);

    // Whenever a matter has changed, we might have to clear the UDC details  
    const firstRender3 = useRef(true);
    useEffect(() => { 
        let matterId = props.cardEntry.matterId ?? "";
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - useEffect() for matter started. Dependency array - ${JSON.stringify({matterId})}` : '', MessageType.Info);
        if (firstRender3.current) {
            firstRender3.current = false;
          return;
        }

        if (+props.field.DependsOn == DependsOnTables.Matter) {
            if (props.cardEntry.matterId) {
                setValidationMessage("");
            }

            props.clearUdcDetails(props.field.Name);
        } else if (+props.field.DependsOn == DependsOnTables.ClientMatter) {
            if (props.cardEntry.clientId || props.cardEntry.matterId) {
                setValidationMessage("");
            }

           // Previously, we used the codeset (which we get from the dependant entity) to display the values in the dropdown and selected one among them. Now since we have changed the matter, the codeset might have changed
           // we need to check if the codeset has changed. If it has changed, then we need to clear the UDC details
           let codeSet = findCodeSet();
           if (codeSet != props.cardEntry.userCodesObj?.[props.field.Name]?.codeSet) {
               props.clearUdcDetails(props.field.Name);
           }
        }

        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - useEffect() for matter ended. Dependency array - ${JSON.stringify({matterId})}` : '', MessageType.Info);
    }, [props.cardEntry.matterId]);
    

    // This function is used to get the UDC data for the dropdown
    async function getUdcData(startIndex: number, searchString: string, parent: string) : Promise<IUserCodeResponse>{
        let res: IUserCodeResponse = {};
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - getUdcData(${JSON.stringify({startIndex, searchString, parent})}) started` : '', MessageType.Info);
            // Clear the validation message
            setValidationMessage("");

            let codeset = findCodeSet();

            // If the UDC is dependant on another entity and if that entity has not defined a codeset and there is no default value for the codeset, then we will not fetch the UDC data
            if (!codeset && +props.field.DependsOn != DependsOnTables.None) return {};
            
            res = await UserCodeService.getUserCodes(props.field.Name, codeset, searchString, startIndex, 25, parent);
            return res;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - getUdcData(${JSON.stringify({startIndex, searchString, parent})}) - ${error}` : '', MessageType.Error);
            props.showErrorMessage(CONSTANTS.errorMessage);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - getUdcData(${JSON.stringify({startIndex, searchString, parent})}) - output(${JSON.stringify({res})}) ended` : '', MessageType.Info);
        }
    }


    // This function is ueed to find the CodeSet.
    // What does codeset mean? If a UDC is dependant on a client, in that client table there will be a column called CODESETX (X can be 1, 2, ... etc)
    // The value in the codeset column will be used to fetch values for the UDC.
    function findCodeSet() : string {
        let codeSet = "";
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - findCodeSet() started` : '', MessageType.Info);
            let codeSetX = "CODESET" + getCodeIndex(props.field.Name);
            
            let dependsOn = +props.field.DependsOn;
            switch(dependsOn) {
                case DependsOnTables.None : { 
                    break;
                }
                case DependsOnTables.Client : {
                    let clientCodeSetValues = props.cardEntry.clientObj?.codesetValues;
                    if(clientCodeSetValues && clientCodeSetValues[codeSetX]) {
                        codeSet = clientCodeSetValues[codeSetX];
                    }
    
                    break;
                }
                case DependsOnTables.Matter : {
                    let matterCodeSetValues = props.cardEntry.matterObj?.codesetValues;
                    if (matterCodeSetValues && matterCodeSetValues[codeSetX]) {
                        codeSet = matterCodeSetValues[codeSetX];
                    }
    
                    break;
                }
                case DependsOnTables.Timekeeper : { 
                    let timekeeperCodeSetValues = props.cardEntry.timekeeperObj?.codesetValues;
                    if (timekeeperCodeSetValues && timekeeperCodeSetValues[codeSetX]) {
                        codeSet = timekeeperCodeSetValues[codeSetX];
                    }
    
                    break;
                }
                case DependsOnTables.ClientMatter : {
                    // If CodeSetX value of MatterObject is Empty and a Client has been selected, then pick up CodeSetX value from ClientObject
                    if (props.cardEntry.matterObj) {
                        let matterCodeSetValues = props.cardEntry.matterObj?.codesetValues;
                        if(matterCodeSetValues && matterCodeSetValues[codeSetX]) {
                            codeSet = matterCodeSetValues[codeSetX];
                        }
    
                        // If CodeSetX value of MatterObject is Empty and a Client has been selected, then pick up CodeSetX value from ClientObject
                        if (!codeSet && props.cardEntry.clientObj) {
                            let clientCodeSetValues = props.cardEntry.clientObj?.codesetValues;
                            if(clientCodeSetValues && clientCodeSetValues[codeSetX]) {
                                codeSet = clientCodeSetValues[codeSetX];
                            }
                        }
                    } else if (props.cardEntry.clientObj){
                        let clientCodeSetValues = props.cardEntry.clientObj?.codesetValues;
                        if(clientCodeSetValues && clientCodeSetValues[codeSetX]) {
                            codeSet = clientCodeSetValues[codeSetX];
                        }
                    }
    
                    break;
                }
                default: {
                    const CODE_CONFIG_INDEX = 4;
    
                    // How will I get the parent UDC? The dependsOn property will have the value of the parent UDC (e.g. 1 for client, 2 for matter, 3 for timekeeper, 4 for clientMatter, 5 for code1, 6 for code2, 7 for code3, 8 for code4, etc.)
                    let parentIndex = dependsOn - CODE_CONFIG_INDEX; // Hence we subtract 4 from the dependsOn value to get the parent UDC index (If dependsOn is 5, then parentIndex will be 1, if dependsOn is 6, then parentIndex will be 2, etc.)
                    let udcCodeSetValues = props.cardEntry.userCodesObj?.["Code" + parentIndex]?.codesetValues ?? undefined;
                    if (udcCodeSetValues && udcCodeSetValues[codeSetX]) {
                        codeSet = udcCodeSetValues[codeSetX];
                    }
                }
            }
    
            // If the codeSet is still empty, then we will use the default value from the UDC configuration
            return codeSet ? codeSet : props.field.NullCodeSet ?? "";
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - findCodeSet() - ${error}` : '', MessageType.Error);
            return codeSet = "";
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - findCodeSet() - output(${JSON.stringify({codeSet})}) ended` : '', MessageType.Info);
        }
    }


    // This function is used to get the code index from the code name (e.g. code1 will give 1, code2 will give 2, etc.)
    function getCodeIndex(id: string): number {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - getCodeIndex(${JSON.stringify({id})}) started` : '', MessageType.Info);
        let codeId: number = parseInt(id);
        try {
            if (isNaN(codeId)) {
                let match = id.match(/^code(\d+)/i);
                codeId = match ? parseInt(match[1]) : NaN;
            }

            return codeId;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - getCodeIndex(${JSON.stringify({id})}) - ${error}` : '', MessageType.Error);
            return codeId;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - getCodeIndex(${JSON.stringify({id})}) - output(${JSON.stringify({codeId})}) ended` : '', MessageType.Info);
        }
    }


    // This function is called when the user selects a new value from the dropdown
    async function handleUdcChange (newId: string) {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - handleUdcChange(${JSON.stringify({newId})}) started` : '', MessageType.Info);
            if (!newId) {
                if (props.cardEntry.userCodes?.[props.field.Name]) {
                    props.onUdcChange(props.field.Name, {}, true);
                }
    
                return;
            }
            
            // Start fetching data
            props.setIsFetchingData(true);        
            let codeset = findCodeSet();
    
            let data = await UserCodeService.validateUdc(props.field.Label, props.field.Name, newId, codeset, props.cardEntry);
            if (data.status && data.entity) {
                props.onUdcChange(props.field.Name, data.entity, true);
            } else {
                setValidationMessage(data.notificationMessage ?? "");
                props.clearUdcDetails(props.field.Name);
            }
    
            props.setIsFetchingData(false);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - handleUdcChange(${JSON.stringify({newId})}) - ${error}` : '', MessageType.Error);
            props.setIsFetchingData(false);
            props.showErrorMessage(CONSTANTS.errorMessage);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - handleUdcChange(${JSON.stringify({newId})}) ended` : '', MessageType.Info);
        }
    }


    // This function is used to check if the dependant entity is selected
    function isDependantSelected() {
        let result = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - isDependantSelected() started` : '', MessageType.Info);
            let clientSoftText = SoftTextService.getSoftTextValue(CONSTANTS.client)
            let matterSoftText = SoftTextService.getSoftTextValue(CONSTANTS.matter)
    
            if (+props.field.DependsOn == DependsOnTables.Client && !props.cardEntry.clientId) { 
                setValidationMessage(`${props.field.Label} cannot be selected until ${clientSoftText} has been selected.`);
                return result = false;
            }
    
            if (+props.field.DependsOn == DependsOnTables.Matter && !props.cardEntry.matterId) {
                setValidationMessage(`${props.field.Label} cannot be selected until ${matterSoftText} has been selected.`);
                return result = false;
            }
    
            // No need to add a check for Timekeeper, as it is always selected by default and cannot be changed
    
            if (+props.field.DependsOn == DependsOnTables.ClientMatter && !props.cardEntry.clientId && !props.cardEntry.matterId) {
                setValidationMessage(`${props.field.Label} cannot be selected until ${clientSoftText} or ${matterSoftText} has been selected.`);
                return result = false;
            }
    
            // How will I get the parent UDC? The dependsOn property will have the value of the parent UDC (e.g. 1 for client, 2 for matter, 3 for timekeeper, 4 for clientMatter, 5 for code1, 6 for code2, 7 for code3, 8 for code4, etc.)
            let parentIndex = +props.field.DependsOn - 4;
            if (+props.field.DependsOn >= 5 && !props.cardEntry.userCodes?.["Code" + parentIndex]) {
                const udcFields: IUdcFieldModel[] = ConfigService.getUDCFields(); 
                let parentCodeName = udcFields.find(x => x.Name == "Code" + parentIndex)?.Label;
                let message = `${props.field.Label} cannot be selected until ${parentCodeName} has been selected.`;
                setValidationMessage(message);
                return result = false;
            }
    
            return result = true;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - isDependantSelected() - ${error}` : '', MessageType.Error);
            return result = false;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `UdcDropdownField - isDependantSelected() - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    }

      return(
        <div>
            <Field validationMessage={validationMessage} className={styles.container} required={props.field.Required == "true" ? true : false}>
                <CustomCombobox title={props.field.Label} entity={props.field.Name}
                    isUdc={true}
                    isRequired={props.field.Required == "true" ? true : false}
                    isHierarchical={props.field.IsHierarchical == "true" ? true : false}
                    selectedId={(props.cardEntry.userCodeIdDesc?.[props.field.Name] ?? "").split(":")[0] ?? ""}
                    selectedName={(props.cardEntry.userCodeIdDesc?.[props.field.Name] ?? "").split(":")[1] ?? ""}
                    getDropdownItemsForUdc={getUdcData}
                    onSelectionChange={handleUdcChange}
                    cardEntry={props.cardEntry} 
                    isDependantSelected={isDependantSelected}
                    getDropdownItems={function (startIndex: number, searchString: string): Promise<ILookUpData[]> {
                        throw new Error("Function not implemented.");
                    } }
                    validationMessage={validationMessage} 
                /> 
            </Field>
        </div>
    )
}