import { app, authentication } from '@microsoft/teams-js';
import React from 'react';

class ClosePopup extends React.Component {

    componentDidMount() {
        const params = new URLSearchParams(window.location.hash.replace('#/auth-end', ''));
        const result = params.get('code');
        let state : string[] | undefined = params.get('state')?.split('|');

        let authId : string = "";
        let method : string = "";

        state?.map((item, index) => {
            if (index === 0){
              authId = item.split(':')[1];
            }
            else{
                method = item.split(':')[1];
            }
        })
      
        if (method === "page") {
            
          app.initialize().then(() => {
            app.getContext().then(async (context) => {
              authentication.notifySuccess(result!);
            })
          });
        } 
        else if (method === "deeplink") {
     
          var encodedWebUrl = encodeURIComponent(`${window.location.origin}`);
          var encodedContext = encodeURIComponent(JSON.stringify({"subEntityId": result}));
          var deepLinkUrl = `https://teams.microsoft.com/l/entity/${authId}/dashboard?webUrl=${encodedWebUrl}&context=${encodedContext}`;
            
          window.location.href = deepLinkUrl;

          //window.location.href = `msteams://teams.microsoft.com/l/auth-callback?authId=${authId}&result=${result}`
        } 
        else {
            alert("failed");
        }
    }     

    render() {
      return (
        <div>
            <h1>Authentication complete.</h1>
        </div>
      );
    }
}

export default ClosePopup;