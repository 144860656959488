import { useEffect, useRef, useState } from "react";
import MatterService from "../services/matterService";
import { ICardEntry } from "../models/ICardEntry";
import { IMatter } from "../models/IMatter";
import ConfigService from "../services/configService";
import ClientService from "../services/clientService";
import { IClient } from "../models/IClient";
import { Field, makeStyles } from "@fluentui/react-components";
import { IIniConfig } from "../models/IIniConfig";
import { CONSTANTS } from "../constants/constants";
import { matterStyles } from "../enums/matterStyle";
import moment from "moment";
import SoftTextService from "../services/softTextService";
import CustomCombobox from "./CustomCombobox";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { ILookUpData } from "../models/ILookUpData";

interface IMatterFieldProps {
    cardEntry : ICardEntry;
    onMatterChange: (newMatterObj: IMatter) => void;
    onClientMatterChange: (newClientObj: IClient, newMatterObj: IMatter) => void;
    clearMatterDetails: () => void;
    setIsFetchingData: (isFetchingData: boolean) => void;
    showErrorMessage: (exception: string) => void;
}

const useStyles = makeStyles({
    container: {
      "> div": { display: "initial" },
    },
});

export default function MatterField(props : IMatterFieldProps) {

    const styles = useStyles();

    // Stores the soft text value for the matter and client
    const [matterSoftText] = useState(SoftTextService.getSoftTextValue(CONSTANTS.matter));
    const [clientSoftText] = useState(SoftTextService.getSoftTextValue(CONSTANTS.client))

    // Indicates if the client has been updated due to matter change (This will happen when we have UNIQUE matter style and if we select a matter, the client will be updated based on the matter selected.)
    const [clientUpdatedDueToMatter, setClientUpdatedDueToMatter] = useState(false);

    // This state will be used to store the validation message
    const [validationMessage, setValidationMessage] = useState("");

    // Indicates if this is the first render of the component
    const firstRender = useRef(true);

    // This method will be called whenever the client Id changes
    useEffect(() => { 
        let clientId = props.cardEntry.clientId;
        LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - useEffect() for clientId started. Dependency array - ${JSON.stringify({clientId})}` : '', MessageType.Info);

        // If this is the first render, we will skip the rest of the code as I do not want to clear the matter details.
        // Note : Even though we have passed the clientId to the dependency array, the useEffect will be called the first time irrespective of whether the client Id has changed.
        if (firstRender.current) {
          firstRender.current = false;
          return;
        }

        // If the client has been updated due to matter change, we should not clear the matter details. But if the client has been updated due to some other reason, we should clear the matter details.
        if (!clientUpdatedDueToMatter) {
            props.clearMatterDetails();
        } else { 
            // Reset clientUpdatedDueToMatter state
            setClientUpdatedDueToMatter(false);
        }

        if (props.cardEntry.clientId) {
            setValidationMessage("");
        }

        LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - useEffect() for clientId ended. Dependency array - ${JSON.stringify({clientId})}` : '', MessageType.Info);
    }, [props.cardEntry.clientId]);


    // Function to check if the client is selected
    function isDependantSelected() {
        let result : boolean = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - isDependantSelected() started` : '', MessageType.Info);
            let matterStyle = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config)?.MatterStyle ?? "0";
            // Matter Style is Normal
            if (+matterStyle === matterStyles.Normal && !props.cardEntry.clientId) {
                setValidationMessage(`${matterSoftText} cannot be selected until a ${clientSoftText} is selected.`);
                return result = false;
            } else {
                // If Matter Style is UNIQUE, it will not be dependent on client
                return result = true;
            }
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - isDependantSelected() - ${error}` : '', MessageType.Error);
            return result = false;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - isDependantSelected() - output(${JSON.stringify(result)}) ended` : '', MessageType.Info);
        }
    }
    

    // Function to get the matter details
    async function getMatter(startIndex: number, searchString: string) {
        let matters: ILookUpData[] = []
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - getMatter(${JSON.stringify({startIndex, searchString})}) started` : '', MessageType.Info);
            // Clear the validation message
            setValidationMessage("");
            let matters = await MatterService.getMatter(props.cardEntry.clientId ?? "", startIndex, CONSTANTS.dropdownLength, searchString);
            return matters;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - getMatter(${JSON.stringify({startIndex, searchString})}) - ${error}` : '', MessageType.Error);
            props.showErrorMessage(CONSTANTS.errorMessage);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - getMatter(${JSON.stringify({startIndex, searchString})}) - output(${JSON.stringify(matters)}) ended` : '', MessageType.Info);
        }
    }

    // Function to handle the matter change
    async function handleMatterChange (newMatterId: string) {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - handleMatterChange(${JSON.stringify({newMatterId})}) started` : '', MessageType.Info);
            if (!newMatterId) {
                if (props.cardEntry.matterId) {
                    props.onMatterChange({}); 
                }
                return;
            }
    
            // Start fetching data
            props.setIsFetchingData(true); // To block the UI while fetching data 
    
            let matterStyle = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config)?.MatterStyle ?? "0";
            let clientId = props.cardEntry.clientId ?? "";
            let timekeeperId = props.cardEntry?.timekeeperId ?? "";
            let isAllowTempData = false;
            let dateWorked = moment(props.cardEntry.dateWorked).format("YYYY-MM-DDTHH:mm:ss.SSS");
    
            let matterData = await MatterService.validateMatter(props.cardEntry, newMatterId, clientId, dateWorked, timekeeperId, isAllowTempData);
    
            if (matterData.status && matterData.entity) { 
                if (+matterStyle === matterStyles.Normal) { 
                    // We will call the parent component to update the matter
                    props.onMatterChange(matterData.entity);
                } else {
                    if (!clientId && matterData.entity.clientId) {
                        let clientData = await ClientService.validateClient(matterData.entity.clientId, timekeeperId, dateWorked, props.cardEntry, false);
                        if (clientData.status && clientData.entity) {
                            setClientUpdatedDueToMatter(true);
                            props.onClientMatterChange(clientData.entity, matterData.entity);
                        } else {
                            props.onMatterChange(matterData.entity);
                        }
                    } else {
                        props.onMatterChange(matterData.entity);
                    }
                }
            } else {
                props.clearMatterDetails();
                setValidationMessage(matterData.notificationMessage ?? "");
            }
    
            props.setIsFetchingData(false); // To unblock the UI after fetching data
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - handleMatterChange(${JSON.stringify({newMatterId})}) - ${error}` : '', MessageType.Error);
            props.setIsFetchingData(false);
            props.showErrorMessage(CONSTANTS.errorMessage);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterField - handleMatterChange(${JSON.stringify({newMatterId})}) ended` : '', MessageType.Info);
        }
    }

    return (
        <div>
          <Field validationMessage={validationMessage} className={styles.container} required={true}>
                <CustomCombobox title={matterSoftText} entity={CONSTANTS.matter} selectedId={props.cardEntry.matterId ?? ""} selectedName={props.cardEntry.matterName ?? ""} getDropdownItems={getMatter} onSelectionChange={handleMatterChange} cardEntry={props.cardEntry} isDependantSelected={isDependantSelected} validationMessage={validationMessage} isRequired={true}></CustomCombobox>
          </Field>   
        </div>
    )
}