import { CONSTANTS } from "./../constants/constants";
import { MessageType } from "../enums/messageType";
import LoggingService from "./loggingService";

class ApplicationSettingService {
    private static instance: ApplicationSettingService | null = null;
    private static applicationSettings: any;
    private static endpoints: any;
    private static regions: any[];

    private constructor() {
        if (ApplicationSettingService.instance) {
            throw new Error("You can only create one instance!");
        }

        ApplicationSettingService.instance = this;
    }

    static async getInstance(): Promise<ApplicationSettingService> {
        if (!ApplicationSettingService.instance) {
            await ApplicationSettingService.loadApplicationSettings();
            ApplicationSettingService.instance = new ApplicationSettingService();
        }
        return ApplicationSettingService.instance;
    }

    private static async loadApplicationSettings() {
        try {
            const response = await fetch('/appSettings.json', {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            const appSettings = await response.json();

            ApplicationSettingService.applicationSettings = appSettings;
            ApplicationSettingService.endpoints = appSettings[CONSTANTS.endpoints];
            ApplicationSettingService.regions = appSettings[CONSTANTS.regions];
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ApplicationSettingService - loadApplicationSettings - error - ${error}` : '', MessageType.Error);    
            throw new Error(`Error fetching application settings data: ${error}`);
        }
    }

    private getEndpointUrl(region:string, endpointKey: string) {
        try{
            return ApplicationSettingService.endpoints[region][endpointKey];
        }
        catch (error){
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ApplicationSettingService - getEndpointUrl - region ${region} endpoint ${endpointKey} is not available and  error - ${error}` : '', MessageType.Error);    
            throw new Error(`Error fetching getEndpointUrl : ${error}`);
        }
    }

    getAppSettingByKey(key: string) {
        try{
            return ApplicationSettingService.applicationSettings[key];
        }
        catch (error){
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ApplicationSettingService - getAppSettingByKey - key ${key} is missing and  error - ${error}` : '', MessageType.Error);    
            throw new Error(`Error fetching application settings data: ${error}`);
        }
    }

    getAllRegions():{ [key: string]: string }[] {
        try{
            return ApplicationSettingService.regions;
        }
        catch (error){
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ApplicationSettingService - getAllRegions - error - ${error}` : '', MessageType.Error);    
            throw new Error(`Error fetching application settings data: ${error}`);
        }
    }

    getIdentityServerEndpoint(region:string) {
        return this.getEndpointUrl(region, CONSTANTS.identityServerUrl);
    }

    getCarpeApiEndpoint(region: string | null) :Promise<string> { 
        const checkInterval = 500; // Interval in milliseconds to check local storage
        const maxAttempts = 3; // Maximum number of attempts to avoid infinite loop
    
        return new Promise<string>((resolve, reject) => {
            let attempts = 0;
    
            const checkLocalStorage = () => {
                const storedRegion = localStorage.getItem(CONSTANTS.user_region);
                if (storedRegion) {
                    resolve(this.getEndpointUrl(storedRegion, CONSTANTS.carpeApiUrl));
                } else if (attempts < maxAttempts) {
                    attempts++;
                    setTimeout(checkLocalStorage, checkInterval);
                } else {
                    reject(new Error('Region not found in local storage after maximum attempts'));
                }
            };
    
            if (region) {
                resolve(this.getEndpointUrl(region, CONSTANTS.carpeApiUrl));
            } else {
                checkLocalStorage();
            }
        });
    }
}

// Freeze the instance to prevent modifications
const applicationSettingService = Object.freeze(await ApplicationSettingService.getInstance());
export default applicationSettingService;