import moment from "moment";
import { ApiUrlConstant } from "../constants/api-url-constants";
import { CONSTANTS } from "../constants/constants";
import { ValidationDataStatus } from "../enums/validationDataStatus";
import { matterStyles } from "../enums/matterStyle";
import { IIniConfig } from "../models/IIniConfig";
import { IBarrierConfig } from "../models/IBarrierConfig";
import { IClientConfig } from "../models/IClientConfig";
import { IStatusList } from "../models/IStatusList";
import { IUserCalendarsConfig } from "../models/IUserCalendarsConfig";
import { IValidationResponse } from "../models/IValidationResponse";
import { ICardEntry } from "../models/ICardEntry";
import { IMatter } from "../models/IMatter";
import ConfigService from "./configService";
import DataService from "./dataService";
import TimerService from "./timerService";
import ValidationDataService from "./validationDataService";
import SoftTextService from "./softTextService";
import { ILookUpData } from "../models/ILookUpData";
import { IDictionary } from "../models/IDictionary";
import { IServiceResponse } from "../models/IServiceResponse";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";

const MatterService = {

    // Method to get the matters for the dropdown
    async getMatter(clientId: string, startIndex: number, length: number, searchString: string): Promise<ILookUpData[]> {
        let matter: ILookUpData[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatter(${JSON.stringify({clientId, startIndex, length, searchString})}) started` : '', MessageType.Info);
            matter = await DataService.fetchData<ILookUpData[]>(ApiUrlConstant.matter.getMatter + `?start=${startIndex}&len=${length}&clientId=${clientId}&q=${encodeURIComponent(searchString)}`);
            return matter;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatter(${JSON.stringify({clientId, startIndex, length, searchString})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatter(${JSON.stringify({clientId, startIndex, length, searchString})}) - output(${JSON.stringify({matter})}) ended` : '', MessageType.Info);
        }
    },


    // Method to get the matter details for the client (Will be used if the matter style is normal)
    async getMatterDetailsForClient(clientId: string, matterId: string, tkprId: string) : Promise<IMatter[]> {
        let matterDetails: IMatter[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatterDetailsForClient(${JSON.stringify({clientId, matterId, tkprId})}) started` : '', MessageType.Info);
            let configValues: IDictionary<string> = {};
            let matterStyle = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config)?.MatterStyle ?? "0";
            configValues["MatterStyle"] = matterStyle;
            configValues["UseExpenses"] = "0";
            let isAllowTempData = false;

            let url = ApiUrlConstant.matter.getMatterDetailsForClient
                .replace("{0}", matterId)
                .replace("{1}", clientId)
                .replace("{2}", tkprId)
                .replace("{3}", isAllowTempData.toString()); // Convert boolean to string

                matterDetails = await DataService.postData(url, configValues);

            return matterDetails;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatterDetailsForClient(${JSON.stringify({clientId, matterId, tkprId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatterDetailsForClient(${JSON.stringify({clientId, matterId, tkprId})}) - output(${JSON.stringify({matterDetails})}) ended` : '', MessageType.Info);
        }
    },


    // Method to get the matter details by matter id (Will be used if the matter style is unique)
    async getMatterDetailsByMatterId(matterId: string, tkprId: string) : Promise<IMatter[]> { 
        let matterDetails : IMatter[] = []
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatterDetailsByMatterId(${JSON.stringify({matterId, tkprId})}) started` : '', MessageType.Info);
            let configValues: IDictionary<string> = {};
            let matterStyle = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config)?.MatterStyle ?? "0";
            configValues["MatterStyle"] = matterStyle;
            configValues["UseExpenses"] = "0";
            let isAllowTempData = false;

            let url = `${ApiUrlConstant.matter.getMatterDetailsByMatterId}/${matterId}/${tkprId}/${isAllowTempData}`;
            matterDetails = await DataService.postData(url, configValues);
            return matterDetails;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatterDetailsByMatterId(${JSON.stringify({matterId, tkprId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - getMatterDetailsByMatterId(${JSON.stringify({matterId, tkprId})}) - output(${JSON.stringify({matterDetails})}) ended` : '', MessageType.Info);
        }
    },

    // Method to check if the datatype of matter is numeric
    isNumeric() {
        let isNumeric = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - isNumeric() started` : '', MessageType.Info);
            let config = ConfigService.getConfigurationData<IClientConfig>(CONSTANTS.matter);
            let idField = config?.Fields?.Field.find(f => f.Name === CONSTANTS.matterId);
            isNumeric = ValidationDataService.isNumericDatatype(idField?.FieldType ?? "");
            return isNumeric;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - isNumeric() - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - isNumeric() - output(${JSON.stringify({isNumeric})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate the matter and get the matter details if valid, else will send a message
    async validateMatter(entry: ICardEntry, matterId: string, clientId: string, dateWorked: string, timekeeperId: string, isValidated: boolean = false) : Promise<IValidationResponse<IMatter>> {
        let validationResponse: IValidationResponse<IMatter> = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatter(${JSON.stringify({entry, matterId, clientId, dateWorked, timekeeperId, isValidated})}) started` : '', MessageType.Info);
            let matterSoftText = SoftTextService.getSoftTextValue(CONSTANTS.matter)
            // Is the datatype proper?
            let isNumeric = this.isNumeric();
            if (isNumeric && isNaN(+matterId)) {
                return validationResponse = {
                    status: false,
                    notificationMessage: `Invalid ${matterSoftText}.`
                }
            }

            let barrierModes = ConfigService.getConfigurationData<IBarrierConfig>(CONSTANTS.barrierModes);
            if (barrierModes?.Mode === CONSTANTS.advancedBarrier && !isValidated) {
                let walledServiceResponse = await this.validateIfMatterIdsUnaccessibleForUser([clientId], [matterId]);
                if (walledServiceResponse.status) {
                    validationResponse = await this.validateMatterAfterWalledCheck(clientId, matterId, timekeeperId, dateWorked, entry);
                    return validationResponse;
                } else {
                    return validationResponse = {
                        status: false,
                        notificationMessage: barrierModes.NotificationMessage || `Invalid ${matterSoftText}.`
                    }
                } 
            } else {
                validationResponse = await this.validateMatterAfterWalledCheck(clientId, matterId, timekeeperId, dateWorked, entry);
                return validationResponse;
            }
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatter(${JSON.stringify({entry, matterId, clientId, dateWorked, timekeeperId, isValidated})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatter(${JSON.stringify({entry, matterId, clientId, dateWorked, timekeeperId, isValidated})}) - output(${JSON.stringify({validationResponse})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate if the matter is walled for the user
    async validateIfMatterIdsUnaccessibleForUser(clientIds: string[], matterIds: string[]) : Promise<IServiceResponse> {
        let serviceResponse: IServiceResponse = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateIfMatterIdsUnaccessibleForUser(${JSON.stringify({clientIds, matterIds})}) started` : '', MessageType.Info);
            serviceResponse = await DataService.postData<IServiceResponse>(ApiUrlConstant.matter.validateIfMatterIdsUnaccessibleForUser, {clientIds: clientIds, matterIds: matterIds});
            return serviceResponse;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateIfMatterIdsUnaccessibleForUser(${JSON.stringify({clientIds, matterIds})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateIfMatterIdsUnaccessibleForUser(${JSON.stringify({clientIds, matterIds})}) - output(${JSON.stringify({serviceResponse})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate the matter after the walled check
    async validateMatterAfterWalledCheck (clientId: string, matterId: string, timekeeperId: string, dateWorked: string, entry: ICardEntry) { 
        let validationResponse: IValidationResponse<IMatter> = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatterAfterWalledCheck(${JSON.stringify({clientId, matterId, timekeeperId, dateWorked, entry})}) started` : '', MessageType.Info);
            let matterSoftText = SoftTextService.getSoftTextValue(CONSTANTS.matter);
            let matterDetailsObj: IMatter;
            matterDetailsObj = (entry && matterId && entry.matterObj && entry.matterObj.matterId &&
                entry.matterObj.matterId == matterId) ? entry.matterObj
                : await this.validateMatterIdAndGetMatterDetail(matterId, clientId, timekeeperId);
    
            if (matterDetailsObj) {
                let matterConfig = ConfigService.getConfigurationData<IClientConfig>(CONSTANTS.matter);
                validationResponse = await this.validateStatus(matterDetailsObj, matterDetailsObj.status ?? "", matterConfig?.Statuses ?? {}, clientId, matterId, timekeeperId, dateWorked);
                if (validationResponse.status) {
                    validationResponse.entity = matterDetailsObj;
                }
            } else {
                validationResponse = {
                    status: false,
                    notificationMessage: `Invalid ${matterSoftText}.`
                };
            }
    
            return validationResponse;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatterAfterWalledCheck(${JSON.stringify({clientId, matterId, timekeeperId, dateWorked, entry})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatterAfterWalledCheck(${JSON.stringify({clientId, matterId, timekeeperId, dateWorked, entry})}) - output(${JSON.stringify({validationResponse})}) ended` : '', MessageType.Info);
        }
    },


    // Method to get the matter details
    async validateMatterIdAndGetMatterDetail(matterId: string, clientId: string, timekeeperId: string) { 
        let matterDetail: IMatter = {};
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatterIdAndGetMatterDetail(${JSON.stringify({matterId, clientId, timekeeperId})}) started` : '', MessageType.Info);
            let matterStyle = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config)?.MatterStyle ?? "0";
            if (+matterStyle === matterStyles.Normal) { 
                let matterDetails = await this.getMatterDetailsForClient(clientId, matterId, timekeeperId);
                return matterDetail = matterDetails[0];
            } else {
                let matterDetails = await this.getMatterDetailsByMatterId(matterId, timekeeperId);
                return matterDetail = matterDetails[0];
            }
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatterIdAndGetMatterDetail(${JSON.stringify({matterId, clientId, timekeeperId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateMatterIdAndGetMatterDetail(${JSON.stringify({matterId, clientId, timekeeperId})}) - output(${JSON.stringify({matterDetail})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate the status of the matter
    async validateStatus(matter: IMatter, validationDataStatus: string, statusList: IStatusList, clientId: string, matterId: string, timekeeperId: string, dateWorked: string) : Promise<IValidationResponse<IMatter>> {
        let statusObj: IValidationResponse<IMatter> = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateStatus(${JSON.stringify({matter, validationDataStatus, statusList, clientId, matterId, timekeeperId, dateWorked})}) started` : '', MessageType.Info);
            let matterSoftText = SoftTextService.getSoftTextValue(CONSTANTS.matter);

            if (!matterId) {
                statusObj.status = false;
    
                return statusObj;
            }
            
            let barrierModeConfig = ConfigService.getConfigurationData<IBarrierConfig>(CONSTANTS.barrierModes);
    
            if (barrierModeConfig) {
    
                let matterStatus = statusList.Status?.filter((item) => item.Value === validationDataStatus)[0];
    
                if (matterStatus && matterStatus.Meaning) {
    
                    switch (+matterStatus.Meaning) {
    
                        case ValidationDataStatus.Active:
                            if (barrierModeConfig.Mode === CONSTANTS.advancedBarrier) {
    
                                statusObj.status = await this.validateRestrictedMatterForUser(clientId, matterId);
                                if (!statusObj.status) {
                                    statusObj.notificationMessage = `${matterSoftText} ID ${matterId} is restricted.`;
                                }
                            } else {
                                statusObj.status = true;
                            }
                            break;
    
                        case ValidationDataStatus.Inactive:
                            statusObj.status = false;
                            statusObj.notificationMessage = `${matterSoftText} ID ${matterId} has an inactive status.`;
                            break;
    
                        case ValidationDataStatus.RestrictedTimekeeper:
                            if (barrierModeConfig.Mode == CONSTANTS.basicBarrier) {
                                if (!timekeeperId) {
                                    statusObj.status = true;
                                } else {
                                    let validatedTimekeeperdata = await this.validateRestrictedMatterForTimekeeper(timekeeperId, clientId, matterId);
                                    if (validatedTimekeeperdata) {
                                        statusObj.status = true;
                                    } else {
                                        statusObj.status = false;
                                        statusObj.notificationMessage = `${matterSoftText} ID ${matterId} is restricted.`;
                                    }
                                }
                            } else {
                                statusObj.status = true;
                            }
                            break;
    
                        case ValidationDataStatus.TimeOnly:
                            statusObj.status = true;
                            break;
    
                        default:
                            statusObj.status = false;
                    }
                    
                    let calendarConfig = ConfigService.getUserConfigurationData<IUserCalendarsConfig>(CONSTANTS.calendars);
    
                    if (matter.openDate && TimerService.getDateTime(matter.openDate).valueOf() !== TimerService.getDateTime("0001-01-01T00:00:00").valueOf() &&
                        TimerService.getDateTime(matter.openDate) > TimerService.getDateTime(dateWorked)) {
                        //Inavlid due to less than the open timedate
                        statusObj.status = false;
                        statusObj.notificationMessage = `The selected ${matterSoftText} is not valid before ${moment(matter.openDate).format(calendarConfig?.DateFormat.Value)}`;
                    }
    
                    if (matter.closeDate && TimerService.getDateTime(matter.closeDate).valueOf() !== TimerService.getDateTime("0001-01-01T00:00:00").valueOf() &&
                        TimerService.getDateTime(dateWorked) > TimerService.getDateTime(matter.closeDate)) {
                        statusObj.status = false;
                        statusObj.notificationMessage = `The selected ${matterSoftText} is not valid after ${moment(matter.closeDate).format(calendarConfig?.DateFormat.Value)}`;
                    }
                } else {
                    statusObj.status = false;
                    statusObj.notificationMessage = `${matterSoftText} ID ${matterId} has an inactive status.`;
                }
    
                return statusObj;
            }
    
            return statusObj;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateStatus(${JSON.stringify({matter, validationDataStatus, statusList, clientId, matterId, timekeeperId, dateWorked})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateStatus(${JSON.stringify({matter, validationDataStatus, statusList, clientId, matterId, timekeeperId, dateWorked})}) - output(${JSON.stringify({statusObj})}) ended` : '', MessageType.Info);
        }
    },
    

    // Method to check if the matter is restricted for the user
    async validateRestrictedMatterForUser(clientId: string, matterId: string) { 
        let result = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateRestrictedMatterForUser(${JSON.stringify({clientId, matterId})}) started` : '', MessageType.Info);
            result = await DataService.fetchData<boolean>(`${ApiUrlConstant.matter.validateRestrictedMatterForUser}/${clientId || "-1"}/${matterId}/RestrictedForUser`);
            return result;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateRestrictedMatterForUser(${JSON.stringify({clientId, matterId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateRestrictedMatterForUser(${JSON.stringify({clientId, matterId})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    },


    // Method to check if the matter is restricted for the timekeeper
    async validateRestrictedMatterForTimekeeper(timekeeperId: string, clientId: string, matterId: string) { 
        let result = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateRestrictedMatterForTimekeeper(${JSON.stringify({timekeeperId, clientId, matterId})}) started` : '', MessageType.Info);
            result = await DataService.fetchData<boolean>(ApiUrlConstant.matter.validateRestrictedMatterForTimekeeper.replace('{0}', clientId).replace('{1}', matterId).replace('{2}', timekeeperId));
            return result;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateRestrictedMatterForTimekeeper(${JSON.stringify({timekeeperId, clientId, matterId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `MatterService - validateRestrictedMatterForTimekeeper(${JSON.stringify({timekeeperId, clientId, matterId})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    }
}

export default MatterService;