import * as React from "react";
import "../styles/DayViewWidget.css";

import {
    Person24Filled,
    PersonAccounts24Filled,
    CommentEdit24Regular,
    EyeFilled,
    FlagRegular,
    ContentViewGallery24Regular,
    ArrowClockwise24Filled,
    Delete24Regular,
    Timer24Regular,
    Flag24Regular,
} from "@fluentui/react-icons";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Button,
  useArrowNavigationGroup,
  useFocusableGroup,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogContent,
  DialogTitle,
  DialogActions,
  Spinner,
  makeStyles,
  Label,
  shorthands,
  TableColumnDefinition,
  createTableColumn,
  TableColumnSizingOptions,
  useTableFeatures,
  useTableColumnSizing_unstable,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuItem,
  MenuList,
  useTableSort,
  TableColumnId,
} from "@fluentui/react-components";
import CardWidget from "./CardWidget";
import { ICardEntry } from "../models/ICardEntry";
import { CONSTANTS } from "../constants/constants";
import SoftTextService from "../services/softTextService";
import moment from "moment";
import TimekeeperService from "../services/timekeeperService";
import TimeEntryService from "../services/timeEntryService";
import ConfigService from "../services/configService";
import { IIniConfig } from "../models/IIniConfig";
import { ITimeRestrictionsConfig } from "../models/ITimeRestrictionsConfig";
import { IDeleteTimeEntryRequest } from "../models/IDeleteTimeEntryRequest";
import { IDayviewFooter } from "../models/IDayViewFooter";
import TimerService from "../services/timerService";
import { ITimeKeeper } from "../models/ITimeKeeper";
import { NotificationMessage, NotificationMessageHandler } from "../components/NotificationMessage";
import SecurityService from "../services/securityService";
import { TimeOperationsPermissions } from "../enums/timeOperationsPermissions";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { TeamsFxContext } from "../internal/context";

const useStyles = makeStyles({
  popoverSurface: {
    height: '80vh',
    width: '50vw',
    ...shorthands.overflow("auto"),
  },
  spinner: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  status0: {
    color: '#C04C0B', //Incomplete
  },
  status1: {
    color: '#0B77B0', // Ready
  },
  status2: {
    color: '#14814E', // Submitted
  },
  status3: {
    color: '#113f8c', // Approved
  },
  status4: {
    color: '#592a88', // Transferred
  },
  status6: {
    color: '#037FB0', // Transferring
  },
  status7: {
    color: '#D03735', // Reject
  },
  status8: {
    color: '#6A788A', // Missing
  },
  status9: {
    color: '#CD3D90', // Forecast
  },
  status10: {
    color: '#a0522d', // Outstanding
  },
  status11: {
    color: '#ff8c00', // Valdiating
  },
  status12: {
    color: '#ff0000', // NonCompliant
  },
});

export default function DayView() {

  const { themeString } = React.useContext(TeamsFxContext);

  const [isDarkTheme] = React.useState(themeString === "dark" || themeString === "contrast");

  // This is what each row in the table will look like
  type Item = {
    cardEntry: ICardEntry;
  };

  const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });
  const focusableGroupAttr = useFocusableGroup({
    tabBehavior: "limited-trap-focus",
  });

  // This is used to get the styles
  const styles = useStyles();

  // This is used to get the notification ref
  const notificationRef = React.useRef<NotificationMessageHandler>(null);

  // This is used to get the refresh time from the configuration
  const [refreshTime] = React.useState(ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config)?.Refresh ?? "15");

  // Timer state
  const [seconds, setSeconds] = React.useState(+refreshTime * 60);

  // This variable is used to display the spinner when fetching data
  const [loading, setIsLoading] = React.useState(false);

  // Does the user have the permission to delete a time entry
  const [canDelete] = React.useState(SecurityService.hasTimeOperationPermissions(TimeOperationsPermissions.Time_DeleteTime));

  // Variables to hold the footer data
  const [recorded, setRecorded] = React.useState<string>();
  const [missing, setMissing] = React.useState<string>();
  const [target, setTarget] = React.useState<string>();
  const [isRed, setIsRed] = React.useState(false);

  // This is the state that will hold the data for the table
  const [items, setItems] = React.useState<Item[]>([]);

  // This is the state that will hold the template for the columns
  const [columnsDef, setColumnsDef] = React.useState<TableColumnDefinition<Item>[]>([]);

  // This is used to render the size of columns
  const [columnSizingOptions, setColumnSizingOptions] = React.useState<TableColumnSizingOptions>({});

  // This is the state that will hold the columns for the table
  const [columns, setColumns] = React.useState<TableColumnDefinition<Item>[]>(columnsDef);

  // Renders the table
  const { 
    getRows, 
    sort: { getSortDirection, toggleColumnSort, sort },
    columnSizing_unstable, 
    tableRef
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: "client", sortDirection: "ascending" },
      }),
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: true 
      })
    ],
  );

  // This function is used to get the sort properties for the header
  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  // This is used to get the rows for the table
  let rows = sort(getRows());

  // This method will be called when the component is mounted
  React.useEffect (() => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'DayViewWidget - useEffect() started' : '', MessageType.Info);
    fetchData();
    buildDisplayColumns();
    const intervalId = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);

    const handleResize = debounce(() => {
      buildDisplayColumns();
    }, 300);
  
    window.addEventListener('resize', handleResize);
  
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'DayViewWidget - useEffect() ended' : '', MessageType.Info);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId);
    };
  }, []);

  // This is used to fetch data when timer reaches 0
  React.useEffect(() => {
    if (seconds === 0) {
      fetchData();
    }
  }, [seconds]);

  // This is used to update the rows when the items change
  React.useEffect(() => {
      rows = sort(getRows());
  }, [items]);

  // This is used to update the columns when the columns change
  React.useEffect(() => {
    setColumns(columnsDef);
  }, [columnsDef]);

  // Custom debounce function
  function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: NodeJS.Timeout | null;
  
    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout!);
        func(...args);
      };
  
      clearTimeout(timeout!);
      timeout = setTimeout(later, wait);
    };
  };

  // This function is used to build the display columns
  function buildDisplayColumns() {
   try {
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'DayViewWidget - buildDisplayColumns() started' : '', MessageType.Info);
      // These are the columns that we will always display
      let staticColumnsDef: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
          columnId: "client",
          renderHeaderCell: () => <><Person24Filled />{SoftTextService.getSoftTextValue(CONSTANTS.client)}</>,
          renderCell: (item: Item) => <span>{SoftTextService.getClientDisplayName(item.cardEntry)}</span>,
          compare: (a, b) => {
            let clientA = a.cardEntry.clientId ?? "";
            let clientB = b.cardEntry.clientId ?? "";
            return clientA.localeCompare(clientB);
          },
        }),
        createTableColumn<Item>({
          columnId: "matter",
          renderHeaderCell: () => <><PersonAccounts24Filled />{SoftTextService.getSoftTextValue(CONSTANTS.matter)}</>,
          renderCell: (item: Item) => <span>{SoftTextService.getMatterDisplayName(item.cardEntry)}</span>,
          compare: (a, b) => {
            let matterA = a.cardEntry.matterId ?? "";
            let matterB = b.cardEntry.matterId ?? "";
            return matterA.localeCompare(matterB);
          }
        }),
        createTableColumn<Item>({
          columnId: "narrative",
          renderHeaderCell: () => <><CommentEdit24Regular />Narrative</>,
          renderCell: (item: Item) => <span>{item.cardEntry.narrative}</span>
        }),
        createTableColumn<Item>({
          columnId: "time",
          renderHeaderCell: () => <><Timer24Regular />Time</>,
          renderCell: (item: Item) => <span>{item.cardEntry.roundTimeDisplayValue}</span>,
          compare: (a, b) => {
            let timeA = a.cardEntry.roundTimeDisplayValue ?? "";
            let timeB = b.cardEntry.roundTimeDisplayValue ?? "";
            return timeA.localeCompare(timeB);
          }
        }),
        createTableColumn<Item>({
          columnId: "status",
          renderHeaderCell: () => <><Flag24Regular />Status</>,
          renderCell: (item: Item) => <span className={styles[`status${item.cardEntry.status}` as keyof typeof styles]}>{SoftTextService.getStatus(item.cardEntry.status)}</span>
        }),
        createTableColumn<Item>({
          columnId: "action",
          renderHeaderCell: () => <>Action</>,
        }),
      ];

      // Take only the first 3 columns from the display columns
      let udcDisplayColumns = ConfigService.getUdcDisplayColumns().slice(0, 3).map(column => column.toLowerCase());

      // These are the udc columns that are configured to be displayed
      let udcColumnsDef = udcDisplayColumns
        .map(columnId => 
          createTableColumn<Item>({
            columnId,
            renderHeaderCell: () => renderUdcHeader(columnId),
            renderCell: (item: Item) => renderUdcCell(item, columnId),
          })
      );

      // Take the first two columns from staticColumnsDef
      let combinedColumnsDef = staticColumnsDef.slice(0, 2);

      // Add the udcColumnsDef after the first two columns
      combinedColumnsDef.push(...udcColumnsDef);

      // Add the remaining columns from staticColumnsDef
      combinedColumnsDef.push(...staticColumnsDef.slice(2));

      setColumnsDef(combinedColumnsDef);
      
      // Set the column sizing options for the fixed columns
      let staticColumnSizingOptions: TableColumnSizingOptions = {
        client: {
          minWidth: 100,
        },
        matter: {
          minWidth: 100,
        },
        narrative: {
          minWidth: 100,
        },
        time: {
          minWidth: 100,
        },
        status: {
          minWidth: 100,
        },
        action: {
          minWidth: 50,
        },
      };
      
      // Get the screen width
      let screenWidth = window.innerWidth * 0.98; // 98% of screen width because some are used for header and footer and side menu etc
      
      // Set the default width for each column
      for (let columnId in staticColumnSizingOptions) {
        let defaultWidth;

        // Define the default width based on the column ID
        switch (columnId) {
          case 'narrative':
            defaultWidth = screenWidth * getNarrativeWidth(udcDisplayColumns.length);
            break;
          case 'action':
            defaultWidth = screenWidth * 0.02; // 2% of screen width
            break;
          case 'status':
            defaultWidth = screenWidth * 0.04; // 4% of screen width
            break;
          case 'time':
            defaultWidth = screenWidth * 0.04; // 4% of screen width
            break;
          default:
            defaultWidth = screenWidth * getValidationColumnWidth(udcDisplayColumns.length)
        }

        // Set the default width for the column
        staticColumnSizingOptions[columnId].defaultWidth = defaultWidth;
      }

      
      let newColumnSizingOptions = udcDisplayColumns.reduce((acc, columnId) => {
        acc[columnId] = { minWidth: 100, defaultWidth: screenWidth * getValidationColumnWidth(udcDisplayColumns.length) };
        return acc;
      }, {...staticColumnSizingOptions});
      
      setColumnSizingOptions(newColumnSizingOptions);
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - buildDisplayColumns() - ${error}` : '', MessageType.Error);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'DayViewWidget - buildDisplayColumns() ended' : '', MessageType.Info);
    }
  }

  // Returns the width of the validation column based on the number of UDC columns
  function getValidationColumnWidth(totalUdcColumns: number) { 
    let width = 0;
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getValidationColumnWidth(${JSON.stringify({totalUdcColumns})}) started` : '', MessageType.Info);
      switch (totalUdcColumns) { 
        case 0 :
          return width = 0.15;
        case 1 :
          return width = 0.15;
        case 2 :
          return width = 0.10;
        case 3 :
          return width = 0.08;
      }
  
      return width = 0.15;
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getValidationColumnWidth(${JSON.stringify({totalUdcColumns})}) - ${error}` : '', MessageType.Error);
      return width = 0.15;
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getValidationColumnWidth(${JSON.stringify({totalUdcColumns})}) - output(${width}) ended` : '', MessageType.Info);
    }
  }

  // Returns the width of the narrative column based on the number of UDC columns
  function getNarrativeWidth(totalUdcColumns: number) { 
    let width = 0;
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getNarrativeWidth(${JSON.stringify({totalUdcColumns})}) started` : '', MessageType.Info);
      switch (totalUdcColumns) { 
        case 0 :
          return width = 0.6;
        case 1 :
          return width = 0.45;
        case 2 :
        case 3 :
          return width = 0.5;
      }
  
      return width = 0.5;
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getNarrativeWidth(${JSON.stringify({totalUdcColumns})}) - ${error}` : '', MessageType.Error);
      return width = 0.5;
    } finally{
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getNarrativeWidth(${JSON.stringify({totalUdcColumns})}) - output(${width}) ended` : '', MessageType.Info);
    }
  }
  
  // This function is used to render the UDC cell
  function renderUdcCell(item: Item, columnId: string) {
    if (columnId.startsWith('code')) {
      let formattedColumnId = 'Code' + columnId.slice(4);
      let codeIdDescr = item.cardEntry.userCodes?.[formattedColumnId];
      return <span>{codeIdDescr ? codeIdDescr : ""}</span>;
    }
  }

  // This function is used to render the UDC header
  function renderUdcHeader(columnId: string) {
    let udcFields = ConfigService.getUDCFields();
    if (columnId.startsWith('code')) {
      let udcField = udcFields.find(field => field.Name.toLowerCase() === columnId);
      if (udcField) {
        return <><ContentViewGallery24Regular />{udcField.Label}</>;
      }
    } 
  }

  // This function is used to fetch the data for the day
  async function fetchData() {
    try {      
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'DayViewWidget - fetchData() started' : '', MessageType.Info);
      let entries = await getTimeEntries() ?? [];
      let timekeeperId = (await TimekeeperService.getDefaultTimekeeper()).timekeeperId;
      let timekeeperData = await TimekeeperService.getTimekeeperData(timekeeperId, false, false);
      updateFooter(timekeeperData, entries);
      setSeconds(15 * 60); // Reset timer
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - fetchData() - ${error}` : '', MessageType.Error);
      notificationRef.current?.showNotification(CONSTANTS.errorMessage, true);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'DayViewWidget - fetchData() ended' : '', MessageType.Info);
    }
  }

  // This function is used to update the footer data
  async function updateFooter(timekeeperData : ITimeKeeper, entries : ICardEntry[]) { 
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - updateFooter(${JSON.stringify({timekeeperData, entries})}) started` : '', MessageType.Info);
      let footer: IDayviewFooter = { missingTime: 0, recordedTime: 0, targetTime: 0 };
      footer = await TimeEntryService.buildFooter(timekeeperData, timekeeperData.calender ?? "", moment().format("YYYY-MM-DD"), entries);
      setIsRed(footer.recordedTime < footer.targetTime);
      setRecorded(TimerService.convertSecondsToHoursAndMinutes(footer.recordedTime));
      setMissing(TimerService.convertSecondsToHoursAndMinutes(footer.missingTime > 0 ? footer.missingTime : 0));
      setTarget(TimerService.convertSecondsToHoursAndMinutes(footer.targetTime));
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - updateFooter(${JSON.stringify({timekeeperData, entries})}) - ${error}` : '', MessageType.Error);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - updateFooter(${JSON.stringify({timekeeperData, entries})}) ended` : '', MessageType.Info);
    }
  }

  // This function is used to get the time entries for the day
  async function getTimeEntries() {
    let cardEntries: ICardEntry[] = [];
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'DayViewWidget - getTimeEntries() started' : '', MessageType.Info);
      setIsLoading(true);
      let startDate = moment().format("YYYY-MM-DD");
      let endDate = "";
      let timekeeperId = (await TimekeeperService.getDefaultTimekeeper()).timekeeperId;
      let timeFormat = "1"; // hh:mm
      let defaultRndInc = ConfigService.getConfigurationData<ITimeRestrictionsConfig>(CONSTANTS.timeRestrictions)?.DefaultRndInc ?? "0";
      let timeEntries = await TimeEntryService.getTimeEntriesForDayView(startDate, timekeeperId, timeFormat, defaultRndInc, endDate, false);
      cardEntries = timeEntries.map((timeEntry) => TimeEntryService.mapTimeEntryToCardEntry(timeEntry));
      let cardEntriesToItems : Item[] = cardEntries.map((cardEntry) => {
        return {
          cardEntry: cardEntry,
        };
      });
      setItems(cardEntriesToItems);
      setIsLoading(false);
      return cardEntries;
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getTimeEntries() - ${error}` : '', MessageType.Error);
      setIsLoading(false);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - getTimeEntries() - output(${JSON.stringify({cardEntries})}) ended` : '', MessageType.Info);
    }
  }

  // This function is used to delete an entry
  async function deleteEntry(timeId?: string) {
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - deleteEntry(${JSON.stringify({timeId})}) started` : '', MessageType.Info);
      if (!timeId) return;

      setIsLoading(true);
      let request : IDeleteTimeEntryRequest[];
      request = [{id: timeId, isForecast: false}];
      let response = await TimeEntryService.deleteEntry(request);
      if (response.status) {
        let entries = items.filter((item) => item.cardEntry.timeId !== timeId)
        setItems(entries);
        let timekeeperId = (await TimekeeperService.getDefaultTimekeeper()).timekeeperId;
        let timekeeperData = await TimekeeperService.getTimekeeperData(timekeeperId, false, false);
        updateFooter(timekeeperData, entries.map((item) => item.cardEntry));
        notificationRef.current?.showNotification("Time Entry deleted successfully.");
      }
      setIsLoading(false); 
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - deleteEntry(${JSON.stringify({timeId})}) - ${error}` : '', MessageType.Error);
      setIsLoading(false);
      notificationRef.current?.showNotification(CONSTANTS.errorMessage, true);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `DayViewWidget - deleteEntry(${JSON.stringify({timeId})}) ended` : '', MessageType.Info);
    }
  }
  
  return (
    <div>
      <div>
        <NotificationMessage ref={notificationRef}/>
        {loading ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : rows.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%' }}>
            <Label size="large">No Data</Label>
          </div>
        ) : (
          <div style={{ overflowX: "auto", paddingBottom: "3rem" }}>
            
            <div>
              <Table
              sortable aria-label="Table with sort"
              ref={tableRef}
              {...columnSizing_unstable.getTableProps()}
              size="small"
              {...keyboardNavAttr}
              role="grid"
              noNativeElements={true}
            >
            <TableHeader>
              <TableRow className="tableHeader">
                {columns.map((column) => (
                  <Menu openOnContext key={column.columnId}>
                    <MenuTrigger>
                    <TableHeaderCell
                      key={column.columnId}
                      {...columnSizing_unstable.getTableHeaderCellProps(column.columnId)}
                      {...(['client', 'matter', 'time'].includes(column.columnId.toString()) ? headerSortProps(column.columnId) : {})}
                    >
                      {column.renderHeaderCell()}
                  </TableHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={columnSizing_unstable.enableKeyboardMode(
                            column.columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {rows.map(({ item }) => (
                <TableRow key={item.cardEntry.timeId}>
                  {columns.map((column) => {
                    if (column.columnId === 'action') {
                      return (
                      <TableCell tabIndex={0} {...focusableGroupAttr}>
                        <TableCellLayout>
                          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '5%', paddingTop: '5%'}}>
                              <Dialog>
                                <DialogTrigger disableButtonEnhancement={true}>
                                  <Button icon={<EyeFilled />} aria-label="Edit" title="View Entry"/>
                                </DialogTrigger>
                                <DialogSurface>
                                  <DialogBody>
                                    <DialogContent>
                                      <CardWidget cardEntry={item.cardEntry}/>
                                    </DialogContent>
                                  </DialogBody>
                                </DialogSurface>
                              </Dialog>

                              <Dialog modalType="alert">
                                <DialogTrigger>
                                  <Button disabled={!canDelete} icon={<Delete24Regular />} aria-label="Delete" style={{ color: 'red', marginLeft: '5px' }} title="Delete Entry"/>
                                </DialogTrigger>
                                <DialogSurface>
                                  <DialogBody>
                                    <DialogTitle>Delete entry</DialogTitle>
                                    <DialogContent>
                                      Are you sure you want to delete the selected entry?
                                    </DialogContent>
                                    <DialogActions>
                                      <DialogTrigger>
                                        <Button appearance="secondary">Cancel</Button>
                                      </DialogTrigger>
                                      <DialogTrigger>
                                        <Button appearance="primary" onClick={() => deleteEntry(item.cardEntry.timeId)}>Delete</Button>
                                      </DialogTrigger>
                                    </DialogActions>
                                  </DialogBody>
                                </DialogSurface>
                              </Dialog>
                            </div>
                          
                        </TableCellLayout>
                    </TableCell> );
                  }

                  return (
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps(column.columnId)}
                      >
                        <TableCellLayout truncate>
                          {column.renderCell(item)}
                        </TableCellLayout>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
            </Table>
          </div>
        </div>
        )}
        <div style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '100%',
          color: isDarkTheme ? 'white' : 'black',
          backgroundColor: isDarkTheme ? '#1e1e1e' : 'white',
          boxShadow: isDarkTheme ? '0 -4px 25px #52525280' : '0 -4px 25px #32323280',
          height: '8%',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 3%'  // Add padding to the container
        }}>
          <span style={{flexGrow: 1, textAlign: 'left', alignContent: 'center'}}>
            Recorded : <span className={isRed ? styles.status7 : styles.status2}>{recorded}</span> | Missing : {missing} | Target : {target}
          </span>
          <span style={{flexShrink: 0, textAlign: 'right', alignContent: 'center'}}>
            <Button icon={<ArrowClockwise24Filled />} onClick={fetchData} appearance="primary" title="Refresh timesheet">
              <span>{TimerService.secondsToMMSS(seconds)}</span> 
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
};