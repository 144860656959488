import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Spinner, makeStyles, shorthands } from "@fluentui/react-components";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";

const useStyles = makeStyles({
  container: {
    "> div": { ...shorthands.padding("200px") },
  },
});

export default function LoginCallback() {
  const auth = useAuth();
  const styles = useStyles();
  const navigate = useNavigate();

  LoggingService.log(LoggingService.isLoggingEnabled() ? `LoginCallback - Sign-in callback event triggered.` : '', MessageType.Info);

  useEffect(() => {
    auth.loginCallback().then(() => {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `LoginCallback - Sign-in callback event successful.` : '', MessageType.Info);
      navigate("/tab");
    }).catch(function(err) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `LoginCallback - Sign-in callback event failed - ${err}` : '', MessageType.Error);
    });
  }, []);

  return (
    <div className={styles.container}> 
      <Spinner size="large" labelPosition="below" label="Processing sign-in callback..." /> 
    </div>
  );
}
