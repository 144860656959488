import { MessageType } from "../enums/messageType";

// Array to store logs
let logs: { timeStamp: string, message: string, type: string }[] = [];

// Variable to indicate if logging is enabled
let logsEnabled: boolean = false;

// Logs will be displayed in console in development mode
let isDevMode: boolean = false;

// Variable to store the interval for clearing logs
let logClearInterval: NodeJS.Timeout | null = null;

const LoggingService = {

    // Getter for isLoggingEnabled
    isLoggingEnabled() {
        return logsEnabled || isDevMode;
    },

    // Setter for isLoggingEnabled
    setIsLoggingEnabled(value: boolean) {
        logsEnabled = value;

        if (logsEnabled) {
            logClearInterval = setInterval(() => {
                this.clearLogs();
            }, 120000);
        } else {
            // If logging is disabled, clear the interval
            if (logClearInterval) {
                clearInterval(logClearInterval);
                logClearInterval = null;
            }
        }
    },

    // Function to log messages
    log(message: string, type: MessageType) {
        if (logsEnabled) {
            logs.push({ timeStamp: new Date().toLocaleString(), message: message, type });
        } 
        
        if (isDevMode) {
            if (type === MessageType.Error) {
                console.error(message);
            } else {
                console.log(message);
            }
        }
    },

    // Function to download logs
    downloadLogs() {
        let element = document.createElement('a');
        let file = new Blob([logs.map(log => `${log.timeStamp} : [${log.type}] : ${log.message}`).join('\n')], { type: 'text/plain' });
        let url = URL.createObjectURL(file);
        element.href = url;
        let fileName = `TeamsAppLog${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}${String(new Date().getDate()).padStart(2, '0')}.txt`;
        element.download = fileName;
        document.body.appendChild(element);
        element.click();

        // Clean up
        URL.revokeObjectURL(url);
        document.body.removeChild(element);

        // Clear logs after download
        this.clearLogs();
    },

    // Function to clear logs
    clearLogs() {
        logs = [];
    }
}

export default LoggingService;