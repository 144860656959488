export class CONSTANTS {

    static dropdownLength: number = 25;
    static dropdownWaitTimeBeforeCall: number = 3000;

    static client: string = "Client";
    static matter: string = "Matter";
    static matterId: string = "MATTERID";
    static clientId: string = "CLIENTID";
    static timekeeper: string = "Timekeeper";


    static codeId: string = "CODEID";
    static config: string = "Config";
    static miscellaneous: string = "Miscellaneous";
    static barrierModes: string = "BarrierModes";
    static advancedBarrier: string = "Advanced";
    static basicBarrier: string = "Basic";
    static calendars: string = "Calendars";
    static minDateValue: string = "0001-01-01T00:00:00";
    static spellCheck: string = "SpellCheck";
    static userDictionary: string = "UserDictionary";
    static autoReplace : string = "a";
    static timeRestrictions: string = "TimeRestrictions";
    static confirmation: string = "Confirmation";
    static teamsApp: string = "TeamsApp";

    static regionalParameter: string = "RegionalParameter";
    static defaultValues: string = "DefaultValues";

    static sunday: string = "Sunday";
    static monday: string = "Monday";
    static tuesday: string = "Tuesday";
    static wednesday: string ="Wednesday";
    static thursday: string = "Thursday";
    static friday: string = "Friday";
    static saturday: string = "Saturday";

    static dateValidation: string = "DateValidation";
    static closeValdiation : string = "Close";
    static narrativeValidation: string = "Narrative";

    static routeDateParamFormat: string = "YYYY-MM-DD";

    static create: string = "create";
    static found: string = "found";
    static today: string = "today";
    static settings: string = "settings";

    static errorMessage: string = "Error occurred while processing the request. Please connect with support team.";
    static tokenExpiredMessage: string = "Token has expired. Please wait while you are redirected to the login screen.";
    
    static userId: string = "userId";

    static superAdmin: string = "superAdmin";

    static user_region: string = "user-region";
    static endpoints: string = "ENDPOINTS";
    static regions: string = "REGIONS";
    static app_client_id: string = "CD_TEAMS_APP_CLIENT_ID";
    static app_url: string = "APP_URL";
    static app_id: string = "APP_ID";
    static app_internal_id: string = "APP_INTERNAL_ID";
    static identityServerUrl: string = "IDS_URL";
    static carpeApiUrl: string = "CARPE_API_URL";
}