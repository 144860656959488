export enum SaveEntrySteps {
  ValidateRequiredData = 0,
  ValidateDuplicateEntry = 1,
  ValidateDateWorked = 2,
  ValidateWeekendOrHoliday = 3,
  ValidateCloseRestrictions = 4,
  SaveEntry = 5,
  UpdateEntry = 6,
  AddFavourites = 7,
  GetThirdPartyValidation = 8,
  SaveIncompleteEntry = 9,
  ValidateDateIncurred = 10,
  ValidateEntryChanged = 11
}