import { CONSTANTS } from "../constants/constants";
import ApplicationSettingService from "../services/applicationSettingService";

const DataService = {
    async baseUrl(): Promise<string> {
        try {
            return await ApplicationSettingService.getCarpeApiEndpoint(localStorage.getItem(CONSTANTS.user_region));
        } catch (error:any) {
            console.error('Error fetching API endpoint:', error.message);
            throw error;
        }
    },

    // GET API call base
    async fetchData<T>(url: string): Promise<T> {
        let accessToken: string = sessionStorage.getItem("token")! ;
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const apiUrl = await this.baseUrl() + url;
        const response = await fetch(apiUrl, { headers });
        if (!response.ok || response.status === 204) {          
            throw new Error(response.statusText);
        }
  
        const data = await response.json();
        return data as T;
    },

    // POST API call base
    async postData<T>(url: string, obj: any): Promise<T> {
        let accessToken: string = sessionStorage.getItem("token")! ;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }

        const apiUrl = await this.baseUrl() + url;
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: headers
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const result = await response.json();
        return result as T;
    }
}

export default DataService;