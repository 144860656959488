import { ApiUrlConstant } from "../constants/api-url-constants";
import { CONSTANTS } from "../constants/constants";
import { MessageType } from "../enums/messageType";
import { ICommonLookup } from "../models/ICommonLookup";
import { IDictionary } from "../models/IDictionary";
import { IUdcConfig } from "../models/IUdcConfig";
import { IUdcFieldModel } from "../models/IUdcFieldModel";
import DataService from "./dataService";
import LoggingService from "./loggingService";

// This will hold the data from CDINICONFIG
let config: ICommonLookup[];

// This will hold the data from CDUSERINI or CDUSERINIDEFAULT
let userConfig: ICommonLookup[];

const ConfigService = {

    // This method will return the UDC fields
    getUDCFields(): IUdcFieldModel[] {
        let fields: IUdcFieldModel[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUDCFields() started` : '', MessageType.Info);
            let data = this.getConfigurationData<IUdcConfig>(CONSTANTS.teamsApp);
            fields = (data && data.UDCs && data.UDCs.UDC && (Array.isArray(data.UDCs.UDC) ? data.UDCs.UDC : [data.UDCs.UDC])) ?? [];
            return fields
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUDCFields() - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUDCFields() - output(${JSON.stringify({fields})}) ended` : '', MessageType.Info);
        }
    },

    // This method will return the UDC fields
    getUdcDisplayColumns(): string[] {
        let columns: string[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUdcDisplayColumns() started` : '', MessageType.Info);
            let data = this.getConfigurationData<IUdcConfig>(CONSTANTS.teamsApp);
            let columnObject = (data && data.TodayTabColumns && data.TodayTabColumns.Column && (Array.isArray(data.TodayTabColumns.Column) ? data.TodayTabColumns.Column : [data.TodayTabColumns.Column])) ?? [];
            columns = columnObject.map(x => x.Name);
            return columns;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUdcDisplayColumns() - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUdcDisplayColumns() - output(${JSON.stringify({columns})}) ended` : '', MessageType.Info);
        }
    },

    // This method will load the configuration data from CDINICONFIG
    async loadConfigurationData() {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - loadConfigurationData() started` : '', MessageType.Info);
            let data = await DataService.fetchData<IDictionary<string>>(ApiUrlConstant.settings.getSettings);

            config = [];
            config = Object.keys(data).map(x => {
                return { Key: x, Value: data[x] };
            });
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - loadConfigurationData() - ${error}` : '', MessageType.Error);
            throw error;
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - loadConfigurationData() ended` : '', MessageType.Info);
        }
    },

    // This method will load the user configuration data from CDUSERINI or CDUSERINIDEFAULT
    async loadUserConfigurationData() { 
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - loadUserConfigurationData() started` : '', MessageType.Info);
            let data = await DataService.fetchData<IDictionary<string>>(ApiUrlConstant.settings.getUserDefaultConfigurationDetails);
            userConfig = [];
            userConfig = Object.keys(data).map(x => {
                return { Key: x, Value: data[x] };
            });
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - loadUserConfigurationData() - ${error}` : '', MessageType.Error);
            throw error;
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - loadUserConfigurationData() ended` : '', MessageType.Info);
        }
    },

    // This method will return the user configuration data for a given key
    getUserConfigurationData<T>(key: string): T | null { 
        LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUserConfigurationData(${JSON.stringify({key})}) started` : '', MessageType.Info);
        let data: T | null = null;
        try {
            const item = userConfig.find(x => x.Key === key);
            
            if (item) {
                if (typeof item.Value === 'string') {
                    try {
                        data = JSON.parse(item.Value) as T;
                    } catch (error) {
                        LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - Error parsing JSON - ${error}` : '', MessageType.Error);
                    }
                } else {
                    // Assuming Value is already an object
                    data = item.Value as T;
                }
            }
            return data;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUserConfigurationData(${JSON.stringify({key})}) - ${error}` : '', MessageType.Error);
            return data;
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getUserConfigurationData(${JSON.stringify({key})}) - output(${JSON.stringify({data})}) ended` : '', MessageType.Info);
        }
    },

    // This method will save the user configuration data locally
    saveUserConfigurationData<T>(key: string, value: T) {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - saveUserConfigurationData(${JSON.stringify({key, value})}) started` : '', MessageType.Info);
            const item = userConfig.find(x => x.Key === key);
            if (item) {
                // If the item already exists, update its value
                item.Value = JSON.stringify(value);
            } else {
                // If the item doesn't exist, add a new item
                userConfig.push({ Key: key, Value: JSON.stringify(value) });
            }
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - saveUserConfigurationData(${JSON.stringify({key, value})}) - ${error}` : '', MessageType.Error);
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - saveUserConfigurationData(${JSON.stringify({key, value})}) ended` : '', MessageType.Info);
        }
    },

    // This method will return the configuration data for a given key
    getConfigurationData<T>(key: string): T | null { 
        LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getConfigurationData(${JSON.stringify({key})}) started` : '', MessageType.Info);
        let data: T | null = null;
        
        try {
            const item = config.find(x => x.Key === key);
        
            if (item) {
                if (typeof item.Value === 'string') {
                    try {
                        data = JSON.parse(item.Value) as T;
                    } catch (error) {
                        LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - Error parsing JSON - ${error}` : '', MessageType.Error);
                    }
                } else {
                    // Assuming Value is already an object
                    data = item.Value as T;
                }
            }
            return data;   
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getConfigurationData(${JSON.stringify({key})}) - ${error}` : '', MessageType.Error);
            return data;
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ConfigService - getConfigurationData(${JSON.stringify({key})}) - output(${JSON.stringify({data})}) ended` : '', MessageType.Info);
        }
    }
    
} 

export default ConfigService;