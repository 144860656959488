import { UserManager, UserManagerSettings, Log } from "oidc-client-ts";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";
import ApplicationSettingService from "./applicationSettingService";
import { CONSTANTS } from "../constants/constants";

let instance: any;
let userManager: UserManager;
let userMangerSettings: UserManagerSettings;

class AuthService {
    
    constructor() {
        if (instance) {
            throw new Error("You can only create one instance!");
        }

        Log.setLogger(console);
        Log.setLevel(Log.INFO);

        userMangerSettings = this.buildUserManagerSettings();

        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthService - constructor - ${JSON.stringify(userMangerSettings)}` : '', MessageType.Info);
        userManager = new UserManager(userMangerSettings);

        instance = this;
    }

    private buildUserManagerSettings(): UserManagerSettings {
        const appClientId = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_client_id);
        const appUrl = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_url);
    
        return {
            authority: localStorage.getItem(CONSTANTS.user_region) ?? '',
            client_id: appClientId,
            scope: "CarpeWebApi openid",
            response_type: "code",
            response_mode: "query",
            redirect_uri: appUrl + "/index.html#/login-callback",
            post_logout_redirect_uri: appUrl + "/index.html#/logout",
            popup_redirect_uri: appUrl + "/index.html#/popup-callback",
            popup_post_logout_redirect_uri: appUrl,
            silent_redirect_uri: appUrl + "/index.html#/silent-callback",
            automaticSilentRenew: false,
            validateSubOnSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: false,
            monitorAnonymousSession: true,
            revokeTokensOnSignout: true,
        };
    }

    getInstance() {
        return this;
    }

    userManager(){
        return userManager;
    }

    userMangerSettings(){
        return userMangerSettings;
    }

    updateUserManagerSettings(authorityUrl:string) {
        userMangerSettings = this.buildUserManagerSettings();
        userMangerSettings.authority = authorityUrl;
        userManager = new UserManager(userMangerSettings);
        console.log(`AuthService - updateUserManagerSettings - ${JSON.stringify(userMangerSettings)}`);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthService - updateUserManagerSettings - ${JSON.stringify(this.userMangerSettings)}` : '', MessageType.Info);
    }
}
 
const authenticationService = Object.freeze(new AuthService());
export default authenticationService;
