import { ApiUrlConstant } from "../constants/api-url-constants";
import DataService from "./dataService"
import { ICardEntry } from "../models/ICardEntry";
import ConfigService from "./configService";
import { IUserDefinedCodeConfig } from "../models/IUserDefinedCodeConfig";
import { CONSTANTS } from "../constants/constants";
import ValidationDataService from "./validationDataService";
import { IValidationResponse } from "../models/IValidationResponse";
import { ValidationDataStatus } from "../enums/validationDataStatus";
import TimerService from "./timerService";
import moment from "moment";
import { IUserCodeResponse } from "../models/IUserCodeResponse";
import { IUserCode } from "../models/IUserCode";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";

const UserCodeService = {

    // Method to get the user codes for the dropdown
    async getUserCodes(userCodeName: string, codeSet: string, searchString: string, start: number, length: number, parent: string) : Promise<IUserCodeResponse>{
        let response: IUserCodeResponse = {};
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - getUserCodes(${JSON.stringify({userCodeName, codeSet, searchString, start, length, parent})}) started` : '', MessageType.Info);
            let url = ApiUrlConstant.userCode.getUDCdata
            .replace("{0}", userCodeName)
            .replace("{1}", codeSet)
            .replace("{2}", searchString)
            .replace("{3}", start.toString())
            .replace("{4}", length.toString())
            .replace("{5}", parent);

            response = await DataService.fetchData<IUserCodeResponse>(url);
            return response;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - getUserCodes(${JSON.stringify({userCodeName, codeSet, searchString, start, length, parent})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - getUserCodes(${JSON.stringify({userCodeName, codeSet, searchString, start, length, parent})}) - output(${JSON.stringify({response})}) ended` : '', MessageType.Info);
        }
    },

    // Method to get the user code object
    async getUserCodeObject(codeX: string, codeId: string, codeSet: string, ) : Promise<IUserCode | null> { 
        let userCodeObj: IUserCode | null = null;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - getUserCodeObject(${JSON.stringify({codeX, codeId, codeSet})}) started` : '', MessageType.Info);
            let url = ApiUrlConstant.userCode.getUDCObject
            .replace("{0}", codeX)
            .replace("{1}", codeId)
            .replace("{2}", codeSet);

            userCodeObj = await DataService.fetchData<IUserCode>(url);
            
            return userCodeObj;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - getUserCodeObject(${JSON.stringify({codeX, codeId, codeSet})}) - ${error}` : '', MessageType.Error);
            return null;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - getUserCodeObject(${JSON.stringify({codeX, codeId, codeSet})}) - output(${JSON.stringify({userCodeObj})}) ended` : '', MessageType.Info);
        }
    },

    // Method to check if the datatype of matter is numeric
    isNumeric(codeX: string) {
        let isNumeric: boolean = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - isNumeric(${JSON.stringify({codeX})}) started` : '', MessageType.Info);
            let config = ConfigService.getConfigurationData<IUserDefinedCodeConfig>(codeX);
            let idField = config?.Fields?.Field.find(f => f.Name === CONSTANTS.codeId);
            return isNumeric = ValidationDataService.isNumericDatatype(idField?.FieldType ?? "");
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - isNumeric(${JSON.stringify({codeX})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - isNumeric(${JSON.stringify({codeX})}) - output(${JSON.stringify({isNumeric})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate the user defined code and return the response
    async validateUdc(userCodeName: string, codeX: string, codeId: string, codeSet: string, entry: ICardEntry) {
        let response: IValidationResponse<IUserCode> = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - validateUdc(${JSON.stringify({userCodeName, codeX, codeId, codeSet, entry})}) started` : '', MessageType.Info);
            let codeObject: IUserCode | null;
            let configData = ConfigService.getConfigurationData<IUserDefinedCodeConfig>(codeX);
    
            let isNumeric = this.isNumeric(codeX);
            if (isNumeric && isNaN(+codeId)) {
                response.notificationMessage = `Invalid ${userCodeName}.`
            } else {
                let oldObj = <IUserCode> entry?.userCodesObj?.[codeX];
                codeObject = (oldObj && oldObj.codeID == codeId)
                ? oldObj : await this.getUserCodeObject(codeX, codeId, codeSet);
    
                if (!codeObject || codeObject.downlinkCode) {
                    response.notificationMessage = `Invalid ${userCodeName}.`
                } else {
                    let statusObj = configData?.Statuses?.Status?.filter(x => x.Value == codeObject?.status);
                    if (!statusObj || statusObj.length == 0) {
                        response.notificationMessage = `Invalid ${userCodeName}.`
                    } else if (statusObj && statusObj[0] && statusObj[0].Meaning && +statusObj[0]?.Meaning == ValidationDataStatus.Inactive) {
                        response.notificationMessage = `${userCodeName} ${codeId} has an inactive status.`
                    } else if (codeObject.openDate && TimerService.getDateTime(codeObject.openDate).valueOf() !==
                    TimerService.getDateTime(CONSTANTS.minDateValue).valueOf() &&
                    TimerService.getDateTime(codeObject.openDate) >
                    TimerService.getDateTime(entry.dateWorked)) {
                        response.notificationMessage = `The selected ${userCodeName} is not valid before ${moment(codeObject.openDate).toDate().toLocaleDateString()}.`
                    } else if (codeObject.closeDate && TimerService.getDateTime(codeObject.closeDate).valueOf() !==
                    TimerService.getDateTime(CONSTANTS.minDateValue).valueOf() &&
                    TimerService.getDateTime(entry.dateWorked) >
                    TimerService.getDateTime(codeObject.closeDate)) {
                        response.notificationMessage = `The selected ${userCodeName} is not valid after ${moment(codeObject.closeDate).toDate().toLocaleDateString()}.`
                    } else {
                        response.status = true;
                        response.entity = codeObject;
                    }
                }
            }
    
            return response;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - validateUdc(${JSON.stringify({userCodeName, codeX, codeId, codeSet, entry})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `UserCodeService - validateUdc(${JSON.stringify({userCodeName, codeX, codeId, codeSet, entry})}) - output(${JSON.stringify({response})}) ended` : '', MessageType.Info);
        }
    }
}

export default UserCodeService;