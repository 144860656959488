import { ComboboxProps, makeStyles } from "@fluentui/react-components";
import { ICardEntry } from "../models/ICardEntry";
import ConfigService from "../services/configService";
import UdcDropdownField from "./UdcDropdownField";
import UdcTextField from "./UdcTextField";
import { useState } from "react";
import { IUserCode } from "../models/IUserCode";
import { IUdcFieldModel } from "../models/IUdcFieldModel";
import { FieldType } from "../enums/fieldType";

interface UdcFieldsProps extends Partial<ComboboxProps>{
    cardEntry: ICardEntry;
    onUdcChange: (codeX: string, udcObj: IUserCode, isFromDropdown: boolean) => void;
    clearUdcDetails: (codeX: string) => void;
    setIsFetchingData: (isFetchingData: boolean) => void;
    showErrorMessage: (exception: string) => void;
}

const useStyles = makeStyles({
    mt5: {
      marginTop: '1px',
    }
});

export default function UdcFields(props: UdcFieldsProps) {

    // Get the UDC fields from the configuration
    const[udcFields] = useState<IUdcFieldModel[]>(ConfigService.getUDCFields());
    
    const classes = useStyles();

    return (
        <div className={`list-body ${classes.mt5}`}>
            {udcFields?.map(udc => {
                if (udc.ControlType == FieldType.ComboboxField) {
                    return (
                        <div key={udc.Name}>
                            <UdcDropdownField cardEntry={props.cardEntry} field={udc} onUdcChange={props.onUdcChange} clearUdcDetails={props.clearUdcDetails} setIsFetchingData={props.setIsFetchingData} showErrorMessage={props.showErrorMessage}/>
                        </div>    
                    )
                } else {
                    return (
                       <div key={udc.Name}>
                            <UdcTextField cardEntry={props.cardEntry} field={udc} onUdcChange={props.onUdcChange} />
                        </div>
                    )
                }
            })} 
        </div>
    )
}