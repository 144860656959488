import { Text } from "@fluentui/react-components";
import { List28Filled } from "@fluentui/react-icons";
import { BaseWidget } from "@microsoft/teamsfx-react";
import CardWidgetControl from "../widgets/CardWidgetControl";

export default class CardWidgetBase extends BaseWidget<any, any> {
  
  header(): JSX.Element | undefined {
    return (
      <div>
        <List28Filled />
        <Text style={{fontSize: 20}}>{ this.props.cardEntry.isAddEntry ? 'Create Time Entry' : 'View Time Entry'}</Text>
      </div>
    );
  }

  body(): JSX.Element | undefined {
    return <CardWidgetControl cardEntry={this.props.cardEntry}/>
  }
}