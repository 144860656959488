import { Button,
  Label, 
  makeStyles, 
  Link, 
  Dropdown,
  Option,
  useId } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AdvancedLogo } from './advancedLogo.svg';
import { app } from "@microsoft/teams-js";
import LoggingService from "../services/loggingService";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { MessageType } from "../enums/messageType";
import { CONSTANTS } from "../constants/constants";

const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh'
    }
});

export default function LoginRedirect(props:any) {
  const navigate = useNavigate();

  const classes = useStyles();

  const auth = useAuth();

  const dropdownId = useId("dropdown-default");

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string }[]>([]);

  const [region, setRegion] = useState<string>(localStorage.getItem(CONSTANTS.user_region) ?? '');

  const options:{ [key: string]: string }[] = auth.allRegions;

  useEffect(() => {
    let selectedRegion = options.find((item) => item.key === region)?.value;
    setRegion(selectedRegion ?? '');
    app.initialize().then(() => {
        app.getContext().then(async (context) => {
          sessionStorage.setItem('hostClientType', context.app.host.clientType);
          LoggingService.log(LoggingService.isLoggingEnabled() ? `LoginRedirect - Context loaded` : '', MessageType.Info);

          var code = context.page.subPageId;
          if (code != null && code.length > 0){
            LoggingService.log(LoggingService.isLoggingEnabled() ? `LoginRedirect - Found code in context` : '', MessageType.Info);
            context.page.subPageId = '';
            auth.getToken(code).then((result) => {
              if (result){
                LoggingService.log(LoggingService.isLoggingEnabled() ? `LoginRedirect - Navigating to tab.` : '', MessageType.Info);
                navigate("/tab");
              }
            });
          }
        });
    });
  }, []);

  // Redirects to the login component
  async function redirect() {
      navigate("/externalLoginIdp");
  }

  const handleRegionChange = (ev:any, data:any): void => {
    setSelectedOptions(data.selectedOptions);
    setRegion(data.optionText ?? '');
    options.forEach((item) => {
      if(item.value === data.optionValue){
        auth.setUserRegion(item.key)
      }
    });
  };

  return (
    <div className={classes.container}>
          <AdvancedLogo style={{ width: '20rem', height: 'auto' }} />
          {props.isTokenExpired === 'true' ? (
              <Label style={{ padding: '3rem 0rem 1rem 0rem' }}>Your session has expired. Please login to the application again.</Label>
          ):(
              <div style={{ padding: '3rem 0rem 0.5rem 0rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Label style={{ padding: '0rem 1rem 0.5rem 1rem' }}>A valid OneAdvanced Time Capture (Carpe Diem) account is required to use this app.</Label>
                  <Label style={{ padding: '0rem 1rem 0.5rem 1rem' }}>Please visit <Link href="https://www.oneadvanced.com/products/time-capture" target="blank">OneAdvanced Time Capture</Link> for more information.</Label>
                  <Label style={{ padding: '2rem 1rem 0rem 1rem' }}>Please log into your account to start using the app.</Label>
              </div>
          )}
            <Label id={dropdownId}>Please select the region. </Label>
            <Dropdown
              aria-labelledby={dropdownId}
              placeholder="Select the region"
              value={region}
              selectedOptions={selectedOptions}
              onOptionSelect={(e, data) => handleRegionChange(e,data)}
              {...props}
            >
              {options.map((option) => (
                <Option key={option.key}>
                  {option.value}
                </Option>
              ))}
            </Dropdown>
          <Button disabled={!region} style={{marginTop:'1rem'}} appearance="primary" onClick={() => redirect()} >Login</Button>
    </div>
  )
}