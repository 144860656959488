import { ApiUrlConstant } from "../constants/api-url-constants";
import { CONSTANTS } from "../constants/constants";
import { MessageType } from "../enums/messageType";
import { IGetMisspelledWordsRequest } from "../models/IGetMisspelledWordsRequest";
import { ITlxFirmDictionaryEntity } from "../models/ITlxFirmDictionaryEntity";
import { IUserDictionary } from "../models/IUserDictionary";
import ConfigService from "./configService";
import DataService from "./dataService";
import LoggingService from "./loggingService";

const SpellCheckService = { 
    // Get misspelled words
    async getMisspelledWords(getMisspelledWordsRequest: IGetMisspelledWordsRequest) : Promise<string[]> {
      let misspelledWords: string[] = [];
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getMisspelledWords(${JSON.stringify({getMisspelledWordsRequest})}) started` : '', MessageType.Info);
        misspelledWords = await DataService.postData<string[]>(ApiUrlConstant.spellCheck.getMisspelledWords, getMisspelledWordsRequest);
        return misspelledWords;
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getMisspelledWords(${JSON.stringify({getMisspelledWordsRequest})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getMisspelledWords(${JSON.stringify({getMisspelledWordsRequest})}) - output(${JSON.stringify({misspelledWords})}) ended` : '', MessageType.Info);
      }
    },

    // Get suggestions
    async getSuggestions(word: string, matterLanguage: string, defaultLanguage: string, userSelectedLanguage: string) : Promise<string[]>{
      let suggestions: string[] = [];
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getSuggestions(${JSON.stringify({word, matterLanguage, defaultLanguage, userSelectedLanguage})}) started` : '', MessageType.Info);
        suggestions = await DataService.fetchData<string[]>(ApiUrlConstant.spellCheck.getSuggestions + "/" + word + "?matterLang=" + matterLanguage +
        "&defaultLang=" + defaultLanguage + "&userSelectedLanguage=" + userSelectedLanguage);
        return suggestions;
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getSuggestions(${JSON.stringify({word, matterLanguage, defaultLanguage, userSelectedLanguage})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getSuggestions(${JSON.stringify({word, matterLanguage, defaultLanguage, userSelectedLanguage})}) - output(${JSON.stringify({suggestions})}) ended` : '', MessageType.Info);
      }
    },

    // Get custom suggestions
    async getCustomSuggestions(matterlang: string, defaultLang: string, userSelectedLanguage: string) : Promise<ITlxFirmDictionaryEntity[]> {
      let suggestions: ITlxFirmDictionaryEntity[] = [];
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getCustomSuggestions(${JSON.stringify({matterlang, defaultLang, userSelectedLanguage})}) started` : '', MessageType.Info);
        suggestions = await DataService.fetchData<ITlxFirmDictionaryEntity[]>(ApiUrlConstant.spellCheck.getCustomSuggestions + "?matterLanguage=" + matterlang +
        "&defaultLanguage=" + defaultLang + "&userSelectedLanguage=" + userSelectedLanguage);
        return suggestions;
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getCustomSuggestions(${JSON.stringify({matterlang, defaultLang, userSelectedLanguage})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getCustomSuggestions(${JSON.stringify({matterlang, defaultLang, userSelectedLanguage})}) - output(${JSON.stringify({suggestions})}) ended` : '', MessageType.Info);
      }
    },

    // Get user dictionary
    async getUserDictionary(): Promise<string[]> {
      let personalDictionary: string[] = [];
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getUserDictionary() started` : '', MessageType.Info);
        let obj = await ConfigService.getUserConfigurationData<IUserDictionary>(CONSTANTS.userDictionary);
        if (obj && obj.UserDictionaryWords) {
            if (obj.UserDictionaryWords.Value !== "") {
                personalDictionary = obj.UserDictionaryWords.Value.split("#%D%#");
            }
        }

        return personalDictionary;
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getUserDictionary() - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - getUserDictionary() - output(${JSON.stringify({personalDictionary})}) ended` : '', MessageType.Info);
      }
    },

    // Save user dictionary
    async saveDictionary(newWordList: string[]) : Promise<boolean>{
      let result: boolean = false;
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - saveDictionary(${JSON.stringify({newWordList})}) started` : '', MessageType.Info);
        result = await DataService.postData<boolean>(ApiUrlConstant.spellCheck.updateUserDictionary, newWordList);
        return result;
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - saveDictionary(${JSON.stringify({newWordList})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - saveDictionary(${JSON.stringify({newWordList})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
      }
    },

    // Save user dictionary configuration data
    async saveUserDictionaryConfigurationData(words: string[], userlanguage: string) {
    try {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - saveUserDictionaryConfigurationData(${JSON.stringify({words, userlanguage})}) started` : '', MessageType.Info);
        let output: IUserDictionary = {
          Class: { Value: CONSTANTS.userDictionary },
          SelectedLanguage: { Value: userlanguage },
          UserDictionaryWords: { Value: words ? words.join("#%D%#") : "" }
        };
        ConfigService.saveUserConfigurationData(CONSTANTS.userDictionary, output);
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - saveUserDictionaryConfigurationData(${JSON.stringify({words, userlanguage})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `SpellCheckService - saveUserDictionaryConfigurationData(${JSON.stringify({words, userlanguage})}) ended` : '', MessageType.Info);
      }
    }
}

export default SpellCheckService;