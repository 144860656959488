import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../services/authService";
import jwt_decode from 'jwt-decode';
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { IToken } from "../models/IToken";
import { app, authentication, HostClientType } from "@microsoft/teams-js";
import SecurityService from "../services/securityService";
import ApplicationSettingService from "../services/applicationSettingService";
import { CONSTANTS } from "../constants/constants";

interface AuthContextType {
  userToken: string | undefined;
  logout: () => void;
  login: () => Promise<any>;
  popupLogin: () => Promise<any>;
  loginCallback: () => Promise<any>;
  popupLoginCallback: () => Promise<any>;
  silentCallback: () => Promise<any>;
  redirectUserToIdp: () => Promise<void>;
  getToken: (code : string) => Promise<boolean>;
  getAccessTokenFromRefreshToken: (code : string) => Promise<boolean>;
  clearToken: () => void;
  allRegions: { [key: string]: string }[];
  setUserRegion:(region:string)=> void;
  identityServerUrl: string;
}

let AuthContext = createContext<AuthContextType>(null!);

const useAuth = () => useContext(AuthContext);

let eventInitialized = false;

 // This function is used to get the user id from the token
 function getUserId(accessToken: string): string {
  let userId = "";
  try {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - getUserId()` : '', MessageType.Info);
    if (accessToken) {
      let decodedToken: any = jwt_decode(accessToken);
      userId = decodedToken.unique_name;
    }
  } catch (error) {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - getUserId() - ${error}` : '', MessageType.Error);
  } finally {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - getUserId() - output(${userId})` : '', MessageType.Info);
  }
  return userId;
}

function AuthProvider({ children }: { children: React.ReactNode }) {

  let accessToken: string = sessionStorage.getItem("token")! ;

  let [userToken, setUser] = useState<string | undefined>(accessToken);

  let [allRegions, setAllRegions] = useState<{ [key: string]: string }[]>([]);

  let [userRegion, setRegion] = useState<string>(localStorage.getItem(CONSTANTS.user_region) ?? '');

  let [identityServerUrl, setIdentityServerUrl] = useState<string>('');

  useEffect(() => {
      setAllRegions(ApplicationSettingService.getAllRegions());
      if(userRegion){ 
          setIdentityServerUrl(ApplicationSettingService.getIdentityServerEndpoint(userRegion));
      }
  }, []);

  if (!eventInitialized)
  {
    eventInitialized = true;
    
    AuthService.userManager().events.addAccessTokenExpiring(function () {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - token expiring` : '', MessageType.Info);
    });

    AuthService.userManager().events.addAccessTokenExpired(function () {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - token expired` : '', MessageType.Info);
    });
    
    AuthService.userManager().events.addSilentRenewError(function (e : any) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - silent renew error - ${e.message}` : '', MessageType.Error);
    });
    
    AuthService.userManager().events.addUserLoaded(function (authUser : any) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - user loaded event raised - ${JSON.stringify(authUser)}` : '', MessageType.Info);

        if (authUser){
          LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Updating token via user loaded event.` : '', MessageType.Info);
          sessionStorage.setItem("token", authUser.access_token);
          sessionStorage.setItem("refreshToken", authUser.refresh_token!);
          sessionStorage.setItem("userId", getUserId(authUser.access_token));
          setUser(authUser.access_token);
        }
    });
  }

  const logout = async () => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Logout Started` : '', MessageType.Info);

    const authorityUrl = identityServerUrl;
    const clientId = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_client_id);
    const postLogoutRedirectUri = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_url);

    let token=sessionStorage.getItem("token")!;
    let hostClientType = sessionStorage.getItem("hostClientType");

    const logoutUrl = `${authorityUrl}connect/endsession?client_id=${clientId}&post_logout_redirect_uri=${postLogoutRedirectUri}&id_token_hint=${token}`;
    
    try {
        // Open the logout URL in a new window
        const logoutWindow = window.open(logoutUrl, '_blank', 'width=500,height=300');

        if (hostClientType == HostClientType.web){  
            // Check periodically if the logout window has redirected to the postLogoutRedirectUri
            const checkWindowClosed = setInterval(() => {

            LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Logout Interval Started` : '', MessageType.Info);

            try {
                logoutWindow!.close();
                clearInterval(checkWindowClosed);
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("refreshToken");
                sessionStorage.removeItem("userId");
                clearToken();

                LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Logout Interval Ended` : '', MessageType.Info);
            } catch (e) {
              LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Logout Interval Error - ${e}` : '', MessageType.Error);
            }
          }, 2000); // close window in 2 second
        }
        else {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Clearing session on Logout` : '', MessageType.Info);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("refreshToken");
          sessionStorage.removeItem("userId");
          clearToken();
        }
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Logout Error - ${error}` : '', MessageType.Error);
    }

    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Logout Ended` : '', MessageType.Info);
  };

  const login = () =>  AuthService.userManager().signinRedirect();

  const popupLogin = async () => {
    return AuthService.userManager().signinPopup().then(function(authUser : any) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Popup Sign-in response success - ${JSON.stringify(authUser)}` : '', MessageType.Info);
        if (authUser){
          LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Updating token after Popup sign-in.` : '', MessageType.Info);
          sessionStorage.setItem("token", authUser.access_token);
          sessionStorage.setItem("refreshToken", authUser.refresh_token!);
          sessionStorage.setItem("userId", getUserId(authUser.access_token));
          setUser(authUser.access_token);
        }
    }).catch(function(err : any) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Popup Sign-in response error - ${err}` : '', MessageType.Error);
    });
  };

  const loginCallback = async () => {
    let url = window.location.hash;
    url = url.replace('#', '');
    return AuthService.userManager().signinRedirectCallback(url).then(function(authUser : any) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Sign-in response success - ${JSON.stringify(authUser)}` : '', MessageType.Info);
        if (authUser){
          LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Updating token after sign-in.` : '', MessageType.Info);
          sessionStorage.setItem("token", authUser.access_token);
          sessionStorage.setItem("refreshToken", authUser.refresh_token!);
          sessionStorage.setItem("userId", getUserId(authUser.access_token));
          setUser(authUser.access_token);
        }
    }).catch(function(err : any) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Sign-in response error - ${err}` : '', MessageType.Error);
    });
  };
  
  const popupLoginCallback = async () => {
      let url = window.location.hash;
      url = url.replace('#', '');
    return AuthService.userManager().signinPopupCallback(url).then(function() {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Sign-in callback response success` : '', MessageType.Info);
    }).catch(function(err: any) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Sign-in callback response error - ${err}` : '', MessageType.Error);
    });
  };

  const silentCallback = async () => {
    let url = window.location.hash;
    url = url.replace('#', '');
    return AuthService.userManager().signinSilentCallback(url).then(function() {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Sign-in silent callback response success` : '', MessageType.Info);
    }).catch(function(err: any) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Sign-in silent callback response error - ${err}` : '', MessageType.Error);
    });
  };

  const redirectUserToIdp = async () => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Redirecting user to Idp` : '', MessageType.Info);
    
    var oauthRedirectMethod = 'not-supported';
    var oauthRedirectUrl = encodeURIComponent(window.location.origin + "/index.html#/auth-end");
    var hostClientType = sessionStorage.getItem('hostClientType');
    var appInternalId = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_internal_id);

    if (hostClientType === HostClientType.web){
      oauthRedirectMethod = 'page';
    }
    else{
      oauthRedirectMethod = 'deeplink';
    }
    
    app.initialize().then(() => {
      AuthService.updateUserManagerSettings(identityServerUrl);

      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Redirect user to Idp - Initialize - ${oauthRedirectMethod} - ${oauthRedirectUrl} - ${identityServerUrl}` : '', MessageType.Info);

      authentication.authenticate({
        url: `${window.location.origin}/index.html#/auth-start?oauthRedirectMethod=${oauthRedirectMethod}&hostRedirectUrl=${oauthRedirectUrl}&authId=${appInternalId}&identityServerUrl=${identityServerUrl}`,
        isExternal: true,
        width: 600,
        height: 550,
      }).then((result) => {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Redirect user to Idp - Auth Code received` : '', MessageType.Info);
        getToken(result).then((result) => {
          if (result){
            LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Sign-in successful.` : '', MessageType.Info);
          }
        });
      }).catch(function(err) {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Redirect user to Idp response error - ${err}` : '', MessageType.Error);
      })
    })
  };

  const getToken = async (code: string) => {  
    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Get token started` : '', MessageType.Info);

    const appClientId: string = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_client_id);
    const getTokenUrl: string = `${identityServerUrl}connect/token`;
    const redirectUrl: string = `${window.location.origin}/index.html#/auth-end`;

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    var details : any = {
      'client_id': appClientId,
      'code': code,
      'grant_type': 'authorization_code',
      'redirect_uri': redirectUrl,
      'code_verifier': 'c775e7b757ede630cd0aa1113bd102661ab38829ca52a6422ab782862f268646'
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }

    var obj = formBody.join("&");

    const response = await fetch(getTokenUrl, {
        method: 'POST',
        body: obj,
        headers: headers
    });

    if (response.ok) {
      var data : IToken = await response.json() as IToken;  
      
      var userId = getUserId(data.access_token);
      sessionStorage.setItem("token", data.access_token);
      sessionStorage.setItem("userId", userId);
      let hostClientType = sessionStorage.getItem('hostClientType');
      if (hostClientType !== HostClientType.web) {
        sessionStorage.setItem("refreshToken", data.refresh_token);
      }

      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Get token received for ${userId}` : '', MessageType.Info);

      setUser(data.access_token);
      return true;
    }
    else
    {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Get token error - ${JSON.stringify(response)}` : '', MessageType.Error);
      return true;
    }
  };

  const getAccessTokenFromRefreshToken = async (refreshToken: string) => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Refreshing token started` : '', MessageType.Info);
  
    const appClientId: string = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_client_id);
    const tokenUrl: string = `${identityServerUrl}connect/token`;
  
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
  
    var details: any = {
      'client_id': appClientId,
      'refresh_token': refreshToken,
      'grant_type': 'refresh_token'
    };
  
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    var body = formBody.join("&");
  
    const response = await fetch(tokenUrl, {
      method: 'POST',
      body: body,
      headers: headers
    });
  
    if (response.ok) {
      var data: IToken = await response.json() as IToken;
      
      // Store the new access token and refresh token (if provided) in sessionStorage
      sessionStorage.setItem("token", data.access_token);
      if (data.refresh_token) {  // Check if a new refresh token was provided
        sessionStorage.setItem("refreshToken", data.refresh_token);
      }
  
      var userId = getUserId(data.access_token);
      sessionStorage.setItem("userId", userId);
  
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - New token ${data.access_token}` : '', MessageType.Info);
      
      setUser(data.access_token);

      SecurityService.loadPermissionsAndLicenses();
      return true;
    } else {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Refresh token error - ${JSON.stringify(response)}` : '', MessageType.Error);
      return false;
    }
  }; 

  const clearToken = () => { 
    setUser('');
  };

  const setUserRegion = (region:string):void =>{
    if(region){     
      localStorage.setItem(CONSTANTS.user_region, region);
      setRegion(region);
      setIdentityServerUrl(ApplicationSettingService.getIdentityServerEndpoint(region));
      LoggingService.log(`AuthContext - UserRegion - ${region}`, MessageType.Info);
    } else {
      LoggingService.log(`AuthContext - setApiUrls - ${CONSTANTS.user_region}: ${userRegion}`, MessageType.Error);
    } 
  };

  return (<AuthContext.Provider value={{ allRegions, setUserRegion, identityServerUrl, userToken, logout, login, popupLogin, loginCallback, popupLoginCallback, silentCallback, redirectUserToIdp, getToken, getAccessTokenFromRefreshToken, clearToken }}>{children}</AuthContext.Provider>);
}



function AuthStatus() {
    let auth = useAuth();
    let navigate = useNavigate();
  
    if (!auth.userToken) {
      return <p>You are not logged in.</p>;
    }
  
    return (
      <p>
        Welcome {auth.userToken}!{" "}
        {/* <button onClick={() => { auth.logout(() => navigate("/")); }}>
          Sign out
        </button> */}
      </p>
    );
}

function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
  
    if (!auth.userToken) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `AuthContext - Authentication is required. Redirecting user to login screen.` : '', MessageType.Info);
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
}

export { useAuth, AuthStatus, RequireAuth, AuthProvider };