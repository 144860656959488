import { CONSTANTS } from "../constants/constants";
import { MessageType } from "../enums/messageType";
import { TimeOperationsPermissions } from "../enums/timeOperationsPermissions";
import { UserLicenses } from "../enums/userLicences";
import JwtHelperService from "./jwtHelperService";
import LoggingService from "./loggingService";

// Declare the variables to store the licenses and permissions
let licenses: number;
let timePermissions: number;
let isSuperAdmin: boolean;

const SecurityService = {
    
    isSuperAdmin() {
        return isSuperAdmin;
    },

    // Load permissions and licenses from the token
    loadPermissionsAndLicenses() {
        try {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - loadPermissionsAndLicenses() started` : '', MessageType.Info);
          let token = sessionStorage.getItem("token");
          if (token) {
            let decodedResult = JwtHelperService.decodeToken(token);
            if (decodedResult) {
              licenses = +decodedResult.licences;
              timePermissions = +decodedResult.timePermissions;
              isSuperAdmin = decodedResult.role === CONSTANTS.superAdmin;
            }
          }
        } catch (error) {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - loadPermissionsAndLicenses() - ${error}` : '', MessageType.Error);
          throw error;
        } finally {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - loadPermissionsAndLicenses() ended` : '', MessageType.Info);
        }
    },

    // Check if the user has the required license
    hasLicense(requiredLicense: UserLicenses): boolean {
        let result = false;
        try {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - hasLicense(${JSON.stringify({requiredLicense})}) started` : '', MessageType.Info);
          return result = (requiredLicense & licenses) ? true : false;
        } catch (error) {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - hasLicense(${JSON.stringify({requiredLicense})}) - ${error}` : '', MessageType.Error);
          throw error;
        } finally {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - hasLicense(${JSON.stringify({requiredLicense})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    },

    // Check if the user has the required permission
    hasTimeOperationPermissions(requiredPermission: TimeOperationsPermissions): boolean {
        let result = false;
        try {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - hasTimeOperationPermissions(${JSON.stringify({requiredPermission})}) started` : '', MessageType.Info);
          return result = (requiredPermission & timePermissions) ? true : false;
        } catch (error) {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - hasTimeOperationPermissions(${JSON.stringify({requiredPermission})}) - ${error}` : '', MessageType.Error);
          throw error;
        } finally {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `SecurityService - hasTimeOperationPermissions(${JSON.stringify({requiredPermission})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    }
}

export default SecurityService;