import { CONSTANTS } from "../constants/constants";
import { EntryStatuses } from "../enums/entryStatus";
import { MessageType } from "../enums/messageType";
import { ICardEntry } from "../models/ICardEntry";
import { IConfigValue } from "../models/IConfigValue";
import { IIniConfig } from "../models/IIniConfig";
import { ISoftText } from "../models/ISoftText";
import { ITimeFinderActivity } from "../models/ITimefinderActivity";
import ConfigService from "./configService";
import LoggingService from "./loggingService";

// This will hold the soft text details
let softText: ISoftText | null;

const SoftTextService = { 

    // Load all soft text details
    async loadAllSoftTextDetails() {
        try {
            softText = ConfigService.getConfigurationData<ISoftText>("SoftText");
        } catch (error) {
            throw error;
        }
    },

    // Get soft text value
    getSoftTextValue(key: string) { 
        let value = "";
        try {
            if (softText) {
                return value = (softText[key] as IConfigValue).Value
            }
    
            return value;
        } catch (error) {
            throw error;
        }
    },

    // This function is used to get the display name of the client
    getClientDisplayName(entity: ICardEntry | ITimeFinderActivity): string {
        let displayName = "";  
        try {
            if (entity.clientId) {
                return displayName = entity.clientId + ":" + entity.clientName;
            } else {
                return displayName = "Unknown " + this.getSoftTextValue(CONSTANTS.client);
            }
        } catch (error) {
            throw error;
        }
    },

    // This function is used to get the display name of the matter
    getMatterDisplayName(entity: ICardEntry | ITimeFinderActivity): string {
        let displayName = ""; 
        try {
            if (entity.matterId) {
                return displayName = entity.matterId + ":" + entity.matterName;
            } else {
                return displayName = "Unknown " + this.getSoftTextValue(CONSTANTS.matter);
            }
        } catch (error) {
            throw error;
        }
    },

    // This function is used to get the display status of an entry
    getStatus(data?: string): string {
        let status = "";
        try {
            if (!data) return status;
  
            let configData = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config);
            if (data == "2" && configData?.UseApproval == "1") {
                return status = "Pending"
            }
            else if (data == "2") {
                return status = "Submitted"
            } else if (data == "12") {
            return status = "Non-compliant"
            } else {
                return status = EntryStatuses[+data];
            }
        } catch (error) {
            throw error;
        }
    }
}

export default SoftTextService;