import * as React from "react";
import "../styles/FoundWidget.css";

import {
    Person24Filled,
    PersonAccounts24Filled,
    BroadActivityFeed24Filled,
    ShiftsActivity24Filled,
    AddFilled,
    Timer24Regular,
    Calendar24Regular,
    CallInbound24Regular,
    CallOutbound24Regular,
    EyeOff24Regular,
} from "@fluentui/react-icons";

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Button,
  useFocusableGroup,
  TableColumnSizingOptions,
  useTableFeatures,
  TableColumnDefinition,
  createTableColumn,
  useTableColumnSizing_unstable,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Spinner,
  Label,
  makeStyles,
  useTableSort,
  TableColumnId,
} from "@fluentui/react-components";
import { ITimeFinderActivity } from "../models/ITimefinderActivity";
import SoftTextService from "../services/softTextService";
import { CONSTANTS } from "../constants/constants";
import TimeFinderService from "../services/timeFinderService";
import moment from "moment";
import TimerService from "../services/timerService";
import TimekeeperService from "../services/timekeeperService";
import TimeEntryService from "../services/timeEntryService";
import ConfigService from "../services/configService";
import { IUserDefaultValues } from "../models/IUserDefaultValues";
import { IIniConfig } from "../models/IIniConfig";
import { IRoundingBillableRequest } from "../models/IRoundingBillableRequest";
import { IRoundingBillableResponse } from "../models/IRoundingBillableResponse";
import { ITimeRestrictionsConfig } from "../models/ITimeRestrictionsConfig";
import { NotificationMessage, NotificationMessageHandler } from "../components/NotificationMessage";
import { TimeOperationsPermissions } from "../enums/timeOperationsPermissions";
import SecurityService from "../services/securityService";
import { UserLicenses } from "../enums/userLicences";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";

const useStyles = makeStyles({
  spinner: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  noLicense: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%'
  }
 });



export const FoundView = () => {
  
  // This is the item type that will be used to store the data for the table in each cell
  type Item = {
    timeFinderActivity : ITimeFinderActivity
  };

  const focusableGroupAttr = useFocusableGroup({
    tabBehavior: "limited-trap-focus",
  });

  // This is used to get the styles
  const styles = useStyles();

  // This is used to get the notification ref
  const notificationRef = React.useRef<NotificationMessageHandler>(null);
  
  // This is used to check if the user has the permission to create time
  const [createTimePermissions] = React.useState<boolean>(SecurityService.hasTimeOperationPermissions(TimeOperationsPermissions.Time_CreateTime));

  // This is used to check if the user has the license to use the timefinder
  const [timeFinderLicense] = React.useState<boolean>(SecurityService.hasLicense(UserLicenses.CDTIMEFINDER));

  // This is the state that will hold the data for the table
  const [items, setItems] = React.useState<Item[]>([]);
  
  // This is the state that will hold the template for the columns
  const [columnsDef, setColumnsDef] = React.useState<TableColumnDefinition<Item>[]>([]);

  // This is the state that will hold the columns for the table
  const [columns, setColumns] = React.useState<TableColumnDefinition<Item>[]>(columnsDef);

  // This is used to render the size of columns
  const [columnSizingOptions, setColumnSizingOptions] = React.useState<TableColumnSizingOptions>({});

  // This variable is used to display the spinner when fetching data
  const [loading, setIsLoading] = React.useState(false);

  React.useEffect (() => {
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'FoundView - useEffect() started' : '', MessageType.Info);
    buildDisplayColumns();
    fetchTimefinderActivities();

    const handleResize = debounce(() => {
      buildDisplayColumns();
    }, 300);

    window.addEventListener('resize', handleResize);
    LoggingService.log(LoggingService.isLoggingEnabled() ? 'FoundView - useEffect() ended' : '', MessageType.Info);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // This is used to update the rows when the items change
  React.useEffect(() => {
    rows = sort(getRows());
  }, [items]);

  // This is used to update the columns when the columns change
  React.useEffect(() => {
    setColumns(columnsDef);
  }, [columnsDef]);

  // This function is used to build the display columns
  function buildDisplayColumns() { 
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'FoundView - buildDisplayColumns() started' : '', MessageType.Info);
      let staticColumnsDef: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
          columnId: "startTime",
          renderHeaderCell: () => <><ShiftsActivity24Filled />StartTime</>,
          renderCell: (item: Item) => <span>{TimerService.getTimeDisplayValue(item.timeFinderActivity.activityStartTime)}</span>,
          compare: (a, b) => {
            let timeA = new Date(a.timeFinderActivity.activityStartTime).getTime();
            let timeB = new Date(b.timeFinderActivity.activityStartTime).getTime();
            return timeA - timeB;
          },
        }),
        createTableColumn<Item>({
          columnId: "client",
          renderHeaderCell: () => <><Person24Filled />{SoftTextService.getSoftTextValue(CONSTANTS.client)}</>,
          renderCell: (item: Item) => <span>{SoftTextService.getClientDisplayName(item.timeFinderActivity)}</span>,
          compare: (a, b) => {
            let clientA = a.timeFinderActivity.clientId ?? "";
            let clientB = b.timeFinderActivity.clientId ?? "";
            return clientA.localeCompare(clientB);
          },
        }),
        createTableColumn<Item>({
          columnId: "matter",
          renderHeaderCell: () => <><PersonAccounts24Filled />{SoftTextService.getSoftTextValue(CONSTANTS.matter)}</>,
          renderCell: (item: Item) => <span>{SoftTextService.getMatterDisplayName(item.timeFinderActivity)}</span>,
          compare: (a, b) => {
            let matterA = a.timeFinderActivity.matterId ?? "";
            let matterB = b.timeFinderActivity.matterId ?? "";
            return matterA.localeCompare(matterB);
          },
        }),
        createTableColumn<Item>({
          columnId: "activity",
          renderHeaderCell: () => <><BroadActivityFeed24Filled />Activity</>,
          renderCell: (item: Item) => <span>{item.timeFinderActivity.narrative}</span>,
        }),
        createTableColumn<Item>({
          columnId: "duration",
          renderHeaderCell: () => <><Timer24Regular />Duration</>,
          renderCell: (item: Item) => <span>{TimerService.convertSecondsToHoursAndMinutes(item.timeFinderActivity.duration)}</span>,
          compare: (a, b) => {
            let durationA = a.timeFinderActivity.duration;
            let durationB = b.timeFinderActivity.duration;
            return durationA - durationB;
          },
        }),
        createTableColumn<Item>({
          columnId: "action",
          renderHeaderCell: () => <>Action</>,
        }),
      ];
  
      setColumnsDef(staticColumnsDef);
  
      let totalWidth = window.innerWidth;
      let staticColumnSizingOptions: TableColumnSizingOptions = {
        startTime: {
          minWidth: 150,
          defaultWidth: totalWidth * 0.15 // 15%
        },
        client: {
          minWidth: 150,
          defaultWidth: totalWidth * 0.15 // 15%
        },
        matter: {
          minWidth: 150,
          defaultWidth: totalWidth * 0.15 // 15%
        },
        activity: {
          minWidth: 150,
          defaultWidth: totalWidth * 0.45 // 45%
        },
        duration: {
          minWidth: 150,
          defaultWidth: totalWidth * 0.1 // 10%
        },
        action: {
          minWidth: 100,
        },
      }
  
      setColumnSizingOptions(staticColumnSizingOptions);
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - buildDisplayColumns() - ${error}` : '', MessageType.Error);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'FoundView - buildDisplayColumns() ended' : '', MessageType.Info);
    }
  }  
 
  // This function is used to render the activity cell
  function renderActivityCell(activity: ITimeFinderActivity) { 
    switch (activity.activityType) {
      case "TEAMS_PHONE_IN":
        return <CallInbound24Regular />
      case "TEAMS_PHONE_OUT":
        return <CallOutbound24Regular />
      case "TEAMS_MEETING":
        return <Calendar24Regular />
    }
  }
  
  // Renders the table
  const { getRows, sort: { getSortDirection, toggleColumnSort, sort }, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: "startTime", sortDirection: "ascending" },
      }),
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: true 
      })
    ],
  );

  let rows = sort(getRows());

  // This function is used to get the sort properties for the header
  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  // This function is used to debounce the resize event
  function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: NodeJS.Timeout | null;

    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout!);
        func(...args);
      };

      clearTimeout(timeout!);
      timeout = setTimeout(later, wait);
    };
  };

  // This function is used to fetch the timefinder activities
  async function fetchTimefinderActivities() { 
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'FoundView - fetchTimefinderActivities() started' : '', MessageType.Info);
      if (!timeFinderLicense) return;
      setIsLoading(true);
      let timekeeperId = (await TimekeeperService.getDefaultTimekeeper()).timekeeperId;
      let today = moment().format("YYYY-MM-DD");
      let startTime = `${moment(today).format("YYYY-MM-DD")}T00:00:00.000`
      let endTime = `${moment(today).format("YYYY-MM-DD")}T23:59:59.999`
      let offSet = TimeEntryService.getTimeZoneOffset();
      let data = await TimeFinderService.getTimekeeperActivitiesAll(timekeeperId, startTime, endTime, offSet);
      data = data.filter((timeFinderActivity) => !timeFinderActivity.excluded);
      data = TimerService.convertActivitiesTimeToLocalTime(data);
      //data.sort((a, b) => new Date(a.activityStartTime).getTime() - new Date(b.activityStartTime).getTime());
      setItems(data.map((timeFinderActivity) => ({ timeFinderActivity })));
      setIsLoading(false);
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - fetchTimefinderActivities() - ${error}` : '', MessageType.Error);
      setIsLoading(false);
      notificationRef.current?.showNotification(CONSTANTS.errorMessage, true);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? 'FoundView - fetchTimefinderActivities() ended' : '', MessageType.Info);
    }
  }

  // This function is used to close the timefinder activity
  async function closeTimeFoundActivity(item: ITimeFinderActivity, showMessage: boolean = true) {
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - closeTimeFoundActivity(${JSON.stringify({item, showMessage})}) started` : '', MessageType.Info);
      let response = await TimeFinderService.excludeTimeFinderActivity((await TimekeeperService.getDefaultTimekeeper()).timekeeperId, item);
      if (response) {
        if (showMessage) {
          notificationRef.current?.showNotification("TimeFinder Activity has been excluded successfully.");
        }
        let data = items.filter((timeFinderActivity) => timeFinderActivity.timeFinderActivity.id !== item.id);
        setItems(data);
      } else {
        notificationRef.current?.showNotification(CONSTANTS.errorMessage, true);
      }
    } catch (error) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - closeTimeFoundActivity(${JSON.stringify({item, showMessage})}) - ${error}` : '', MessageType.Error);
      notificationRef.current?.showNotification(CONSTANTS.errorMessage, true);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - closeTimeFoundActivity(${JSON.stringify({item, showMessage})}) ended` : '', MessageType.Info);
    }
  }

  // This function is used to add the timefinder activity as a time entry
  async function addTimeFinderTimeEntry(timeFinderItem: ITimeFinderActivity) {
    try {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - addTimeFinderTimeEntry(${JSON.stringify({timeFinderItem})}) started` : '', MessageType.Info);
      let tkprid = (await TimekeeperService.getDefaultTimekeeper()).timekeeperId, userId = sessionStorage.getItem(CONSTANTS.userId) ?? "", sourceOfEntry = "F6";
      let timeEntry = TimeEntryService.createEmptyTimeEntry(tkprid,  userId, sourceOfEntry);

      timeEntry.clientId = timeFinderItem.clientId ? timeFinderItem.clientId : "";
      timeEntry.projectId = timeFinderItem.matterId ? timeFinderItem.matterId : "";
      let keys = Object.keys(timeFinderItem.userCodes);
      timeEntry.userCodes = {}; // Initialize the userCodes object
      for (let i = 0; i < keys.length; i++) {
        if (timeFinderItem.userCodes[keys[i]]) {
          timeEntry.userCodes[keys[i]] = timeFinderItem.userCodes[keys[i]];
        }
      }

      let userDefaultConfig = ConfigService.getUserConfigurationData<IUserDefaultValues>(CONSTANTS.defaultValues);
      let configData = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config);
      if (userDefaultConfig) {
        if (userDefaultConfig["Client"] && !timeEntry.clientId) {
          timeEntry.clientId = userDefaultConfig["Client"].Value;
        }

        if (userDefaultConfig["Matter"] && !timeEntry.projectId) {
          timeEntry.projectId = userDefaultConfig["Matter"].Value;
        }

        let timeCodes = configData ? configData.TimeCodes.split(",") : [];
        timeCodes.forEach((timeCode) => {
          if (userDefaultConfig && userDefaultConfig[`Code${timeCode}`]) {
            if (timeEntry.userCodes && !timeEntry.userCodes[`Code${timeCode}`]) {
              timeEntry.userCodes[`Code${timeCode}`] = userDefaultConfig[`Code${timeCode}`]?.Value ?? "";
            }
          }
        });
      }

      var roundingRequest: IRoundingBillableRequest = {
        clientId: timeEntry.clientId, matterId: timeEntry.projectId,
        costCode: "", tkprId: tkprid, userCodes: timeEntry.userCodes
      };

      timeEntry.rawTime = timeFinderItem.duration;

      let roundingType = ConfigService.getConfigurationData<ITimeRestrictionsConfig>("TimeRestrictions")?.AutoRound ?? "0";
      var roundingResponse: IRoundingBillableResponse = await TimeEntryService.getRoundingAndBillableValue(roundingRequest);
      timeEntry.billable = roundingResponse.billable;
      timeEntry.roundingValue = +(roundingResponse.roundingValue ?? 0);
      timeEntry.roundTime = TimerService.roundSeconds(+timeEntry.rawTime, +timeEntry.roundingValue, +roundingType);

      let activityStopTime = timeFinderItem.activityEndTime;
      const defaultTime: number = 100;
      if (timeFinderItem.activityStartTime.valueOf() == timeFinderItem.activityEndTime.valueOf()) {
        let stopTime = TimerService.getDateTime(timeFinderItem.activityStartTime);
        stopTime.setTime(stopTime.getTime() + defaultTime);
        activityStopTime = moment(stopTime).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      timeEntry.startStopSequences = [];
      timeEntry.startStopSequences.push({
        entryID: "",
        sequenceID: 0,
        startTime: moment(timeFinderItem.activityStartTime).format("YYYY-MM-DDTHH:mm:ss.SSS"),
        stopTime: moment(activityStopTime).format("YYYY-MM-DDTHH:mm:ss.SSS")
      });

      timeEntry.narrativeForSheetview = timeFinderItem.narrative;
      timeEntry.spellChecked = 1;
      timeEntry.spellcheckOverride = 0;

      timeEntry.offset = TimeEntryService.getTimeZoneOffset();
      let saveEntrySteps = TimeEntryService.getSaveEntryStepsForAdd();
      let timeEntryObj = TimeEntryService.prepairSaveRequest(
        timeEntry,
        saveEntrySteps
      );
      
      let response = await TimeEntryService.AddTimeEntry(timeEntryObj);
      if (response) {
        notificationRef.current?.showNotification("TimeFinder Activity has been added as a Time Entry successfully.");
        closeTimeFoundActivity(timeFinderItem, false);
      }
    } catch (error) { 
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - addTimeFinderTimeEntry(${JSON.stringify({timeFinderItem})}) - ${error}` : '', MessageType.Error);
      notificationRef.current?.showNotification(CONSTANTS.errorMessage, true);
    } finally {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `FoundView - addTimeFinderTimeEntry(${JSON.stringify({timeFinderItem})}) ended` : '', MessageType.Info);
    }
  }

  return (
      timeFinderLicense ? (
      <div>
        <NotificationMessage ref={notificationRef}/>
        {loading ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : rows.length === 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%' }}>
              <Label size="large">No Data</Label>
            </div>
          ) : (
            <div style={{ overflowX: "auto" }}>
              
            <Table
            sortable aria-label="Table with sort"
            ref={tableRef}
            {...columnSizing_unstable.getTableProps()}
            size="medium"
            role="grid"
            noNativeElements={true}
          >
          <TableHeader>
            <TableRow className="tableHeader">
              {columns.map((column) => (
                <Menu openOnContext key={column.columnId}>
                  <MenuTrigger>
                  <TableHeaderCell
                    key={column.columnId}
                    {...columnSizing_unstable.getTableHeaderCellProps(column.columnId)}
                    {...(['client', 'matter', 'startTime', 'duration'].includes(column.columnId.toString()) ? headerSortProps(column.columnId) : {})}
                  >
                    {column.renderHeaderCell()}
                </TableHeaderCell>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        onClick={columnSizing_unstable.enableKeyboardMode(
                          column.columnId
                        )}
                      >
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ))}
            </TableRow>
          </TableHeader>
            <TableBody>
              {rows.map(({ item }) => (
                <TableRow key={item.timeFinderActivity.id}>
                  {columns.map((column) => {
                    if (column.columnId === 'action') {
                      return (
                      <TableCell tabIndex={0} {...focusableGroupAttr}>
                        <TableCellLayout>
                          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <Button disabled={!createTimePermissions} icon={<AddFilled />} aria-label="Add" onClick={() => addTimeFinderTimeEntry(item.timeFinderActivity)} title="Add Time"/>
                            <Button style={{ marginLeft: '5px' }} icon={<EyeOff24Regular />} aria-label="Exclude" onClick={() => closeTimeFoundActivity(item.timeFinderActivity)} title="Mark as Excluded"/>
                          </div>
                          
                        </TableCellLayout>
                    </TableCell> );
                  }

                  return (
                      <TableCell
                        key={column.columnId}
                        {...columnSizing_unstable.getTableCellProps(column.columnId)}
                      >
                        <TableCellLayout  
                          media={column.columnId === 'activity' ? renderActivityCell(item.timeFinderActivity) : null} 
                          truncate
                        >
                          {column.renderCell(item)}
                        </TableCellLayout>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
            </div>
        )}
      </div>
      ) : (
        <div className={styles.noLicense}>A TimeFinder license is required to use this tab. Please connect with your admin.</div>
      )
    );
};
