import { IDictionary } from "./IDictionary";
import { ILookUpData } from "./ILookUpData";
import { IStartStopSequence } from "./IStartStopSequence";
import { ITPVServiceResponse } from "./ITPVServiceResponse";
import { ITimeGap } from "./ITimeGap";
import { ITimeKeeper } from "./ITimeKeeper";

export class TimeEntry {
  isSelectedEntry?: boolean;
  selectedMergeEntry?: boolean;
  isForecastConverted?: boolean;
  source?: string;
  spellChecked?: number;
  status?: string;
  formattedStatus?: string;
  userApproved?: string;
  userClosed?: string;
  userCreated?: string;
  userEdited?: string;
  userTransferred?: string;
  userCodes?: IDictionary<string>;
  userCodesInt?: IDictionary<number>;
  userCodeIdDesc?: IDictionary<string>;
  trFile?: string;
  rawTimeDisplayValue?: string;
  narrativeCRC?: number;
  startStopSequences?: IStartStopSequence[];
  allStartStopSequencesFromDB?: IStartStopSequence[];
  userRejected?: string;
  dateRejected?: string;
  reason?: string;
  rejectedReason?: string;
  rowIndex?: number;
  isEntryEditing?: boolean;
  codeValue?: string;
  timeZone?: string;
  offset?: number;
  nickname?: string;
  worklistId?: string;
  remainingRawTime?: number;
  remainingRoundTime?: number;
  billStatus?: number;
  userLastModified?: string;
  rawTime?: number;
  rawTimeForSorting?: number;
  roundTimeForSorting?: number;
  otherTime?: number;
  narrativeForSheetview?: string;
  timekeeperId?: string;
  dateWorked?: string;
  timekeeperName?: string;
  clientId?: string;
  clientIdInt?: number;
  clientName?: string;
  projectId?: string;
  projectIdInt?: number;
  projectName?: string;
  approval?: string;
  billable?: string;
  previousBillable?: string;
  forecastTimeId?: string;
  billableOverriden?: string;
  isBillableOverrideReadOnly?: boolean;
  roundTimeDisplayValue?: string;
  roundTime?: number;
  roundingValue?: number;
  timeId?: string;
  isBillableOverride?: boolean;
  dateLastModified?: string;
  dateTransferred?: string;
  dateCreated?: string;
  dateClosed?: string;
  dateApproved?: string;
  comments?: string;
  billableDisplay?: string;
  dateEdited?: string;
  timekeeperEntries?: ITimeKeeper[];
  localValidationRules?: ILookUpData[];
  tpvData?: ITPVServiceResponse;
  forecastChecked?: boolean;
  isTpvWarning?: boolean;
  isConvertTimeEntry?: boolean;
  isAddEntry?: boolean;
  isSubmit?: boolean;
  spellCheckRequired?: boolean;
  matterLanguage?: string;
  timeGaps?: ITimeGap[];
  timekeeperObj?: ITimeKeeper;
  eventTime?: string;
  showEntry?: boolean;
  showEntireDayTimeGap?: boolean;
  notificationMessage?: string;
  isTimerRunning?: boolean;
  isWarning?: boolean;
  saveInProgress?: boolean;
  totalrecords?: number;
  showFoundTime?: boolean;
  isPartialConvertEntry?: boolean;
  isDirty?: boolean;
  isRecall?: boolean;
  validationOnly?: boolean;
  isOffline?: boolean;
  lastTimeEntrySyncTime?: string;
  isExpanded?: boolean;
  isNewTimeEntry?: boolean;
  showClientMatterToolTip?: boolean;
  isPresentDayEntry?: boolean;
  title?: string;
  temporaryTitle?: string;
  titleForSorting?: string;
  isWorklistEntry?: boolean;
  dateLabel?: string;
  tempWorklistId?: string;
  activityType?: string;
  userAddedFields?: IDictionary<string>;
  tempId?: string;
  isResponseIncomplete?: boolean;
  isOfflineAddEntry?: boolean;
  entryColor?: string;
  entryCount?: number;
  totalEntryCount?: string;
  timeEntryGroupID?: number;
  billableStatus?: boolean;
  inValidTimeEntry?: boolean;
  isDefaultTimekeeperForTimepad?: boolean;
  totalCount?: number;
  showEntries?: boolean;
  isNarrativeValidationsPerformed?: boolean;
  isTimeValidationsPerformed?: boolean;
  externalRef?: string;
  originalSource?: string;
  isFromSmartTimers?: boolean;
  processingStarted?: boolean;
  isFromDashboard?: boolean;
  isSmartTimer?: boolean;
  spellcheckDictionary?: string;
  spellcheckOverride?: number;
  isNonCompliant?: boolean;
  isAcknowledged?: boolean;
  complianceStatus?: number;
  nicknameId?: string;
}