import moment from "moment";
import { ApiUrlConstant } from "../constants/api-url-constants";
import { CONSTANTS } from "../constants/constants";
import { IIniConfig } from "../models/IIniConfig";
import { IConfirmationNotificationConfig } from "../models/IConfirmationNotificationConfig";
import { IMiscellaneousConfig } from "../models/IMiscellaneousConfig";
import { IRegionalParameterConfig } from "../models/IRegionalParameterConfig";
import { ISpellCheck } from "../models/ISpellCheck";
import { ITimeEntryServiceResponse } from "../models/ITimeEntryServiceResponse";
import { IUserDictionary } from "../models/IUserDictionary";
import { ICardEntry } from "../models/ICardEntry";
import ConfigService from "./configService";
import DataService from "./dataService";
import { IDeleteTimeEntryRequest } from "../models/IDeleteTimeEntryRequest";
import { ICalendarConfigData } from "../models/ICalendarConfig";
import ValidationDataService from "./validationDataService";
import { EntryStatuses } from "../enums/entryStatus";
import { IDayviewFooter } from "../models/IDayViewFooter";
import TimerService from "./timerService";
import { ITimeKeeper } from "../models/ITimeKeeper";
import { IServiceResponse } from "../models/IServiceResponse";
import { TimeEntry } from "../models/TimeEntry";
import { ISaveTimeEntryRequest } from "../models/ISaveTimeEntryRequest";
import { SaveEntrySteps } from "../enums/saveEntrySteps";
import { IRoundingBillableRequest } from "../models/IRoundingBillableRequest";
import { IRoundingBillableResponse } from "../models/IRoundingBillableResponse";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";

const TimeEntryService = {
  
    // Gets the data required for the footer of the day view
    async buildFooter(timekeeper: ITimeKeeper, calendar: string, selectedDate: string, timeEntriesObj: ICardEntry[]) {
      let footerData: IDayviewFooter = {
        targetTime: 0,
        recordedTime: 0,
        missingTime: 0
      };
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - buildFooter(${JSON.stringify({timekeeper, calendar, selectedDate, timeEntriesObj})}) started` : '', MessageType.Info);
        let targetTime = await this.getDayTarget(timekeeper, calendar, moment(selectedDate, CONSTANTS.routeDateParamFormat));
        let totalTime = 0;
        
        if (timeEntriesObj != null) { 
          timeEntriesObj.forEach(data => {
            if (data.timeId && data.timeId !== "" && data.status && data.status !== EntryStatuses.Forecast.toString()) {
                //Calculates the recorded time for global action bar
                totalTime = totalTime + (data.roundTime ?? 0);
            }
          });
        }

        let missingTime = (targetTime - totalTime);

        footerData = {
          missingTime: missingTime,
          recordedTime: totalTime,
          targetTime: targetTime
      };

      return footerData;;
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - buildFooter(${JSON.stringify({timekeeper, calendar, selectedDate, timeEntriesObj})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - buildFooter(${JSON.stringify({timekeeper, calendar, selectedDate, timeEntriesObj})}) - output(${JSON.stringify({footerData})}) ended` : '', MessageType.Info);
      }
    },

    // Gets the target time
    async getDayTarget(timekeeper: ITimeKeeper, calendar: string, date: moment.Moment) {
      let target: number = 0;
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getDayTarget(${JSON.stringify({timekeeper, calendar, date})}) started` : '', MessageType.Info);
        let calendarConfig = ConfigService.getConfigurationData<ICalendarConfigData>(CONSTANTS.calendars);
        let selectedDay = date.format("dddd"), 
        targetTime: number = 0, 
        isHoliday = false, 
        isWeekend = false,
        weekStart: string, 
        weekEnd = [0, 0], 
        nonWorkingDays: number[] = [], 
        num: number,
        calendarObject = calendarConfig && (Array.isArray(calendarConfig.Calendar) ? calendarConfig.Calendar : [calendarConfig.Calendar]).find(x => x?.Name == calendar);
        if (!calendarObject) {
          calendarObject = calendarConfig && (Array.isArray(calendarConfig.Calendar) ? calendarConfig.Calendar : [calendarConfig.Calendar]).find(x => x?.Name == calendarConfig?.DefaultCalendarName);
        }

        if (calendarObject) {
          weekStart = calendarObject.WeekBegins;
          if (calendarObject.Holiday) {
              let holiday = (Array.isArray(calendarObject.Holiday) ? calendarObject.Holiday : [calendarObject.Holiday]).find(x => x.Date == date.format("MM/DD/YYYY"));
              if (holiday) {
                  targetTime = 0;
                  isHoliday = true;
              }
          }
        }

        if (isHoliday == false) { 
          if (timekeeper) {
            switch (selectedDay) {
                case CONSTANTS.sunday:
                    targetTime = timekeeper.sundayMinutes ?? 0;
                    break;
                case CONSTANTS.monday:
                    targetTime = timekeeper.mondayMinutes ?? 0;
                    break;
                case CONSTANTS.tuesday:
                    targetTime = timekeeper.tuesdayMinutes ?? 0;
                    break;
                case CONSTANTS.wednesday:
                    targetTime = timekeeper.wednesdayMinutes ?? 0;
                    break;
                case CONSTANTS.thursday:
                    targetTime = timekeeper.thursdayMinutes ?? 0;
                    break;
                case CONSTANTS.friday:
                    targetTime = timekeeper.fridayMinutes ?? 0;
                    break;
                case CONSTANTS.saturday:
                    targetTime = timekeeper.saturdayMinutes ?? 0;
            }
          }
        }

        if (targetTime == null && (timekeeper.sundayMinutes == null && timekeeper.mondayMinutes == null && timekeeper.tuesdayMinutes == null &&
          timekeeper.wednesdayMinutes == null && timekeeper.thursdayMinutes == null && timekeeper.fridayMinutes == null &&
          timekeeper.saturdayMinutes == null)) {
          //Check if the day is weekend
          if (calendarObject?.Weekends != undefined) {
              if (calendarObject.Weekends != null || calendarObject.Weekends != "") {
                  let weekEndStr: string[] = calendarObject.Weekends.split(",");
                  for (let i in weekEndStr) {
                      nonWorkingDays.push(+weekEndStr[i]);
                  }
              }
              for (num = 0; num < nonWorkingDays.length; num++) {
                  if (+date.format("d") == nonWorkingDays[num] - 1) {
                      targetTime = 0;
                      isWeekend = true;
                  }
              }
          }
          //If the day is not a weekend, proceed with target time calculation
          if (!isWeekend) {
              if (timekeeper) {
                  targetTime = timekeeper.minTimeDay ?? 0;
              }
              if (!targetTime) {
                  /*SIR 55938 - It is now possible to time validation rule for timekeeper level however is does 
                  not take affect in the Day view target.This is the only way we can assign a  Time requirement for a groupe of timekeepers.*/
                  let validationRule: any;
                  validationRule = await ValidationDataService.getLocalValidationRules("", "", timekeeper.timekeeperId);
                  targetTime = validationRule.IfLessThan;
              }
          }
        }

        target = targetTime * 60;
        return target;
      } catch (error) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getDayTarget(${JSON.stringify({timekeeper, calendar, date})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getDayTarget(${JSON.stringify({timekeeper, calendar, date})}) - output(${JSON.stringify({target})}) ended` : '', MessageType.Info);
      }
    },

    // Deletes the selected entry
    async deleteEntry(timeIds: IDeleteTimeEntryRequest[]) : Promise<IServiceResponse>{
      let response: IServiceResponse = {};
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - deleteEntry(${JSON.stringify({timeIds})}) started` : '', MessageType.Info);
        response = await DataService.postData<IServiceResponse>(ApiUrlConstant.timeEntry.deleteTimeEntries, timeIds);
        return response;
      } catch (error) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - deleteEntry(${JSON.stringify({timeIds})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - deleteEntry(${JSON.stringify({timeIds})}) - output(${JSON.stringify({response})}) ended` : '', MessageType.Info);
      }
    },

    // Get the time entries for the day view
    async getTimeEntriesForDayView(startDate: string, tkprId: string, timeFormat: string, defaultRndInc: string, endDate: string, isSmartTimer: boolean): Promise<TimeEntry[]> {
      let timeEntries: TimeEntry[] = [];
      try { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getTimeEntriesForDayView(${JSON.stringify({startDate, tkprId, timeFormat, defaultRndInc, endDate, isSmartTimer})}) started` : '', MessageType.Info);
        timeEntries = await DataService.fetchData<TimeEntry[]>(ApiUrlConstant.timeEntry.getTimeEntries.replace("{0}", startDate).replace("{1}", tkprId).replace("{2}", timeFormat).replace("{3}", defaultRndInc).replace("{4}", endDate).replace("{5}", isSmartTimer.toString()));
        return timeEntries;
      } catch (error) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getTimeEntriesForDayView(${JSON.stringify({startDate, tkprId, timeFormat, defaultRndInc, endDate, isSmartTimer})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getTimeEntriesForDayView(${JSON.stringify({startDate, tkprId, timeFormat, defaultRndInc, endDate, isSmartTimer})}) - output(${JSON.stringify({timeEntries})}) ended` : '', MessageType.Info);
      }
    },

    // Convert the time entries to card entries
    mapTimeEntryToCardEntry(timeEntry: TimeEntry): ICardEntry { 
      let cardEntry: ICardEntry = {};
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - mapTimeEntryToCardEntry(${JSON.stringify(timeEntry)}) started` : '', MessageType.Info);

        cardEntry.timeId = timeEntry.timeId;

        cardEntry.clientId = timeEntry.clientId ?? "";
        cardEntry.clientName = timeEntry.clientName ?? "";

        cardEntry.matterId = timeEntry.projectId ?? "";
        cardEntry.matterName = timeEntry.projectName ?? "";

        cardEntry.timekeeperId = timeEntry.timekeeperId;
        cardEntry.timekeeperName = timeEntry.timekeeperName;

        cardEntry.userCodes = timeEntry.userCodes;
        cardEntry.userCodeIdDesc = timeEntry.userCodes;

        cardEntry.dateWorked = timeEntry.dateWorked;

        cardEntry.narrative = timeEntry.narrativeForSheetview;
        cardEntry.spellChecked = timeEntry.spellChecked;

        cardEntry.rawTime = timeEntry.rawTime;
        cardEntry.totalSeconds = timeEntry.rawTime;
        cardEntry.otherTime = timeEntry.otherTime;
        cardEntry.roundTime = timeEntry.roundTime;
        cardEntry.roundTimeDisplayValue = timeEntry.roundTimeDisplayValue;
        cardEntry.startStopSequences = timeEntry.startStopSequences;

        cardEntry.status = timeEntry.status;

        return cardEntry;
      } catch (error) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - mapTimeEntryToCardEntry(${JSON.stringify({timeEntry})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - mapTimeEntryToCardEntry(${JSON.stringify({timeEntry})}) - output(${JSON.stringify({cardEntry})}) ended` : '', MessageType.Info);
      }
    },

    // Adds the time entry
    async AddTimeEntry(timeEntryObj: ISaveTimeEntryRequest): Promise<ITimeEntryServiceResponse> { 
        let response: ITimeEntryServiceResponse = {
          timeID: "",
          status: "",
          dateWorked: new Date(),
          isTimeEntryAdded: false,
          narrativeCRC: 0,
          spellCheckValue: 0
        };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - AddTimeEntry(${JSON.stringify({timeEntryObj})}) started` : '', MessageType.Info);
            response = await DataService.postData<ITimeEntryServiceResponse>(ApiUrlConstant.timeEntry.addTimeEntry, timeEntryObj);
            return response;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - AddTimeEntry(${JSON.stringify({timeEntryObj})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - AddTimeEntry(${JSON.stringify({timeEntryObj})}) - output(${JSON.stringify({response})}) ended` : '', MessageType.Info);
        }
    },

    // Gets the save entry steps for add
    getSaveEntryStepsForAdd() {
        let saveEntrySteps: SaveEntrySteps[] = [];
        try {

            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getSaveEntryStepsForAdd() started` : '', MessageType.Info);
            saveEntrySteps = [
              SaveEntrySteps.ValidateRequiredData,
              SaveEntrySteps.ValidateDateWorked,
              SaveEntrySteps.ValidateWeekendOrHoliday,
              SaveEntrySteps.ValidateDuplicateEntry,
              SaveEntrySteps.SaveEntry,
              SaveEntrySteps.AddFavourites
          ];

          let confirmationConfig = ConfigService.getUserConfigurationData<IConfirmationNotificationConfig>(CONSTANTS.confirmation);
          const startNumber: number = 3;
          const deleteCount: number = 1;
          if (confirmationConfig && confirmationConfig.DuplicateEntries.Value === "false") {
            saveEntrySteps.splice(startNumber, deleteCount);
          }
          
          return saveEntrySteps;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getSaveEntryStepsForAdd() - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getSaveEntryStepsForAdd() - output(${JSON.stringify({saveEntrySteps})}) ended` : '', MessageType.Info);
        }
    },

    // Prepares the request obj
    prepairSaveRequest(timeEntry: TimeEntry, saveEntrySteps: SaveEntrySteps[]) {
      let saveTimeEntryRequest: ISaveTimeEntryRequest = {};
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - prepairSaveRequest(${JSON.stringify({timeEntry, saveEntrySteps})}) started` : '', MessageType.Info);
        let userPref = ConfigService.getUserConfigurationData<ISpellCheck>(CONSTANTS.spellCheck);
        let defaultLanguage = ConfigService.getUserConfigurationData<IUserDictionary>(CONSTANTS.userDictionary)?.SelectedLanguage.Value ?? "en-GB";
        let userSpellCheckPreferences = {
          ignoreAllCaps: (userPref?.IgnoreAllCaps?.Value === "true"),
          ignoreCappedWords: (userPref?.IgnoreCappedWords?.Value === "true"),
          ignoreDomainName: (userPref?.IgnoreDomainName?.Value === "true"),
          ignoreDoubleWord: (userPref?.IgnoreDoubleWord?.Value === "true"),
          ignoreMixedCase: (userPref?.IgnoreMixedCase?.Value === "true"),
          ignoreMixedDigits: (userPref?.IgnoreMixedDigits?.Value === "true"),
          spellCheckAddEdit: (userPref?.SpellCheckAddEdit?.Value === "true"),
          userSelectedLanguage: defaultLanguage
        };
  
        saveTimeEntryRequest = {
          timeEntry: timeEntry,
          saveEntrySteps: saveEntrySteps,
          worklistSuggestionCount: -1,
          spellCheckConfig: userSpellCheckPreferences,
          defaultLangForSpellCheck: timeEntry.spellcheckDictionary,
          matterLanguage: timeEntry.matterLanguage,
      };
  
        return saveTimeEntryRequest;
      } catch (error) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - prepairSaveRequest(${JSON.stringify({timeEntry, saveEntrySteps})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - prepairSaveRequest(${JSON.stringify({timeEntry, saveEntrySteps})}) - output(${JSON.stringify({saveTimeEntryRequest})}) ended` : '', MessageType.Info);
      }
    },

    // Gets the time zone offset
    getTimeZoneOffset() {
      let offset: number = 0;
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getTimeZoneOffset() started` : '', MessageType.Info);
        let regionalParameter =
        ConfigService.getUserConfigurationData<IRegionalParameterConfig>
              (CONSTANTS.regionalParameter);
        let useSystemTimeZoneValue: boolean = regionalParameter?.UseSystemTimeZone ?
            (regionalParameter.UseSystemTimeZone.Value.toLowerCase() == "true" ? true : false) : false;
        if (regionalParameter && !useSystemTimeZoneValue) {
            return offset = parseFloat(regionalParameter.Offset.Value);
        } else {
            let timeZoneOffset: number = (new Date().getTimezoneOffset()) / 60;
            if (timeZoneOffset > 0) {
                return offset = (timeZoneOffset * -1);
            } else if (timeZoneOffset < 0) {
                return offset = (Math.abs(timeZoneOffset));
            } else {
                return offset = timeZoneOffset;
            }
        }
      } catch (error) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getTimeZoneOffset() - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getTimeZoneOffset() - output(${JSON.stringify({offset})}) ended` : '', MessageType.Info);
      }
    },

    // Gets the billable override value
    async getAllowBillableOverrideValue(clientId: string, matterId: string, timekeeperId: string) {
      let billableOverrideValue: boolean = false;
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getAllowBillableOverrideValue(${JSON.stringify({clientId, matterId, timekeeperId})}) started` : '', MessageType.Info);
        
        let isLocalRuleDefined: boolean;
        let data = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config);
        isLocalRuleDefined = data?.IsLocalRuleDefined === "1" ? true : false;
        if (isLocalRuleDefined && (clientId || matterId || timekeeperId)) {
          let billableInfo = await this.getAllowBillableOverrideInfo(clientId, matterId, timekeeperId);
          billableOverrideValue = billableInfo;
          return (billableOverrideValue);
        } else {
          let data1 = ConfigService.getConfigurationData<IMiscellaneousConfig>(CONSTANTS.miscellaneous);
          billableOverrideValue = data1?.AllowBillableOverride === "0" ? false : true;
          return (billableOverrideValue);
        }
      } catch (error) { 
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getAllowBillableOverrideValue(${JSON.stringify({clientId, matterId, timekeeperId})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getAllowBillableOverrideValue(${JSON.stringify({clientId, matterId, timekeeperId})}) - output(${JSON.stringify({billableOverrideValue})}) ended` : '', MessageType.Info);
      }
    },

    // Gets the billable override value
    async  getAllowBillableOverrideInfo(clientId: string, matterId: string, timekeeperId: string): Promise<boolean> {
      let billableDataInfo: boolean = false;
      try {
          LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getAllowBillableOverrideInfo(${JSON.stringify({clientId, matterId, timekeeperId})}) started` : '', MessageType.Info);
          billableDataInfo = await DataService.fetchData<boolean>((ApiUrlConstant.timeEntry.getLocalAllowBillableOverrideInfo).replace("{0}", clientId).replace("{1}", matterId).replace("{2}", timekeeperId));
          return (billableDataInfo);
      } catch (error) {
          LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getAllowBillableOverrideInfo(${JSON.stringify({clientId, matterId, timekeeperId})}) - ${error}` : '', MessageType.Error);
          throw error;
      } finally {
          LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getAllowBillableOverrideInfo(${JSON.stringify({clientId, matterId, timekeeperId})}) - output(${JSON.stringify({billableDataInfo})}) ended` : '', MessageType.Info);
      }
    },

    // Creates an empty time entry
    createEmptyTimeEntry(tkprId: string, userId : string, sourceOfEntry: string): TimeEntry { 
      let timeEntry: TimeEntry = {};
      try {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - createEmptyTimeEntry(${JSON.stringify({tkprId, userId, sourceOfEntry})}) started` : '', MessageType.Info);
        timeEntry = {
          timekeeperId: tkprId,
          timeId: "",
          clientId: "",
          projectId: "",
          clientName: "",
          projectName: "",  
          status: EntryStatuses.Incomplete.toString(),
          rawTime: 0,
          otherTime: 0,
          roundTime: 0,
          roundTimeDisplayValue: "0",
      
          dateCreated: TimerService.convertToString(TimerService.getDateTime()),
          userCreated: userId,
          userEdited: userId,
          dateEdited: TimerService.convertToString(TimerService.getDateTime()),
          originalSource: sourceOfEntry,
          source: sourceOfEntry,
          dateWorked: moment().format("YYYY-MM-DDT00:00:00"),
          billable: "",
          billableOverriden: "0",
          comments: "",
          dateLastModified: TimerService.convertToString(TimerService.getDateTime()),
          narrativeForSheetview: "",
          spellChecked: 0,
          nickname: "",
          startStopSequences: [],
          localValidationRules: [],
          userCodes: {},
          remainingRawTime: 0,
          remainingRoundTime: 0
        };
      
        return timeEntry;
      } catch (error) {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - createEmptyTimeEntry(${JSON.stringify({tkprId, userId, sourceOfEntry})}) - ${error}` : '', MessageType.Error);
        throw error;
      } finally {
        LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - createEmptyTimeEntry(${JSON.stringify({tkprId, userId, sourceOfEntry})}) - output(${JSON.stringify({timeEntry})}) ended` : '', MessageType.Info);
      }
    },

    // This method will get the rounding and billable value
    async getRoundingAndBillableValue(requestObj : IRoundingBillableRequest) : Promise<IRoundingBillableResponse> { 
      let response: IRoundingBillableResponse = {};
      try {
          LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getRoundingAndBillableValue(${JSON.stringify({requestObj})}) started` : '', MessageType.Info);
          response = await DataService.postData<IRoundingBillableResponse>(ApiUrlConstant.validationData.getRoundingAndBillableValue, requestObj);
          return response;
      } catch (error) { 
          LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getRoundingAndBillableValue(${JSON.stringify({requestObj})}) - ${error}` : '', MessageType.Error);
          throw error;
      } finally {
          LoggingService.log(LoggingService.isLoggingEnabled() ?  `TimeEntryService - getRoundingAndBillableValue(${JSON.stringify({requestObj})}) - output(${JSON.stringify({response})}) ended` : '', MessageType.Info);
      }
  },    
}

export default TimeEntryService;
