import { Button, ComboboxProps, Field, GriffelStyle, InputProps, Label, MessageBar, MessageBarActions, MessageBarBody, MessageBarGroup, MessageBarIntent, MessageBarTitle, Popover, PopoverProps, PopoverSurface, PopoverTrigger, Textarea, TextareaProps, makeStyles, shorthands } from "@fluentui/react-components";
import { useContext, useEffect, useId, useRef, useState } from "react";
import { ICardEntry } from "../models/ICardEntry";
import ConfigService from "../services/configService";
import { ISpellCheck } from "../models/ISpellCheck";
import { IUserDictionary } from "../models/IUserDictionary";
import SpellCheckService from "../services/spellCheckService";
import { CONSTANTS } from "../constants/constants";
import { INarrativeWordIndex } from "../models/INarrativeWordIndex";
import { ITlxFirmDictionaryEntity } from "../models/ITlxFirmDictionaryEntity";
import { IIniConfig } from "../models/IIniConfig";
import { DismissRegular } from "@fluentui/react-icons";
import { IUserPreferences } from "../models/IUserPreferences";
import { IDictionary } from "../models/IDictionary";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { TeamsFxContext } from "../internal/context";

interface NarrativeProps extends Partial<ComboboxProps>{
    cardEntry: ICardEntry;
    trigger: boolean,
    onNarrativeChange: (narrative: string, spellChecked: number) => void;
    showErrorMessage: (message: string) => void;
    showSuccessMessage: (message: string) => void;
}

interface INarrativeArray {
    rightPhrase?: string;
    wrongWord?: string;
    wrongWordId?: number;
    wordType?: string;
}

const useStyles = makeStyles({
    darkSpellCheck: {
        backgroundColor: 'rgb(51, 51, 51)',
    },
    lightSpellCheck: {
        backgroundColor: 'f4f4f4',
    },
    spellCheck: {
      marginBottom: '10px',
      ...shorthands.padding('10px'),
      ...shorthands.borderRadius('10px'),
    },
    errorBorder: {
        ...shorthands.border('0.5px', 'solid', 'f78a8a'),
    },
    container: {
        "> div": { display: "initial" },
    },
    misspelled_word: {
        color: 'red',
    },
    double_word: {
        color: 'red',
    },
    suggestion_word: {
        color: 'blue',
    },
    contentHeader: {
        marginTop: "0",
    },
    textArea: {
        height: '15em'
    },
    spellCheckButton: {
        marginTop: "20",
    },
    m5: {
        ...shorthands.margin('5px'),
    }
  });
  
export default function Narrative(props : NarrativeProps) { 

    // Get the theme string from the context
    const { themeString } = useContext(TeamsFxContext);

    // Is the theme dark or contrast
    const [isDarkTheme] = useState(themeString === "dark" || themeString === "contrast");

    // This will be used to disable the text area if the card entry is in view only mode
    const [viewOnly] = useState(props.cardEntry.timeId ? true : false)

    const styles = useStyles();

    // This will hold the narrative value
    const [narrative, setNarrative] = useState<string>("");
    const narrativeText = useRef<string>("");

    // This will hold the value which indicates whether the text area should be shown or not
    const [showTextArea, setShowTextArea] = useState<boolean>(true);

    // This will hold the value which indicates whether the error border should be shown or not
    const [showErroBorder, setShowErrorBorder] = useState<boolean>(false);

    // This array hold the value of the misspelled words
    const misspelledWords = useRef<string[]>([]);

    // This array will hold the value of the suggestion words
    const suggestionWords = useRef<string[]>([]);

    // This array will hold the value of the ignore words list
    const ignoreWordsList = useRef<string[]>([]);

    // This will hold the value of the user spell check preferences
    const userSpellCheckPreferences = useRef<IUserPreferences | undefined>(undefined);

    // This will hold the value of the global narrative array
    const [globalNarrativeArray, setGlobalNarrativeArray] = useState<INarrativeArray[]>([]);

    // This will hold the value of the suggestion list which will be shown in the popover
    const [suggestionList, setSuggestionList] = useState<string[]>([]);

    // This will hold the value of the admin dictionary data
    const adminDicData = useRef<IDictionary<ITlxFirmDictionaryEntity[]>>({});

    // This will hold the value of the suggestion words dictionary
    const suggestionWordsDic = useRef<IDictionary<string>>({});

    // This will hold the value of the replacement words dictionary
    const replacementWordsDic = useRef<IDictionary<string>>({});

    // This will hold the value of the ignore all words check
    const ignoreAllWordsCheck = useRef<boolean>(false);

    // This will hold the value of the default language
    const defaultLanguage = useRef<string>("");

    // This will hold the value of the spell checked
    const spellChecked = useRef<number>(0);

    // This will hold the value of the disableBrowserSpellCheck (in built browser spell check)
    const [disableBrowserSpellCheck, setDisableBrowserSpellCheck] = useState<boolean>(false);

    // This will hold the value of the selected word
    const selectedWord = useRef<string>("");

    // This will hold the value of the selected word id
    const selectedWordId = useRef<number>(0);

    // This will hold the value of the selected word type
    const selectedWordType = useRef<string>("");

    const id = useId();

    // This will hold the reference of the narrative template
    const narrativeTemplateRef = useRef<HTMLDivElement | null>(null);

    // This will hold the reference of the do nothing div which will be used to focus out
    const doNothingDivRef = useRef<HTMLDivElement | null>(null);

    // This will be called only once when the component is mounted
    useEffect(() => {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - useEffect() started` : '', MessageType.Info);
        let currValue = props.cardEntry.narrative ?? "";
        narrativeText.current = currValue;
        setNarrative(narrativeText.current);
        

        let config = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config);
        if (config?.DisableBrowserSpellCheckonNarrativeField === "1") {
            setDisableBrowserSpellCheck(true);
        }

        spellChecked.current = props.cardEntry.spellChecked ?? 0;
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - useEffect() ended` : '', MessageType.Info);
    }, []);

    // This method will be called when the narrative value changes
    useEffect(() => {
        let narrative = props.cardEntry.narrative ?? "";
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - useEffect() for narrative started. Dependency array - ${JSON.stringify({narrative})}` : '', MessageType.Info);
        narrativeText.current = props.cardEntry.narrative ?? "";
        setNarrative(narrativeText.current);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - useEffect() for narrative ended. Dependency array - ${JSON.stringify({narrative})}` : '', MessageType.Info);
    }, [props.cardEntry.narrative]);


    // This method will be called when the trigger value changes (when we save/submit the time entry)
    // This will be used to reset the narrative field
    // This will be triggereed when we save/submit the time entry
    useEffect(() => {
        let trigger = props.trigger;
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - useEffect() for trigger started. Dependency array - ${JSON.stringify({trigger})}` : '', MessageType.Info);
        setGlobalNarrativeArray([]);
        setShowTextArea(true);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - useEffect() for trigger ended. Dependency array - ${JSON.stringify({trigger})}` : '', MessageType.Info);
    }, [props.trigger]);

    // This function will be called when the user types in the narrative field
    const onNarrativeChange: TextareaProps["onChange"] = (ev, data) => {
        let val = data.value;
        narrativeText.current = val;
        setNarrative(narrativeText.current);
        spellChecked.current = 0;
    }
    
    // This method will be called when the user leaves the narrative field
    const onBlur = (): void => {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - onBlur() started` : '', MessageType.Info);
        props.onNarrativeChange(narrativeText.current, spellChecked.current);
        LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - onBlur() ended` : '', MessageType.Info);
    }

    // This method will be called when the user clicks on the spell check button
    function doSpellCheck(): void {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - doSpellCheck() started` : '', MessageType.Info);
            ignoreAllWordsCheck.current = false;
            if (showTextArea) {
                getMisspelledWords();
            } else {
                let currNarrative = extractText();
                setGlobalNarrativeArray([]);
                setShowTextArea(true);
                setTimeout(() => {
                    narrativeText.current = currNarrative;
                    props.onNarrativeChange(currNarrative, spellChecked.current);
                }, 500);
            }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - doSpellCheck() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - doSpellCheck() ended` : '', MessageType.Info);
        }
    }


    // This method will be called when the user clicks on the spell check button
    async function getMisspelledWords() { 
        let result = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getMisspelledWords() started` : '', MessageType.Info);
            if (!defaultLanguage.current) {
                await setDefaultLanguage();
            }
    
            let matterDetails = props.cardEntry.matterObj;
            let matterLanguage = matterDetails && matterDetails.userAddedFields && matterDetails.userAddedFields.Language ? matterDetails.userAddedFields.Language : "";
            let userPref = ConfigService.getUserConfigurationData<ISpellCheck>(CONSTANTS.spellCheck);
            userSpellCheckPreferences.current = {
                ignoreAllCaps: (userPref?.IgnoreAllCaps?.Value === "true"),
                ignoreCappedWords: (userPref?.IgnoreCappedWords?.Value === "true"),
                ignoreDomainName: (userPref?.IgnoreDomainName?.Value === "true"),
                ignoreDoubleWord: (userPref?.IgnoreDoubleWord?.Value === "true"),
                ignoreMixedCase: (userPref?.IgnoreMixedCase?.Value === "true"),
                ignoreMixedDigits: (userPref?.IgnoreMixedDigits?.Value === "true"),
                spellCheckAddEdit: (userPref?.SpellCheckAddEdit?.Value === "true"),
                userSelectedLanguage: defaultLanguage.current
              };
    
            let incorrectWords = await SpellCheckService.getMisspelledWords({
                text: narrativeText.current, matterLang: matterLanguage,
                defaultLang: defaultLanguage.current, userSelectedLanguage: defaultLanguage.current, userSpellCheckPreferences: userSpellCheckPreferences.current
            });
    
            misspelledWords.current = incorrectWords;
            let doubleWordPresent = false;
    
            if (misspelledWords.current.length === 0) {
                if (userSpellCheckPreferences.current.ignoreDoubleWord === false) {
                    let splitArray = narrativeText.current.split(/\s/);
                    let currentWord, lastWord = "";
                    for (let k = 0; k < splitArray.length; k++) { 
                        currentWord = splitArray[k];
                        if (
                            (lastWord !== "") && (lastWord.toLowerCase() === currentWord.toLowerCase()) &&
                            (misspelledWords.current.indexOf(currentWord) === -1) &&
                            (suggestionWords.current.indexOf(currentWord) === -1) &&
                            (ignoreWordsList.current.indexOf(currentWord) === -1)
                        ) {
                            doubleWordPresent = true;
                            break;
                        }
    
                        lastWord = currentWord;
                    }
                }
            }
    
            if (misspelledWords.current.length === 0 && doubleWordPresent === false) {
                // spell check performed and narrative is valid
                spellChecked.current = 1;
                proccessNarrative()
                return result = false; // spell check not required
            } else if (spellChecked.current === 0) {
                setShowErrorBorder(true);
                props.showErrorMessage("Manual Spell Check Required");
                proccessNarrative();
                return result = true;
            } else if (misspelledWords.current.length > 0 && spellChecked.current === 1) {
                props.showSuccessMessage("Spell Check Completed");
                return result = false;
            }
    
            return result = false;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getMisspelledWords() - ${error}` : '', MessageType.Error);
            return result = false;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getMisspelledWords() - output(${JSON.stringify(result)}) ended` : '', MessageType.Info);
        }
    }


    // This method is used to process the narrative
    function proccessNarrative() {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - proccessNarrative() started` : '', MessageType.Info);
            let narrativeWordIndexArray = createNarrativeWordIndexArray();
            spellChecked.current = narrativeWordIndexArray.findIndex(item => item.word !== "") == -1 ? 1 : 0;
            if (spellChecked.current === 1) {
                setShowErrorBorder(false);
                props.showSuccessMessage("Spell Check Completed");
            }
            let startIndex = 0, endIndex = 0, pointer = 0;
            let tempArray: INarrativeArray[] = [];
            for (let i = 0; i < narrativeWordIndexArray.length; i++) {
                startIndex = narrativeWordIndexArray[i].index;
                endIndex = startIndex + narrativeWordIndexArray[i].word.length;
                tempArray.push({
                    rightPhrase: narrativeText.current.slice(pointer, startIndex),
                    wrongWord: narrativeText.current.slice(startIndex, endIndex),
                    wrongWordId: i,
                    wordType: narrativeWordIndexArray[i].type
                });
                pointer = endIndex;
            }
    
            tempArray.push({ rightPhrase: narrativeText.current.slice(pointer) });
            setGlobalNarrativeArray(tempArray);
            setShowTextArea(false);
            setTimeout(() => {
                let currNarrative = extractText();
                narrativeText.current = currNarrative;
                props.onNarrativeChange(currNarrative, spellChecked.current);
                
            }, 500);
        }  catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - proccessNarrative() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - proccessNarrative() ended` : '', MessageType.Info);
        }
    }


    // This method is used to create the narrative word index array
    function createNarrativeWordIndexArray() : INarrativeWordIndex[] {
        let narrativeWordIndex: INarrativeWordIndex[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - createNarrativeWordIndexArray() started` : '', MessageType.Info);
            
            let splitArray: string[] = [];
            for (let m = 0; m < misspelledWords.current.length; m++) { 
                if (ignoreWordsList.current.indexOf(misspelledWords.current[m]) === -1) {
                    splitArray = narrativeText.current
                      .split(new RegExp("\\b(" + misspelledWords.current[m] + ")(?!['\\w])"))
                      .filter(substr => substr !== misspelledWords.current[m]);
                    let previousWordIndex = 0;
                    for (let i = 0; i < (splitArray.length - 1); i++) {
                      narrativeWordIndex.push({
                        word: misspelledWords.current[m], index: previousWordIndex + splitArray[i].length,
                        type: "misspelled_word"
                      });
                      previousWordIndex = previousWordIndex + splitArray[i].length + misspelledWords.current[m].length;
                    }
                }
            }
    
            for (let s = 0; s < suggestionWords.current.length; s++) {
                if (ignoreWordsList.current.indexOf(suggestionWords.current[s]) === -1) {
                  splitArray = narrativeText.current
                    .split(new RegExp("\\b(" + suggestionWords.current[s] + ")\\b"))
                    .filter(substr => substr !== suggestionWords.current[s]);
                  let previousWordIndex = 0;
                  for (let j = 0; j < (splitArray.length - 1); j++) {
                    narrativeWordIndex.push({
                      word: suggestionWords.current[s], index: previousWordIndex + splitArray[j].length,
                      type: "suggestion_word"
                    });
                    previousWordIndex = previousWordIndex + splitArray[j].length + suggestionWords.current[s].length;
                  }
                }
            }
    
            if (userSpellCheckPreferences.current && userSpellCheckPreferences.current.ignoreDoubleWord === false) {
                // parsing the textarea value for any double words
                splitArray = narrativeText.current.replace(/[^a-z A-Z 0-9]/g, "").split(/\s/);
                let arrayToCalculateIndex = narrativeText.current.split(/\s/);
                let currentWord, lastWord = "";
                for (let k = 0; k < splitArray.length; k++) {
                  currentWord = splitArray[k];
                  // previous word should not be undefined and equals to current word and
                  // not present in the misspelled words list
                  if ((lastWord !== "") && (lastWord.toLowerCase() === currentWord.toLowerCase()) &&
                    (misspelledWords.current.indexOf(currentWord) === -1) &&
                    (suggestionWords.current.indexOf(currentWord) === -1) &&
                    (ignoreWordsList.current.indexOf(currentWord) === -1)
                  ) {
                    narrativeWordIndex.push({
                      word: currentWord, index: calculateIndex(arrayToCalculateIndex, k),
                      type: "double_word"
                    });
                  }
                  if (currentWord !== "") {
                    lastWord = currentWord;
                  }
                }
            }
    
            return narrativeWordIndex.sort((n1, n2) => (n1.index ?? 0) - (n2.index ?? 0));
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - createNarrativeWordIndexArray() - ${error}` : '', MessageType.Error);
            return narrativeWordIndex = [];
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - createNarrativeWordIndexArray() - output(${narrativeWordIndex}) ended` : '', MessageType.Info);
        }
    }


    // This method is used to calculate the index of the word
    function calculateIndex(narrativeWordsArray: string[], wordPosition: number) {
        let index = 0;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - calculateIndex(${JSON.stringify({narrativeWordsArray, wordPosition})}) started` : '', MessageType.Info);
            let pointer = 0;
            while (pointer < wordPosition) {
                index = index + narrativeWordsArray[pointer].length + 1;
                pointer++;
            }
        
            return index;
        } catch (error) {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - calculateIndex(${JSON.stringify({narrativeWordsArray, wordPosition})}) - ${error}` : '', MessageType.Error);
          return index;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - calculateIndex(${JSON.stringify({narrativeWordsArray, wordPosition})}) - output(${index}) ended` : '', MessageType.Info);
        }
    }


    // This method is used to get the suggestions
    async function getSuggestions(wordType: string, word: string, wordId: number) {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getSuggestions(${JSON.stringify({wordType, word, wordId})}) started` : '', MessageType.Info);
            selectedWord.current = word;
            selectedWordId.current = wordId;
            selectedWordType.current = wordType;
            switch (wordType) {
                case "misspelled_word":
                    let matterDetails = props.cardEntry.matterObj;
                    let matterLanguage = matterDetails && matterDetails.userAddedFields && matterDetails.userAddedFields.Language ? matterDetails.userAddedFields.Language : "";
                    let data = await SpellCheckService.getSuggestions(word, matterLanguage, defaultLanguage.current, defaultLanguage.current);
                    setSuggestionList(data);
                break;
                case "double_word":
                    setSuggestionList([]);
                break;
                case "suggestion_word":
                let res = getSuggestionsFromAdminDic(word);
                setSuggestionList(res);
            }
        } catch (error) {
          LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getSuggestions(${JSON.stringify({wordType, word, wordId})}) - ${error}` : '', MessageType.Error);  
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getSuggestions(${JSON.stringify({wordType, word, wordId})}) ended` : '', MessageType.Info);
        }
    }


    // function to get suggestions from admin dictionary
    function getSuggestionsFromAdminDic(word: string): string[] {
        let suggestionList: string[] = [];
        try {
            if (suggestionWordsDic.current[word]) {
                suggestionList = suggestionWordsDic.current[word].split(",");
            }
    
            return suggestionList;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getSuggestionsFromAdminDic(${JSON.stringify({word})}) - ${error}` : '', MessageType.Error);
            return suggestionList = [];
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getSuggestionsFromAdminDic(${JSON.stringify({word})}) - output(${JSON.stringify(suggestionList)}) ended` : '', MessageType.Info);
        }
    }


    // function to set default language
    async function setDefaultLanguage() { 
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - setDefaultLanguage() started` : '', MessageType.Info);
            let obj = ConfigService.getUserConfigurationData<IUserDictionary>(CONSTANTS.userDictionary);
            defaultLanguage.current = obj?.SelectedLanguage.Value ?? "en-GB";
            await getCustomSuggestions();
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - setDefaultLanguage() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - setDefaultLanguage() ended` : '', MessageType.Info);
        }
    }


    // function to get custom suggestions
    async function getCustomSuggestions() { 
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getCustomSuggestions() started` : '', MessageType.Info);
            let matterDetails = props.cardEntry.matterObj;
            let matterLanguage = matterDetails && matterDetails.userAddedFields && matterDetails.userAddedFields.Language ? matterDetails.userAddedFields.Language : "";
            let language = matterLanguage || defaultLanguage.current;
            let data: ITlxFirmDictionaryEntity[];
    
            if (!adminDicData.current[language]) {
                data = await SpellCheckService.getCustomSuggestions(language, defaultLanguage.current, defaultLanguage.current);
                adminDicData.current[language] = data;
            } else { 
                data = adminDicData.current[language];
            }
    
            if (data) {
                data.forEach((value) => { // filling suggetions and auto replace words in different array
                  if (value.type == CONSTANTS.autoReplace) {
                    replacementWordsDic.current[value.phrase] = value.value;
                  } else {
                    suggestionWordsDic.current[value.phrase] = suggestionWordsDic.current[value.phrase] ?
                      suggestionWordsDic.current[value.phrase].concat(",", value.value) : value.value;
                    if (suggestionWords.current.indexOf(value.phrase) === -1) {
                      suggestionWords.current.push(value.phrase);
                    }
                  }
                });
            }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getCustomSuggestions() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - getCustomSuggestions() ended` : '', MessageType.Info);
        }
    }


    // function to handle suggestion selected
    function suggestionSelected (word: string) {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - suggestionSelected(${JSON.stringify({word})}) started` : '', MessageType.Info);
            if (doNothingDivRef.current) {
                doNothingDivRef.current.click();
            }
    
            let tempNarrativeArray = globalNarrativeArray.map(item => item);
            let index = tempNarrativeArray.findIndex(item => item.wrongWordId === selectedWordId.current);
            tempNarrativeArray[index] = {
                rightPhrase: tempNarrativeArray[index].rightPhrase + word,
                wrongWord: "",
                wrongWordId: -2
            };
            setGlobalNarrativeArray(tempNarrativeArray);
            //setOpen(false);
            setTimeout(() => {
                let currNarrative = extractText();
                props.onNarrativeChange(currNarrative, spellChecked.current);
                narrativeText.current = currNarrative;
                if (!narrativeText.current.match(new RegExp("\\b(" + selectedWord.current + ")\\b"))) {
                    misspelledWords.current.splice(misspelledWords.current.indexOf(selectedWord.current), 1);
                }
                ignoreAllWordsCheck.current = true;
                proccessNarrative();  
            }, 500);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - suggestionSelected(${JSON.stringify({word})}) - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - suggestionSelected(${JSON.stringify({word})}) ended` : '', MessageType.Info);
        }
    }


    // function to ignore all
    function ignoreAll() {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - ignoreAll() started` : '', MessageType.Info);
            if (doNothingDivRef.current) {
                doNothingDivRef.current.click();
            }
            if (ignoreWordsList.current.indexOf(selectedWord.current) === -1) {
                ignoreWordsList.current.push(selectedWord.current);
                ignoreAllWordsCheck.current = true;
                proccessNarrative();
            }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - ignoreAll() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - ignoreAll() ended` : '', MessageType.Info);
        }
    }


    // function to add to dictionary
    async function addToDictionary() {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - addToDictionary() started` : '', MessageType.Info);
            if (doNothingDivRef.current) {
                doNothingDivRef.current.click();
            }
          // get user dictionary
          let userDictionary = await SpellCheckService.getUserDictionary();
          userDictionary = userDictionary && userDictionary.length > 0 ? userDictionary : [];
          userDictionary.push(selectedWord.current);
          ignoreWordsList.current.push(selectedWord.current);
          // save to database
          let response = await SpellCheckService.saveDictionary(userDictionary);
          if (response) {
            // save to indexed db
            await SpellCheckService.saveUserDictionaryConfigurationData(userDictionary, defaultLanguage.current);
            ignoreAllWordsCheck.current = true;
            proccessNarrative();
          }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - addToDictionary() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - addToDictionary() ended` : '', MessageType.Info);
        }
    }


    // function to delete selected word
    function deleteSelectedWord() { 
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - deleteSelectedWord() started` : '', MessageType.Info);
            if (doNothingDivRef.current) {
                doNothingDivRef.current.click();
            }
    
            setGlobalNarrativeArray(prevArray => {
                const newArray = [...prevArray]; // Copy the previous array to avoid mutating state directly
                const narrativeArrayIndex = newArray.findIndex(item => item.wrongWordId === selectedWordId.current);
                if (narrativeArrayIndex !== -1) { // Check if item is found
                    newArray[narrativeArrayIndex] = { rightPhrase: newArray[narrativeArrayIndex].rightPhrase?.trimRight() };
                }
    
                return newArray; // Return the updated array
            });
    
            setTimeout(() => {
                let currNarrative = extractText();
                narrativeText.current = currNarrative;
                props.onNarrativeChange(currNarrative, spellChecked.current);
            }, 500);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - deleteSelectedWord() - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - deleteSelectedWord() ended` : '', MessageType.Info);
        }
    }

    // function to extract text
    function extractText() {
        let text = "";
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - extractText() started` : '', MessageType.Info);
            if (narrativeTemplateRef.current) {
                text = narrativeTemplateRef.current.textContent ?? "";
                return text;
            } else {
                return text;
            }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - extractText() - ${error}` : '', MessageType.Error);
            return text = "";
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `Narrative - extractText() - output(${JSON.stringify(text)}) ended` : '', MessageType.Info);
        }
    };

    return (
        <div>
            <div ref={doNothingDivRef}></div>
            <Label className="title">Narrative</Label>
            {
                showTextArea ? 
                    <Field size="medium">
                        <Textarea disabled={viewOnly} className={styles.textArea} placeholder="Type here..." value={narrative} onChange={onNarrativeChange} onBlur={onBlur} spellCheck={!disableBrowserSpellCheck}/>
                    </Field> 
                    : 
                    <Field size="medium">
                        <div ref={narrativeTemplateRef} onDoubleClick={() => doSpellCheck()} >
                            <div className={`${styles.container} ${styles.textArea}`} >
                            <div className={`${styles.spellCheck} ${showErroBorder ? styles.errorBorder : ''} ${isDarkTheme ? styles.darkSpellCheck : styles.lightSpellCheck}`} style={{overflowY: 'auto', overflowX: 'auto'}}>
                                    {globalNarrativeArray.map((item, index) => (
                                        <span style={{ whiteSpace: 'pre-wrap' }} key={index}>
                                            {item.rightPhrase}
                                                <Popover mouseLeaveDelay={1000} withArrow>
                                                    <PopoverTrigger disableButtonEnhancement>
                                                        <span>
                                                            {item.wrongWord && (
                                                                <span className={styles[item.wordType as keyof typeof styles]} onClick={async () => await getSuggestions(item.wordType ?? "", item.wrongWord ?? "", item.wrongWordId ?? 0)}>
                                                                    {item.wrongWord}
                                                                </span>
                                                            )}
                                                        </span>
                                                    </PopoverTrigger>
                                                    <PopoverSurface aria-labelledby={id}>
                                                        {<span>
                                                            {suggestionList.map((word, index) => (
                                                                <Button className={styles.m5} key={index} onClick={() => suggestionSelected(word)}>
                                                                    {word}
                                                                </Button>
                                                            ))}
                                                        </span>}
                                                        <div>
                                                            <Button appearance="primary" className={styles.m5} onClick={ignoreAll}>Ignore All</Button>
                                                            {selectedWordType.current === 'misspelled_word' ? <Button appearance="primary" className={styles.m5} onClick={addToDictionary}>Add to dictionary</Button> : null}
                                                            {selectedWordType.current === 'double_word' ? <Button appearance="primary" className={styles.m5} onClick={deleteSelectedWord}>Delete repeated word</Button> : null}
                                                        </div>
                                                    </PopoverSurface>
                                                </Popover>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Field>
            }

            <Button disabled={viewOnly} className={styles.spellCheckButton} onClick={doSpellCheck}>{showTextArea ? 'Spell Check' : 'Edit'}</Button>
        </div>
    )
}