import moment from "moment";
import { ApiUrlConstant } from "../constants/api-url-constants";
import { IIniConfig } from "../models/IIniConfig";
import { IBarrierConfig } from "../models/IBarrierConfig";
import { IClientConfig } from "../models/IClientConfig";
import { IStatusList } from "../models/IStatusList";
import { IUserCalendarsConfig } from "../models/IUserCalendarsConfig";
import { IValidationResponse } from "../models/IValidationResponse";
import { ICardEntry } from "../models/ICardEntry";
import { IClient } from "../models/IClient";
import ConfigService from "./configService";
import DataService from "./dataService";
import TimerService from "./timerService";
import { CONSTANTS } from "../constants/constants";
import ValidationDataService from "./validationDataService";
import SoftTextService from "./softTextService";
import { ILookUpData } from "../models/ILookUpData";
import { IServiceResponse } from "../models/IServiceResponse";
import { ValidationDataStatus } from "../enums/validationDataStatus";
import LoggingService from "./loggingService";
import { MessageType } from "../enums/messageType";

const ClientService = {

    // Method to validate the client and get the client details
    async validateClient(clientId: string, timekeeperId: string, dateWorked: string, entry: ICardEntry, isValidated: boolean = false) : Promise<IValidationResponse<IClient>> {
        let validationResponse: IValidationResponse<IClient> = { status: false };
        try {
                LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClient(${JSON.stringify({clientId, timekeeperId, dateWorked, entry, isValidated})}) started` : '', MessageType.Info);
                let clientSoftText = SoftTextService.getSoftTextValue(CONSTANTS.client);

                // Is the datatype proper?
                let isNumeric = this.isNumeric();
                if (isNumeric && isNaN(+clientId)) {
                    return validationResponse = {
                        status: false,
                        notificationMessage: `Invalid ${clientSoftText}.`
                    }
                }

                let barrierModes = ConfigService.getConfigurationData<IBarrierConfig>(CONSTANTS.barrierModes);

                // isValidated will be false when we tab out from dropdown, else it will always be true
                if (barrierModes?.Mode === CONSTANTS.advancedBarrier && !isValidated) {
                    // Is the client walled?
                    let walledServiceResponse = await this.validateIfClientIdsUnaccessibleForUser([clientId]);
                    if (walledServiceResponse.status) {
                        // It is not walled
                        return validationResponse = await this.validateClientAfterWalledCheck(clientId, timekeeperId, dateWorked, entry);
                    } else { 
                        return validationResponse = {
                            status: false,
                            notificationMessage: barrierModes.NotificationMessage || `Invalid ${clientSoftText}.`
                        };
                    }
                } else {
                    return validationResponse = await this.validateClientAfterWalledCheck(clientId, timekeeperId, dateWorked, entry);
                }
            } catch (error) {
                LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClient(${JSON.stringify({clientId, timekeeperId, dateWorked, entry, isValidated})}) - ${error}` : '', MessageType.Error); 
                throw error;
            } finally {
                LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClient(${JSON.stringify({clientId, timekeeperId, dateWorked, entry, isValidated})}) - output(${JSON.stringify({validationResponse})}) ended` : '', MessageType.Info);
            }
    },


    // Method to check if the datatype of matter is numeric
    isNumeric() {
        let isNumeric: boolean = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - isNumeric() started` : '', MessageType.Info);
            let config = ConfigService.getConfigurationData<IClientConfig>(CONSTANTS.client);
            let idField = config?.Fields?.Field.find(f => f.Name === CONSTANTS.clientId);
            return isNumeric = ValidationDataService.isNumericDatatype(idField?.FieldType ?? "");
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - isNumeric() - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - isNumeric() - output(${JSON.stringify({isNumeric})}) ended` : '', MessageType.Info);
        }
    },


    // Method to fetch all the clients to show in the dropdown
    async getClients(startIndex: number, length: number, searchString: string) : Promise<ILookUpData[]> {
        let clients: ILookUpData[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - getClients(${JSON.stringify({startIndex, length, searchString})}) started` : '', MessageType.Info);
            clients = await DataService.fetchData<ILookUpData[]>(ApiUrlConstant.client.getClients + `?start=${startIndex}&len=${length}&q=${encodeURIComponent(searchString)}`);
            return clients;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - getClients(${JSON.stringify({startIndex, length, searchString})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - getClients(${JSON.stringify({startIndex, length, searchString})}) - output(${JSON.stringify(clients)}) ended` : '', MessageType.Info);
        }  
    },


    // Method to fetch the details of a specific client
    async getClientDetails(clientId: string, timekeeperId: string) : Promise<IClient[]>{
        let clientDetails: IClient[] = [];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - getClientDetails(${JSON.stringify({clientId, timekeeperId})}) started` : '', MessageType.Info);
            let matterStyle = ConfigService.getConfigurationData<IIniConfig>(CONSTANTS.config)?.MatterStyle;
            let configValues = {
                MatterStyle: matterStyle
            };
    
            let isAllowTempData : boolean = false;
    
            clientDetails = await DataService.postData(`${ApiUrlConstant.client.getClientDetails}/${timekeeperId}/${isAllowTempData}`, {
                clientIds: [clientId],
                tkprId: timekeeperId,
                isAllowTempData: isAllowTempData,
                configValues: configValues
            });
    
            return clientDetails;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - getClientDetails(${JSON.stringify({clientId, timekeeperId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - getClientDetails(${JSON.stringify({clientId, timekeeperId})}) - output(${JSON.stringify({clientDetails})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate the client after the walled check is done
    async validateClientAfterWalledCheck (clientId: string, timekeeperId: string, dateWorked: string, entry: ICardEntry) { 
        let validationResponse: IValidationResponse<IClient> = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClientAfterWalledCheck(${JSON.stringify({clientId, timekeeperId, dateWorked, entry})}) started` : '', MessageType.Info);
            let clientSoftText = SoftTextService.getSoftTextValue(CONSTANTS.client);
            let clientDetailsObj: IClient;
            clientDetailsObj = (entry && clientId && entry.clientObj && entry.clientObj.clientId &&
                entry.clientObj.clientId == clientId) ? entry.clientObj
                : await this.validateClientIdAndGetClientDetails(clientId, timekeeperId);
    
            if (clientDetailsObj) {
                let clientConfig = ConfigService.getConfigurationData<IClientConfig>(CONSTANTS.client);
                validationResponse = await this.validateStatus(clientDetailsObj, clientDetailsObj.status ?? "", clientConfig?.Statuses ?? {}, clientDetailsObj.clientId ?? "", timekeeperId, dateWorked);
                if (validationResponse.status) {
                    validationResponse.entity = clientDetailsObj;
                }
            } else {
                validationResponse = {
                    status: false,
                    notificationMessage: `Invalid ${clientSoftText}.`
                };
            }
    
            return validationResponse;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClientAfterWalledCheck(${JSON.stringify({clientId, timekeeperId, dateWorked, entry})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClientAfterWalledCheck(${JSON.stringify({clientId, timekeeperId, dateWorked, entry})}) - output(${JSON.stringify({validationResponse})}) ended` : '', MessageType.Info);
        }
    },


    // Method to get the client details
    async validateClientIdAndGetClientDetails(clientId: string, timekeeperId: string) { 
        let clientDetail : IClient = {};
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClientIdAndGetClientDetails(${JSON.stringify({clientId, timekeeperId})}) started` : '', MessageType.Info);
            let clientDetails = await this.getClientDetails(clientId, timekeeperId);
            return clientDetail = clientDetails[0];
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClientIdAndGetClientDetails(${JSON.stringify({clientId, timekeeperId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateClientIdAndGetClientDetails(${JSON.stringify({clientId, timekeeperId})}) - output(${JSON.stringify({clientDetail})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate if the client is walled for the user
    async validateIfClientIdsUnaccessibleForUser(clientIds: string[]) : Promise<IServiceResponse> {
        let validationResponse: IServiceResponse = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateIfClientIdsUnaccessibleForUser(${JSON.stringify({clientIds})}) started` : '', MessageType.Info);
            validationResponse = await DataService.postData<IServiceResponse>(ApiUrlConstant.client.validateIfClientIdsUnaccessibleForUser, clientIds);
            return validationResponse;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateIfClientIdsUnaccessibleForUser(${JSON.stringify({clientIds})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateIfClientIdsUnaccessibleForUser(${JSON.stringify({clientIds})}) - output(${JSON.stringify({validationResponse})}) ended` : '', MessageType.Info);
        }
    },


    // Method to validate the status of the client
    async validateStatus(client: IClient, validationDataStatus: string, statusList: IStatusList, clientId: string, timekeeperId: string, dateWorked: string) : Promise<IValidationResponse<IClient>> {
        let statusObj: IValidationResponse<IClient> = { status: false };
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateStatus(${JSON.stringify({client, validationDataStatus, statusList, clientId, timekeeperId, dateWorked})}) started` : '', MessageType.Info);
            let clientSoftText = SoftTextService.getSoftTextValue(CONSTANTS.client);
            

            if (!clientId) {
                statusObj.status = false;
    
                return statusObj;
            }
            
            let barrierModeConfig = ConfigService.getConfigurationData<IBarrierConfig>(CONSTANTS.barrierModes);
    
            if (barrierModeConfig) {
    
                let clientStatus = statusList.Status?.filter((item) => item.Value === validationDataStatus)[0];
    
                if (clientStatus && clientStatus.Meaning) {
    
                    switch (+clientStatus.Meaning) {
    
                        case ValidationDataStatus.Active:
                            if (barrierModeConfig.Mode === CONSTANTS.advancedBarrier) {
    
                                statusObj.status = await this.validateRestrictedClientForUser(clientId);
                                if (!statusObj.status) {
                                    statusObj.notificationMessage = `${clientSoftText} ID ${clientId} is restricted.`;
                                }
                            } else {
                                statusObj.status = true;
                            }
                            break;
    
                        case ValidationDataStatus.Inactive:
                            statusObj.status = false;
                            statusObj.notificationMessage = `${clientSoftText} ID ${clientId} has an inactive status.`;
                            break;
    
                        case ValidationDataStatus.RestrictedTimekeeper:
                            if (barrierModeConfig.Mode == CONSTANTS.basicBarrier) {
                                if (!timekeeperId) {
                                    statusObj.status = true;
                                } else {
                                    let validatedTimekeeperdata = await this.validateRestrictedClientForTimekeeper(timekeeperId, clientId);
                                    if (validatedTimekeeperdata) {
                                        statusObj.status = true;
                                    } else {
                                        statusObj.status = false;
                                        statusObj.notificationMessage = `${clientSoftText} ID ${clientId} is restricted.`;
                                    }
                                }
                            } else {
                                statusObj.status = true;
                            }
                            break;
    
                        case ValidationDataStatus.TimeOnly:
                            statusObj.status = true;
                            break;
    
                        default:
                            statusObj.status = false;
                    }
                    
                    let calendarConfig = ConfigService.getUserConfigurationData<IUserCalendarsConfig>(CONSTANTS.calendars);
    
                    if (client.openDate && TimerService.getDateTime(client.openDate).valueOf() !== TimerService.getDateTime("0001-01-01T00:00:00").valueOf() &&
                        TimerService.getDateTime(client.openDate) > TimerService.getDateTime(dateWorked)) {
                        //Inavlid due to less than the open timedate
                        statusObj.status = false;
                        statusObj.notificationMessage = `The selected ${clientSoftText} is not valid before ${moment(client.openDate).format(calendarConfig?.DateFormat.Value)}`;
                    }
    
                    if (client.closeDate && TimerService.getDateTime(client.closeDate).valueOf() !== TimerService.getDateTime("0001-01-01T00:00:00").valueOf() &&
                        TimerService.getDateTime(dateWorked) > TimerService.getDateTime(client.closeDate)) {
                        statusObj.status = false;
                        statusObj.notificationMessage = `The selected ${clientSoftText} is not valid after ${moment(client.closeDate).format(calendarConfig?.DateFormat.Value)}`;
                    }
                } else {
                    statusObj.status = false;
                    statusObj.notificationMessage = `${clientSoftText} ID ${clientId} has an invalid status.`;
                }
    
                return statusObj;
            }
    
            return statusObj;
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateStatus(${JSON.stringify({client, validationDataStatus, statusList, clientId, timekeeperId, dateWorked})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateStatus(${JSON.stringify({client, validationDataStatus, statusList, clientId, timekeeperId, dateWorked})}) - output(${JSON.stringify({statusObj})}) ended` : '', MessageType.Info);
        }
    },

    
    // Method to validate if the client is restricted for the user
    async validateRestrictedClientForUser(clientId: string) : Promise<boolean> {
        let result: boolean = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateRestrictedClientForUser(${JSON.stringify({clientId})}) started` : '', MessageType.Info);
            result = await DataService.fetchData<boolean>(`${ApiUrlConstant.client.validateRestrictedClientForUser}/${clientId}/RestrictedForUser`);
            return result;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateRestrictedClientForUser(${JSON.stringify({clientId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateRestrictedClientForUser(${JSON.stringify({clientId})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    },

    // Method to validate if the client is restricted for the timekeeper
    async validateRestrictedClientForTimekeeper(timekeeperId: string, clientId: string) : Promise<boolean> {
        let result: boolean = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateRestrictedClientForTimekeeper(${JSON.stringify({timekeeperId, clientId})}) started` : '', MessageType.Info);
            let url = ApiUrlConstant.client.validateRestrictedClientForTimekeeper.replace("{0}", clientId).replace("{1}", timekeeperId);
            result = await DataService.fetchData<boolean>(url);
            return result;
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateRestrictedClientForTimekeeper(${JSON.stringify({timekeeperId, clientId})}) - ${error}` : '', MessageType.Error);
            throw error;
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `ClientService - validateRestrictedClientForTimekeeper(${JSON.stringify({timekeeperId, clientId})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    }
}

export default ClientService;