import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Spinner, makeStyles, shorthands } from "@fluentui/react-components";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    "> div": { ...shorthands.padding("200px") },
  },
});

export default function ExternalLoginIdp() {
  const auth = useAuth();
  const styles = useStyles();
  const navigate = useNavigate();
  
  useEffect(() => {
    auth.redirectUserToIdp().then(() => {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ExternalLoginIdp - success` : '', MessageType.Info);
      navigate("/tab");
    }).catch(function(err) {
      LoggingService.log(LoggingService.isLoggingEnabled() ? `ExternalLoginIdp - error - ${err}` : '', MessageType.Error);
    });
  }, []);

  return (
    <div className={styles.container}> 
      <Spinner size="large" labelPosition="below" label="Processing sign-in..." /> 
    </div>
  );
}

