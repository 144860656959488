export enum EntryStatuses {
    Incomplete = 0,
    Ready = 1,
    Submitted = 2,
    Pending = 2,
    Approved = 3,
    Transferred = 4,
    Transferring = 6,
    Rejected = 7,
    Missing = 8,
    Forecast = 9,
    Outstanding = 10,
    Validating = 11,
    NonCompliant = 12
}