import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { CONSTANTS } from '../constants/constants';
import ApplicationSettingService from '../services/applicationSettingService';

const ConsentPopup = () => {
  const auth = useAuth();

  useEffect(() => {
      const params = new URLSearchParams(window.location.hash.replace('#/auth-start', ''));
      const authId = params.get('authId');
      const method = params.get('oauthRedirectMethod');
      const redirectUrl = encodeURIComponent(params.get('hostRedirectUrl')!);
      const authorityUrl: string = params.get('identityServerUrl') ?? '';
      const appClientId: string = ApplicationSettingService.getAppSettingByKey(CONSTANTS.app_client_id);

      const state = `"authId":${authId}|"method":${method}`;
      const scope = "CarpeWebApi%20offline_access%20openid%20Roles%20profile";
      const url = `${authorityUrl}connect/authorize?client_id=${appClientId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&nonce=3cc8eca0aa59e70be58375f817ff976d202GM3xKh&state=${state}&code_challenge=51FaJvQFsiNdiFWIq2EMWUKeAqD47dqU_cHzJpfHl-Q&code_challenge_method=S256`;
      window.location.href = url;
  }, []);

  return (
      <div>
          <h1>Authenticating.</h1>
      </div>
  );
};

export default ConsentPopup;