import { ComboboxProps, Field, Input, InputProps, Label, makeResetStyles, makeStyles, tokens } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { ICardEntry } from "../models/ICardEntry";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";

interface TimeFieldProps extends Partial<ComboboxProps>{
    cardEntry: ICardEntry;
    onTimeChange: (rawTime: number) => void;
}

const useStyles = makeStyles({
    container: {
      "> div": { display: "initial" },
    },
});
  
export default function TimeField(props : TimeFieldProps) { 

    const [viewOnly] = useState(props.cardEntry.timeId ? true : false)

    const styles = useStyles();

    // This will hold the time
    const [time, setTime] = useState<string>(props.cardEntry.roundTimeDisplayValue ?? "");

    // This will hold the validation message
    const [validationMessage, setValidationMessage] = useState("");

    // This method will be called when the roundTimeDisplayValue changes
    useEffect(() => {
        let roundTimeDisplayValue = props.cardEntry.roundTimeDisplayValue ?? "";
        LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - useEffect() for round time display value started. Dependency array - ${JSON.stringify({roundTimeDisplayValue})}` : '', MessageType.Info);
        setTime(props.cardEntry.roundTimeDisplayValue ?? "");
        LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - useEffect() for round time display value ended. Dependency array - ${JSON.stringify({roundTimeDisplayValue})}` : '', MessageType.Info);
    }, [props.cardEntry.roundTimeDisplayValue]);

    // This method will be called when the user changes the time
    const onTimeChange: InputProps["onChange"] = (ev, data) => {
        setValidationMessage("");
        let val = data.value;
        // If the user enters a negative value, we will allow only 6 characters else 5 characters
        if (val[0] === '-') {
            val = val.substring(0, 6);
        } else {
            val = val.substring(0, 5);
        }
        setTime(val);
    }

    // This method will be called when the user leaves the time field
    const onBlur = (): void => {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - onBlur() started` : '', MessageType.Info);
            let timeValue = time;
            let timeValueInSeconds = 0;
            if (timeValue) {
                let isTimeValid = isTimeInputValid(time);
                if (!isTimeValid) {
                    setValidationMessage("Invalid time format. Please enter time in HH:MM format");
                    timeValueInSeconds = 0;
                    setTime('00:00');
                } else { 
                    let isNegative = timeValue[0] === '-';
                    if (isNegative) {
                        timeValue = timeValue.substring(1);
                    }

                    let timeParts = timeValue.split(':');
                    let hours = parseInt(timeParts[0]);
                    let minutes = parseInt(timeParts[1] ?? 0);
                    timeValueInSeconds = (hours * 60 * 60) + (minutes * 60);
                    if (isNegative) {
                        timeValueInSeconds = -timeValueInSeconds;
                    }
                }
            } else { 
                timeValueInSeconds = 0;
            }

            props.onTimeChange(timeValueInSeconds);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - onBlur() - Error occurred - ${error}` : '', MessageType.Error);
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - onBlur() ended` : '', MessageType.Info);
        }
     }

    // This method will check if the time input is valid
    function isTimeInputValid(timeDisplayValue: string): boolean {
        let result = false;
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - isTimeInputValid(${JSON.stringify({timeDisplayValue})}) started` : '', MessageType.Info);
            // Regular expressions to check if time is in -HH:MM, HH:MM, -HH, or HH format
            const timeFormat1 = /^-\d{1,2}:[0-5]\d$/; // -HH:MM
            const timeFormat2 = /^\d{1,2}:[0-5]\d$/; // HH:MM
            const timeFormat3 = /^-\d{1,2}$/; // -HH
            const timeFormat4 = /^\d{1,2}$/; // HH
        
            return result = timeFormat1.test(timeDisplayValue) || timeFormat2.test(timeDisplayValue) || timeFormat3.test(timeDisplayValue) || timeFormat4.test(timeDisplayValue);
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - isTimeInputValid(${JSON.stringify({timeDisplayValue})}) - Error occurred - ${error}` : '', MessageType.Error);
            return result;
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `TimeField - isTimeInputValid(${JSON.stringify({timeDisplayValue})}) - output(${JSON.stringify({result})}) ended` : '', MessageType.Info);
        }
    }

    return (
        <div>
            <Label className="title">Time</Label> 
            <Field validationMessage={validationMessage} className={styles.container}> 
                <Input type="text" placeholder="HH:MM" value={time.toString()} onChange={onTimeChange} onBlur={onBlur} disabled={viewOnly}/> 
            </Field>
        </div>
    )
}