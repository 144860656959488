import { Combobox, ComboboxProps, Label, makeStyles, useId, Option } from "@fluentui/react-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { ICardEntry } from "../models/ICardEntry";
import { IUserCodeResponse } from "../models/IUserCodeResponse";
import { CONSTANTS } from "../constants/constants";
import { ILookUpData } from "../models/ILookUpData";
import LoggingService from "../services/loggingService";
import { MessageType } from "../enums/messageType";


interface CustomComboboxProps extends Partial<ComboboxProps> {
    entity: string;
    title: string;
    selectedId: string;
    selectedName: string;
    getDropdownItems: (startIndex: number, searchString: string) => Promise<ILookUpData[]>
    getDropdownItemsForUdc?: (startIndex: number, searchString: string, parent: string) => Promise<IUserCodeResponse>
    onSelectionChange: (selectedId: string) => Promise<void>;
    isDependantSelected: () => boolean;
    cardEntry : ICardEntry;
    isUdc?: boolean;
    isHierarchical?: boolean;
    validationMessage?: string;
    isRequired: boolean;
}

interface IDropDownHierarchiData {
    selection?: string;
    items?: ILookUpData[];
    parent?: IDropDownHierarchiData;
}

const useStyles = makeStyles({
    listbox: {
      maxHeight: '250px',
      overflowY: 'auto',
    },
    option: {
      height: '32px',
    },
});

export default function CustomCombobox(props : CustomComboboxProps) {

    const [viewOnly] = useState(props.cardEntry.timeId ? true : false)

    // This is used to indicate if the dropdown is meant for UDC or not
    const [isUdc] = useState(props.isUdc || false);

    // This is used to indicate if the dropdown is meant for hierarchical data or not
    const [isHierarchical] = useState(props.isHierarchical || false);

    // This is used to store the old list of items for back traversal incase of UDC which are hierarchical
    const [oldList, setOldList] = useState<IDropDownHierarchiData>();

    // This is used to control the dropdown
    const[open, setOpen] = useState(false);
    const openRef = useRef(false);

    // The list of items to display in the dropdown
    const [items, setItems] = useState<ILookUpData[]>([]);

    // The state to check if the data is loading
    const [isDataLoading, setIsDataLoading] = useState(false);

    // The state to check if no data is found
    const [noDatafound, setNoDataFound] = useState(false);

    // The id for the combobox
    const comboId = useId('combobox');

    // The variable that is used to display in the dropdown
    const [model, setModel] = useState("");

    // The list of selected Ids (it will always be just one id in our case)
    const [selectedId, setSelectedId] = useState<string[]>([]);

    // The reference to the start index to fetch data (used for pagination)
    const startIndexRef = useRef(0);

    // The reference that will be used to store the search string
    const searchStringRef = useRef("");

    // The reference to the selected id
    const selectedIdRef = useRef("");

    // The reference to the client name
    const selectedNameRef = useRef("");

    // The reference to the timeout for the debounce
    const keyDownTimeout = useRef<NodeJS.Timeout | null>(null);

    // The styles for the dropdown
    const classes = useStyles();

    // The useEffect to set the selectedId and selectedName when the selectedId is changed
    useEffect(() => {
        let id = props.selectedId ?? "";
        LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - useEffect() for selectedId started. Dependency array - ${JSON.stringify({id})}` : '', MessageType.Info);
        selectedIdRef.current = props.selectedId;
        selectedNameRef.current = props.selectedName;
        // TODO : SHOULD I RESET SEARCH STRING HERE???
        if (props.selectedId) {
            setModel(props.selectedId + ":" + props.selectedName);
            setSelectedId([props.selectedId]);
        } else {
            //  If the selectedId has been reset to empty state, we will reset the model and selectedId
            setModel("");
            setSelectedId([]);
        }

        LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - useEffect() for selectedId ended. Dependency array - ${JSON.stringify({id})}` : '', MessageType.Info);
    }, [props.selectedId])

    // The useEffect to reset the dropdown when the validation message is set
    useEffect(() => {
        let message = props.validationMessage ?? "";
        LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - useEffect() for validationMessage started. Dependency array - ${JSON.stringify({message})}` : '', MessageType.Info);
        // When the validation message is set, we would have cleared the respective object in the parent component
        // So, we will reset the dropdown UI here
        if (props.validationMessage) {
            setModel("");
            setSelectedId([]);
            selectedIdRef.current = "";
            selectedNameRef.current = "";
        }

        LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - useEffect() for validationMessage ended. Dependency array - ${JSON.stringify({message})}` : '', MessageType.Info);
    }, [props.validationMessage])


    // The function to fetch the data from the API
    // parent parameter is used to fetch the data for UDC which are hierarchical, in all other cases this will empty
    async function fetchData(isFromScroll: boolean = false, parent?: string) {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - fetchData(${JSON.stringify({isFromScroll, parent})}) started` : '', MessageType.Info);
            if (isDataLoading) {
                // If the data is already being fetched, then we will not make another call
                return;
            }
    
            // If the dependant is not selected, then we will not make the call
            // We need to check this only for non-hierarchical dropdowns
            if (!parent) {
                let isDependantSelected = props.isDependantSelected();
                if (!isDependantSelected) {
                    setOpen(false);
                    openRef.current = false;
                    return;
                }
            }
    
            setIsDataLoading(true);
    
            if (!isUdc) {
                let data = await props.getDropdownItems(startIndexRef.current, searchStringRef.current);
                setIsDataLoading(false);
    
                if (data.length === 0) {
                    setNoDataFound(true);
                    return;
                }
    
                isFromScroll ? setItems((prevItems) => [...prevItems, ...data]) : setItems(data); // Append to list if scrolling, else replace list
                startIndexRef.current += 25;  
            } else {
                let data = await props.getDropdownItemsForUdc?.(startIndexRef.current, searchStringRef.current, parent ?? "") ?? {};
                setIsDataLoading(false);
                if (data.userCode) {
                    // This is the case when we have selected the final option in the hierarchy for UDC dropdown
                    selectedIdRef.current = data.userCode.codeID ?? "";
                    selectedNameRef.current = data.userCode.codesetDescription ?? "";
                    searchStringRef.current = "";
                    await props.onSelectionChange(selectedIdRef.current);
                    setOpen(false);
                    openRef.current = false;
                } else {
                    let dropdownItems = data.lookUpEntities ?? [];
    
                    if (dropdownItems.length === 0) {
                        setNoDataFound(true);
                        return;
                    }
    
                    isFromScroll ? setItems((prevItems) => [...prevItems, ...dropdownItems]) : setItems(dropdownItems); // Append to list if scrolling, else replace list
                    startIndexRef.current += 25; 
                }
            }
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - fetchData(${JSON.stringify({isFromScroll, parent})}) - ${error}` : '', MessageType.Error);
            setOpen(false);
            setIsDataLoading(false);
            setModel("");
            searchStringRef.current = "";
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - fetchData(${JSON.stringify({isFromScroll, parent})}) ended` : '', MessageType.Info);
        }
    }

    // The function that will be called when the dropdown opens or closes
    const openDropdown: ComboboxProps["onOpenChange"] = (event, { open }) => {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - openDropdown(${JSON.stringify({open})}) started` : '', MessageType.Info);
            // Check if the dropdown is being opened
            if (open) {
                setIsDataLoading(false);
                setNoDataFound(false);
                setOldList({});
                setItems([]);
                searchStringRef.current = "";
                startIndexRef.current = 0;
                setOpen(true);
                openRef.current = true;
                fetchData();
            } else {
                // This will happen when we have just typed something in the dropdown and not selected any option and are tabbing out
                if (selectedId.length === 0) {
                    if (searchStringRef.current) {
                        let id = searchStringRef.current.split(":")[0].trim();
                        let [oldId, oldName] = getIdAndName();
                        if (id === oldId) {
                            setModel(oldId + ":" + oldName);
                            setSelectedId([oldId]);
                            selectedIdRef.current = oldId;
                            selectedNameRef.current = oldName;
                        } else if (event.type !== 'click'){
                            props.onSelectionChange(id);
                        }
                    } else {
                        setModel("");
                        setSelectedId([]);
                        selectedIdRef.current = "";
                        selectedNameRef.current = "";
                        props.onSelectionChange("");
                    }
                }

                setOpen(false);
                openRef.current = false;
            }
        } catch (error) { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - openDropdown(${JSON.stringify({open})}) - ${error}` : '', MessageType.Error);
            setOpen(false);
            setModel("");
            searchStringRef.current = "";
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - openDropdown(${JSON.stringify({open})}) ended` : '', MessageType.Info);
        }
    };

    // The function to get the id and name based on the entity
    function getIdAndName() : [string, string] {
        let idName : [string, string] = ["", ""];
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - getIdAndName() started` : '', MessageType.Info);
            switch (props.entity) { 
                case CONSTANTS.client: return [props.cardEntry?.clientId ?? "", props.cardEntry?.clientName ?? ""];
                case CONSTANTS.matter: return [props.cardEntry?.matterId ?? "", props.cardEntry?.matterName ?? ""];
                default: {
                    if (props.entity.startsWith('Code')) {
                        let idDesc = props.cardEntry.userCodeIdDesc?.[props.entity] ?? "";
                        return idName = idDesc ? [idDesc.split(":")[0], idDesc.split(":")[1]] : ["", ""];
                    } else {
                        return idName = ["", ""];
                    }
                }
            }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - getIdAndName() - ${error}` : '', MessageType.Error);
            return idName = ["", ""];
        } finally {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - getIdAndName() - output(${JSON.stringify({idName})}) ended` : '', MessageType.Info);
        }
    }


    // The function that will be called when the user types in the dropdown, it is not called when the user selects an option
    const handleChange: ComboboxProps["onChange"] = (event: React.ChangeEvent<HTMLInputElement>) => {
        // When the user types in the dropdown, remove the selected id
        setSelectedId([]);
        selectedIdRef.current = "";
        selectedNameRef.current = "";

        const value = event.target.value.trim();
        setModel(value);
        searchStringRef.current = value;
        handleSearch(value);
    };


    // Debounce function to handle the search
    const handleSearch = useCallback((searchString: string) => {
        // If there's a timeout in progress, clear it
        if (keyDownTimeout.current) {
          clearTimeout(keyDownTimeout.current);
        }
    
        // Set a new timeout
        keyDownTimeout.current = setTimeout(() => {
          // This is the code that will run after the debounce time    
          // Make the API call here;
          setIsDataLoading(false);
          setNoDataFound(false);
          setOldList({});
          setItems([]);
          startIndexRef.current = 0;

          // Check if the dropdown is open, then only make the call.
          // When we type something and tab out immediately, we do not want to make the call
          if (openRef.current) {
            fetchData();
          }

          // Clear the timeout variable
          keyDownTimeout.current = null;
        }, CONSTANTS.dropdownWaitTimeBeforeCall);  // 3000ms debounce time
      }, [props.cardEntry]); // TODO : Check if we need to add props.cardEntry here


    // The function that will be called when the user selects a option from the dropdown
    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - onOptionSelect(${JSON.stringify({data})}) started` : '', MessageType.Info);
            // Tab key should not trigger the selection, we have handled this in the openDropdown method
            if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') {
                return;
            }

            // Check if the user has selected any option, else it is not required to update the state
            if (data.selectedOptions.length > 0) {          
                if (data.optionValue === "back") { 
                    moveBack();
                    return;
                }
                
                if (isUdc && isHierarchical) {
                    // We need to drill down further, so keep the dropdown open and fetch the next level data. Reset the relevant properties 
                    setOpen(true);
                    openRef.current = true;
                    startIndexRef.current = 0;
                    searchStringRef.current = "";
                    setOldList({selection: data.optionText, items: items, parent: oldList});
                    setItems([]);
                    fetchData(false, data.optionValue);
                } else {
                    selectedIdRef.current = data.optionValue ?? "";
                    selectedNameRef.current = data.optionText?.split(":")[1].trim() ?? "";
                    searchStringRef.current = "";
                    props.onSelectionChange(selectedIdRef.current);
                }
            }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - onOptionSelect(${JSON.stringify({data})}) - ${error}` : '', MessageType.Error);
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - onOptionSelect(${JSON.stringify({data})}) ended` : '', MessageType.Info);
        }
    };

    // The function to handle the scroll event
    function handleScroll (e: { currentTarget: { scrollTop: any; clientHeight: any; scrollHeight: any; }; }) {
        try {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - handleScroll() started` : '', MessageType.Info);
            const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
            if (scrollHeight - scrollTop <= clientHeight * 1.5 && !isDataLoading && !noDatafound && items.length % 25 === 0) {
                fetchData(true);
            }
        } catch (error) {
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - handleScroll() - ${error}` : '', MessageType.Error);
        } finally { 
            LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - handleScroll() ended` : '', MessageType.Info);
        }
    };

    // The function to move back in the hierarchy
    function moveBack() {
        LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - moveBack() started` : '', MessageType.Info);
        if (oldList) {
            setItems(oldList.items ?? []);
            setOldList(oldList.parent);
            setOpen(true);
            openRef.current = true;
        }
        LoggingService.log(LoggingService.isLoggingEnabled() ? `CustomCombobox - moveBack() ended` : '', MessageType.Info);
    }

    return(
        <div>
            <Label className="title" required={props.isRequired}>{props.title}</Label>
            <Combobox
                id={comboId}
                placeholder={`Select a ${props.title}`}
                aria-labelledby={comboId}
                value={model}
                selectedOptions={selectedId}
                open={open}
                onOpenChange={openDropdown}
                onChange={handleChange}
                onOptionSelect={onOptionSelect}
                autoComplete="off"
                disabled={viewOnly}
            >
            <div onScroll={handleScroll} className={classes.listbox}>
                {
                    oldList && oldList.selection ? (<Option key={"back"} value="back" style={{fontWeight: 'bold'}}>{oldList.selection}</Option>) : null
                }
                {
                    items.map((item) => (
                        <Option className={classes.option} key={item.key} value={item.key} style={{fontWeight: item.isFavorite ? 'bold' : 'normal'}}>
                            {item.key + ":" + item.value}
                        </Option>
                    ))
                }
                {
                    isDataLoading ? (<Option key="freeform" disabled>Loading...</Option>) : null
                }
                {
                    noDatafound ? (<Option key="freeform" disabled>No data found...</Option>) : null
                }
            </div>               
            </Combobox>
        </div>
    )
}