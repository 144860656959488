import ApplicationSettingService from "../services/applicationSettingService";

export class ApiUrlConstant {

    static userData = {
        getUserData: "api/UserData",
    }

    static timekeeper = {
        getTimeKeepersDetailbyTimekeeperId: "api/TimeKeeper/{0}/{1}/{2}",
    }

    static timeFinder = {
        getTimekeeperActivitiesAll: "api/TimeFinder/GetTimekeeperActivitiesAll",
        excludeTimeFinderActivity: "api/TimeFinder/ExcludeTimeFinderActivity"
    }

    static client = {
        getClients: "api/Client/LookupData",
        getClientDetails: "api/Client/GetAllClientsDetailsForClientIds",
        validateIfClientIdsUnaccessibleForUser: "api/BarrierCheck/Clients/ValidateUnaccessibleForUser",
        validateRestrictedClientForUser: "api/BarrierCheck/Client",
        validateRestrictedClientForTimekeeper: "api/BarrierCheck/Client/{0}/RestrictedForTimekeeper/{1}",
    }

    static matter = {
        getMatter: "api/Matter/LookupData",
        getMatterDetailsForClient: "api/Matter/Details/{0}/ForClient/{1}/{2}/{3}",
        getMatterDetailsByMatterId: "api/Matter/Details",
        validateIfMatterIdsUnaccessibleForUser: "api/BarrierCheck/Matters/ValidateUnaccessibleForUser",
        validateRestrictedMatterForUser: "api/BarrierCheck/Matter",
        validateRestrictedMatterForTimekeeper: "api/BarrierCheck/Matter/{0}/{1}/RestrictedForTimekeeper/{2}",
    }

    static settings = {
        getSettings: "api/CarpeConfiguration",
        getUserDefaultConfigurationDetails: "api/UserConfiguration",
    }

    static userCode = { 
        getUDCdata: "api/UserCode/{0}/Lookup?codeset={1}&q={2}&start={3}&len={4}&parent={5}",
        getUDCObject: "api/UserCode/{0}?codeId={1}&codeset={2}",
    }

    static validationData = {
        getRoundingAndBillableValue: "api/ValidationData/GetRoundingAndBillableValue",
        getLocalValidationRules: "api/ValidationRules?clientId={0}&matterId={1}&timekeeperId={2}"
    }

    static timeEntry = { 
        deleteTimeEntries: "api/TimeEntry/Delete",
        getTimeEntries: "api/TimeEntry/Day/{0}/Tkpr/{1}?format={2}&rndInc={3}&endDate={4}&isSmartTimer={5}",
        addTimeEntry: "api/TimeEntry/AddTimeEntry",
        getLocalAllowBillableOverrideInfo: "api/BillableState?clientId={0}&matterId={1}&tkprId={2}",
    }

    static spellCheck = {
        getMisspelledWords: "api/SpellCheck/MisspelledWords",
        getSuggestions: "api/SpellCheck/Suggestions",
        getCustomSuggestions: "api/SpellCheck/CustomSuggestions",
        updateUserDictionary: "api/SpellCheck/UserDictionary",
    }
}